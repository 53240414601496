import { MENU } from "pages/pageInfo";
import React, { useEffect } from "react";

const Menu = () => {

  useEffect(() => {
    document.title = MENU.title;
  });

  return <div></div>;
};

export default Menu;
