import * as pg from "pages/pageInfo";
import React from "react";
import { Navbar } from "react-bootstrap";
import { useLocation } from "react-router-dom";

export const Footer = () => {
  const location = useLocation();

  // ヘッダ非表示画面の場合、フッターを出さない
  const none = [pg.SESSION_TIMEOUT.path,];
  if(none.includes(location.pathname)){
    return null;
  }

  return <>
    <div className="footer-block">
      <Navbar className="bg-light footer">
        <div className="ml-auto">
          <span className="text-muted">エレマテック©</span>
        </div>
      </Navbar>
    </div>
  </>;
};