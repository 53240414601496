import { ImmutableMap } from "./immutableMap";

export type UserRole = "unauthorized" | "driver" | "manager" | "admin";
export type AuthStatus = "UNAUTHORIZED" | "NEW_PASSWORD_REQUIRED" | "SIGNED_IN";
// これ要るか怪しい
export type User = any;
export type UserRoleToShow = "ドライバー" | "マネージャー" | "管理者";

// AuthStatus と User はここに混ぜるべきではない説がある。
// userRole, companyId, email については画面側で意識するユーザーと同じだけど、authStatus と user は画面自体からは意味のない情報なので。
// CognitoUserInfo とかの interface で別にまとめるべきでは。
export type UserInfo = {
  userRole: UserRole;
  authStatus: AuthStatus;
  companyId: string;
  email: string;
  user: User;
};

export type UserInfoMap = ImmutableMap<UserInfo>;
// これ要るか怪しい
export type CognitoUserInfo = { cognitoId: string } & Omit<
  UserInfo,
  "authStatus" | "user"
>;

export const initialUserInfo: UserInfo = {
  userRole: "unauthorized",
  authStatus: "UNAUTHORIZED",
  companyId: "",
  email: "",
  user: null,
} as const;

export const ArrayOfRolesToShowForManager = ["ドライバー", "マネージャー"];
export const ArrayOfRolesToShowForAdmin = [
  "ドライバー",
  "マネージャー",
  "管理者",
] as const;
