import { useUserInfoContext } from "contexts/userInfoContext";
import { SESSION_TIMEOUT, SIGN_IN } from "pages/pageInfo";
import React from "react";
import { Button } from "react-bootstrap";


const SesstionTimeout = () => {
  // この画面に来たら問答無用でサインアウト処理する
  const { signOut, } = useUserInfoContext();
  signOut(SESSION_TIMEOUT.path);

  return (
    <>
      <div className="pl-5 pr-5 bg-light">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <img src="/img/logo/elematec_logo.png" alt="logo" width="150" className="navbar-brand m-0 p-0"></img>
        </nav>
      </div>
      <div className="form-signin" style={{width: "600px"}}>
        <div className="d-flex">
          <h5 className="mb-4 mx-auto">長時間操作が行われなかったため、自動的にログアウトされました。</h5>
        </div>
        <div>
          <Button variant="outline-primary" className="my-4 mx-auto px-3" href={SIGN_IN.path} size="lg">ログイン画面へ戻る</Button>
        </div>
      </div>
    </>

  // <>
  //   <div>長時間操作が行われなかったため自動的にログアウトされました。</div>
  //   <div>
  //     <Link to={SIGN_IN.path}>ログイン画面へ戻る</Link>
  //   </div>
  // </>
  );
};

export default SesstionTimeout;
