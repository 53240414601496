import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import SelectBoxGroup from "../../components/layout/SelectBoxGroup";
import TextInputGroup from "../../components/layout/TextInputGroup";
import { useUserInfoContext } from "../../contexts/userInfoContext";
import { ArrayOfRolesToShowForManager } from "../../interfaces/userInfoInterface";
import { REGISTER_USER_FOR_MANAGER } from "../pageInfo";

export type RegisterUserError = {
  email: string | null;
  role: string | null;
}

const RegisterUserForManager = () => {
  const { getCompanyId } = useUserInfoContext();
  const [isLoading, setIsLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("一般ユーザー");
  const [companyId, setCompanyId] = useState("");
  const [errors, setErrors] = useState<RegisterUserError>({
    email: null,
    role: null,
  });

  useEffect(() => {
    document.title = REGISTER_USER_FOR_MANAGER.title;
    setCompanyId(getCompanyId());
    setIsLoading(false);
  }, [getCompanyId]);

  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault();
    console.log(email, role);
    const init = {
      headers: {},
      response: true,
      body: {
        CompanyId: companyId,
      },
    };
    API.post("sample", "/user/register", init)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateEmail = (target: string): void => {
    if (!target.includes("@")) {
      setErrors({
        email: "'@'が含まれていません",
        role: errors.role,
      });
    } else if (!target.includes(".")) {
      setErrors({
        email: "'.'が含まれていません",
        role: errors.role,
      });
    } else {
      setErrors({
        email: null,
        role: errors.role,
      });
    }
  };

  if (isLoading) {
    return <React.Fragment />;
  } else {
    return (
      <Form>
        <TextInputGroup
          id={"email"}
          label={"Email"}
          type={"email"}
          placeholder={"example@email.com"}
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(e.target.value)
          }
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
            validateEmail(e.target.value)
          }
          error={errors.email}
        ></TextInputGroup>
        <SelectBoxGroup
          id={"role"}
          label={"User Role"}
          value={role}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            setRole(e.target.value)
          }
          options={ArrayOfRolesToShowForManager}
          error={errors.role}
        />
        <SelectBoxGroup
          id={"companyId"}
          label={"企業名"}
          value={companyId}
          options={Array.of(companyId)}
          disabled={true}
        />
        <Button
          variant="primary"
          type="submit"
          data-testid="button-submit"
          onClick={(e: React.MouseEvent<HTMLInputElement>) => handleClick(e)}
        >
          登録
        </Button>
      </Form>
    );
  }
};

export default RegisterUserForManager;
