import { AxiosRequestConfig } from "axios";
import { MyCheckboxInline, MyDatePicker, MyRadioInline, MyRowNumber } from "components/parts/InputRow";
import { ENDPOINT } from "constants/endpoint";
import { m } from "constants/message";
import { useUserInfoContext } from "contexts/userInfoContext";
import jwt from "jsonwebtoken";
import { MENU, MOVIE_PLAYBACK, SESSION_TIMEOUT, TRACKING_MANAGEMENT_GETFILE } from "pages/pageInfo";
import queryString from "query-string";
import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { request } from "util/request";

interface State {
  "vehicleId": string,
  "fileType": string,
  "fileTypeMessage": string,
  "startDatetime": Date,
  "videoLength": string,
  "vChannel": string[],
  "vChannelMessage": string,
  "aChannel": string[],
}

export const TrackingManagementGetfile: React.FC<any> = (props) =>{
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const isSearching = useRef(false);
  const history = useHistory();
  const [validated, setValidated] = useState(false);
  const [commandId, setCommandId] = useState<number>(0);
  // 取得状況検索リクエストの回数管理(-100:初期状態 -1：検索回数超過)
  const [remaining, setRemaining] = useState<number>(-100);
  const [searchError, setSearchError] = useState(false);
  const [state, setState] = useState<State>({
    "vehicleId": "",
    "fileType": "",
    "fileTypeMessage": "",
    "startDatetime": new Date(),
    "videoLength": "1",
    "vChannel": [],
    "vChannelMessage": "",
    "aChannel": ["0"],
  });
  const { signOut, } = useUserInfoContext();
  const [registerErrorMessage, setRegisterErrorMessage] = useState<string | null>(null);
  const [registerSuccessMessage, setRegisterSuccessMessage] = useState<string | null>(null);
  const [searchErrorMessage, setSearchErrorMessage] = useState<string | null>(null);
  const [searchSuccessMessage, setSearchSuccessMessage] = useState<string | null>(null);

  // 実行間隔 × 実行回数 = 検索APIの初回実行時から最終実行時までの秒数
  const SEARCH_REQUEST_INTERVAL = 30; // 検索APIの実行間隔(秒)
  const SEARCH_REQUEST_COUNT = 6; // 検索APIの実行回数(初回実行を除く)

  useEffect(()=>{
    if(state["vehicleId"] === "") {
      document.title = TRACKING_MANAGEMENT_GETFILE.title;
      // クエリパラメータからvehicle_idを取得する
      const query = queryString.parse(history.location.search)["vehicle_id"] as string;
      if (query === undefined) {
        history.replace(MENU.path);
      }
      setState({...state, vehicleId: query});
    }
  },[state, history]);

  // 動画像取得コマンド登録に成功した場合、remainingの値を更新し、取得状況検索リクエストを定期実行する
  useEffect(() => {
    if (remaining >= 0 && commandId !== 0 && !searchError && !isSearching.current) {
      isSearching.current = true; // APIの二重コール防止用
      (async () => {
        // POSTリクエスト(動画像取得状況検索)
        const config: AxiosRequestConfig = {
          method: "post",
          data: {
            command_id: commandId,
          },
        };

        await request(ENDPOINT.TRACKING_MANAGEMENT_GETFILE_SEARCH, config, setSearchErrorMessage)
          .then((res: any) => {
            let message = "";
            switch (res.data.exec_status) {
              case "00": // 未送信
                message = m("M0101");
                break;
              case "10": // 送信済
                message = m("M0102");
                break;
              case "20": // 実行完了
                if (res.data.status === "01") { message = m("M0103"); } // mdtアップロード完了
                else if (res.data.status === "10") { // mp4変換完了
                  const type = state["fileType"] === "video" ? "movie" : "snapshot";
                  history.push(`${MOVIE_PLAYBACK.path}?source=02&type=${type}&mng_ids=${res.data.mng_id}&main_mng_id_num=0${state["fileType"] === "video" ? "" : "&event_id="}`);
                }
                break;
              case "25": // 該当データなし
                document.title = m("M0104");
                setSearchErrorMessage(m("M0104"));
                throw new Error();
              case "30": // 実行失敗
                document.title = m("M0105");
                setSearchErrorMessage(m("M0105"));
                throw new Error();
              default:
                document.title = m("M0105");
                setSearchErrorMessage(m("M0105"));
                throw new Error();
            }
            document.title = message;
            setSearchSuccessMessage(message);
          })
          .catch((err: any) => {
            // トークン不正時、サインアウト処理してreturn ※サインアウト処理の中でタイムアウト画面へ遷移させる
            if(err instanceof jwt.JsonWebTokenError){ signOut(SESSION_TIMEOUT.path); return; }
            setSearchError(true); // 検索APIの再実行防止用
            setSearchSuccessMessage(null);
            setRegisterSuccessMessage(null);
            setIsRequesting(false);
          }
          );

        setTimeout( () => {
          isSearching.current = false;
          setRemaining(prev => {return prev - 1;});
        }, SEARCH_REQUEST_INTERVAL * 1000);
      })();
    }

    // 検索回数超過
    if (remaining === -1) {
      document.title = m("M0105");
      setRemaining(-100); // 2回目以降の送信要求ボタン押下に備えて初期状態に戻す
      setSearchSuccessMessage(null);
      setRegisterSuccessMessage(null);
      setSearchErrorMessage(m("M0105"));
      setIsRequesting(false);
    }
  }, [remaining, commandId, history, searchError, signOut, state]);

  // submitボタンのハンドラ
  const handleSubmit = async (event: React.MouseEvent<HTMLInputElement>) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    // 入力チェック
    if (form.checkValidity() === false || state["fileType"] === "" || !state["vChannel"].length) {
      const ftm = state["fileType"] === "" ? m("V0013") : "";
      const vcm = !state["vChannel"].length ? m("V0014") : "";
      setState({...state, fileTypeMessage: ftm, vChannelMessage: vcm});
      await setValidated(true);
      return;
    }

    setIsRequesting(true); // APIの二重コール防止用
    setRegisterSuccessMessage(null);
    setRegisterErrorMessage(null);
    setSearchSuccessMessage(null);
    setSearchErrorMessage(null);

    // 時刻のフォーマット
    const zeroPadding = (num: number, len: number) => (String(num).padStart(len, "0"));
    const date = state["startDatetime"];
    const startDatetime = zeroPadding(date.getFullYear(), 4) + "/" + zeroPadding(date.getMonth() + 1, 2) + "/" + zeroPadding(date.getDate(), 2) + " " + zeroPadding(date.getHours(), 2) + ":" + zeroPadding(date.getMinutes(), 2) + ":" + zeroPadding(date.getSeconds(), 2);

    // POSTリクエスト(動画像取得コマンド登録)
    const config: AxiosRequestConfig = {
      method: "post",
      data: {
        vehicle_id: parseInt(state["vehicleId"]),
        type: state["fileType"],
        start_datetime: startDatetime,
        video_length: parseInt(state["videoLength"]),
        v_channel: state["vChannel"],
        a_channel: state["aChannel"],
      },
    };

    await request(ENDPOINT.TRACKING_MANAGEMENT_GETFILE_REGISTER, config, setRegisterErrorMessage)
      .then((res: any) => {
        setCommandId(res.data.command_id);
        setSearchError(false);
        setRemaining(SEARCH_REQUEST_COUNT); // 必ずsetCommandIdとsetSearchErrorの方が先(値が反映されないままuseEffectが動いてしまうため)
        setRegisterSuccessMessage(m("M0100"));
        setSearchSuccessMessage(m("M0101"));
        document.title = m("M0101");
      })
      .catch((err: any) => {
        // トークン不正時、サインアウト処理してreturn ※サインアウト処理の中でタイムアウト画面へ遷移させる
        if(err instanceof jwt.JsonWebTokenError){ signOut(SESSION_TIMEOUT.path); return; }
        setIsRequesting(false);
        return;
      }
      );
  };

  // 入力欄にfileTypeを入力した時のハンドラ
  const handleOnChangeFileType = (event: React.ChangeEvent<HTMLInputElement>, target: string) => {
    switch (event.target.value) {
      case "video":
        setState({...state, [target]: event.target.value, "fileTypeMessage": ""});
        break;
      case "image":
        setState({...state, [target]: event.target.value, "videoLength": "1", "fileTypeMessage": ""});
        break;
      default:
        setState({...state, [target]: event.target.value});
    }
  };

  // 入力欄にstartDatetimeを入力した時のハンドラ
  const handleOnChangeStartDatetime = (date: any, target: string) => {
    if (date === null) {
      date = new Date();
    }
    setState({...state, [target]: date});
  };

  // 入力欄にvideoLengthを入力した時のハンドラ
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, target: string) => {
    setState({...state, [target]: event.target.value});
  };

  // 入力欄にvChannelを入力した時のハンドラ
  const handleOnChangeVChannel = (event: React.ChangeEvent<HTMLInputElement>, target: string) => {
    if (event.target.checked) {
      const vChannel = state.vChannel.slice();
      vChannel.push(event.target.value);
      setState({...state, [target]: vChannel, "vChannelMessage": ""});
    } else {
      const vChannel = state.vChannel.filter(n => n !== event.target.value);
      setState({...state, [target]: vChannel});
    }
  };

  // 種別
  const fileType = {
    label: "",
    className: "mx-0 mt-4",
    itemLabels: ["動画", "静止画"],
    attrName: "fileType",
    values: ["video", "image"],
    disabled: Array(2).fill(isRequesting),
    messageClassName: "mx-0 pb-3 small text-danger",
    message: state.fileTypeMessage,
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChangeFileType(e, "fileType");},
  };

  // 開始時間
  const startDatetime = {
    label: "開始時間",
    startDate: state.startDatetime,
    dateFormat: "yyyy/MM/dd HH:mm:ss",
    showTimeInput: true,
    timeInputLabel: "時間:",
    maxDate: new Date(),
    isClearable: false,
    disabled: state.fileType === "image" ? true : isRequesting,
    onChange: (date: any)=>{handleOnChangeStartDatetime(date, "startDatetime");},
  };

  // 動画長さ
  const videoLength = {
    label: "動画長さ(秒)",
    attrName: "videoLength",
    required: true,
    value: state.videoLength,
    max: 30,
    min: 1,
    md: "4",
    message: m("V0001") + m("V0012", "1", "30"),
    disabled: state.fileType === "image" ? true : isRequesting,
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "videoLength");},
  };

  // 映像ch.
  const vChannel = {
    label: "映像ch.",
    className: "mx-0",
    itemLabels: ["0", "1", "2", "3"],
    attrName: "vChannel",
    values: ["0", "1", "2", "3"],
    inline: true,
    disabled: Array(4).fill(isRequesting),
    checked: false,
    messageClassName: "mx-0 pb-3 small text-danger",
    message: state.vChannelMessage,
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChangeVChannel(e, "vChannel");},
  };

  // 音声ch.
  const aChannel = {
    label: "音声ch.",
    className: "mx-0 pb-3",
    itemLabels: ["0"],
    attrName: "aChannel",
    values: ["0"],
    inline: true,
    disabled: [true],
    checked: true,
    messageClassName: "",
    message: "",
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "aChannel");},
  };

  // 登録完了メッセージの生成
  const registered = registerSuccessMessage != null
    ? <Alert variant="success" className="mt-2" dismissible onClose={e => setRegisterSuccessMessage(null)}><strong>成功：</strong>{registerSuccessMessage}</Alert>
    : null;

  // 登録エラーメッセージの生成
  const registerAlert = registerErrorMessage != null
    ? <Alert variant="danger" className="mt-2" dismissible onClose={e => setRegisterErrorMessage(null)}><strong>エラー：</strong>{registerErrorMessage}</Alert>
    : null;

  // 検索完了メッセージの生成
  const searched = searchSuccessMessage != null
    ? <Alert variant="success" className="mt-2" dismissible onClose={e => setSearchSuccessMessage(null)}><strong>動画像取得状況：</strong>{searchSuccessMessage}
      <Spinner style={{width: "1rem", height: "1rem", margin: "0rem 1rem"}} as="span" animation="border" size="sm" role="status" aria-hidden="true" />
    </Alert>
    : null;

  // 検索エラーメッセージの生成
  const searchAlert = searchErrorMessage != null
    ? <Alert variant="danger" className="mt-2" dismissible onClose={e => setSearchErrorMessage(null)}><strong>エラー：</strong>{searchErrorMessage}</Alert>
    : null;

  // 送信要求ボタンの表示
  const requestButton = isRequesting && (searchSuccessMessage === null)
    ? <Spinner style={{width: "1rem", height: "1rem"}} as="span" animation="border" size="sm" role="status" aria-hidden="true" />
    : "送信要求";

  return (
    <>
      <div className="mt-5 ml-5 mr-5">
        <h2 className="">動画像取得</h2>
        {registered}

        {searched}

        {registerAlert}

        {searchAlert}

        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          {/* 種別 */}
          <MyRadioInline {...fileType}></MyRadioInline>

          {/* 開始時間 */}
          <MyDatePicker {...startDatetime}></MyDatePicker>

          {/* 動画長さ */}
          <MyRowNumber {...videoLength}></MyRowNumber>

          {/* 映像ch. */}
          <MyCheckboxInline {...vChannel}></MyCheckboxInline>

          {/* 音声ch. */}
          <MyCheckboxInline {...aChannel}></MyCheckboxInline>

          <Button type="submit" variant="primary" className="pl-3 pr-3 mr-5 mt-2" disabled={isRequesting} size="sm">{requestButton}</Button>
          <Button variant="secondary" className="pl-3 pr-3 mt-2" disabled={isRequesting} size="sm" onClick={() => window.close()}>閉じる</Button>
        </Form>
      </div>
    </>
  );
};