import { AxiosRequestConfig } from "axios";
import { MyRowMail } from "components/parts/InputRow";
import { API_GATEWAY } from "constants/aws";
import { ENDPOINT } from "constants/endpoint";
import { m } from "constants/message";
import { REQUEST_NEW_PASSWORD, SIGN_IN } from "pages/pageInfo";
import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import { request } from "util/request";


export const RequestNewPassword: React.FC<any> = (props) =>{
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [finished, setFinished] = useState<boolean>(false);

  useEffect(()=>{
    document.title = REQUEST_NEW_PASSWORD.title;
  }, []);

  // submitボタンのハンドラ
  const handleSubmit = async (event: React.MouseEvent<HTMLInputElement>) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() === false) {
      await setValidated(true);
      return;
    }

    setIsRequesting(true); // APIの二重コール防止用
    setErrorMessage(null);

    // POSTリクエスト
    const config: AxiosRequestConfig = {
      method: "post",
      data: { email: email, },
      headers: {
        "x-api-key": API_GATEWAY.API_KEY,
      },
    };

    await request(ENDPOINT.OFFER_UPDATE_PASSWORD, config, setErrorMessage, false)
      .then((res: any) =>{ setFinished(true); })
      .catch((err: any)=>{ setIsRequesting(false); }
      );
  };

  // 入力欄に入力した時のハンドラ
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, ) => {
    // 空文字列はnullに変換する
    setEmail(event.target.value);
  };

  // 申込の実施前後の共通ヘッダ
  const PageHeaderDom = (
    <>
      <div className="pl-5 pr-5 bg-light">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <img src="/img/logo/elematec_logo.png" alt="logo" width="150" className="navbar-brand m-0 p-0"></img>
        </nav>
      </div>
    </>
  );

  // リクエスト完了時に表示する画面
  if(finished){
    return (
      <>
        {PageHeaderDom}
        <div className="form-signin" style={{width: "600px"}}>
          <div className="d-flex">
            <h4 className="mb-4 mx-auto">パスワード再設定の申込完了</h4>
          </div>
          <span>
          パスワード再設定の手順を記載したメールを指定のメールアドレス宛に送信しました。<br/>
          しばらくしてもメールが届かない場合、再度申し込みを行ってください。
          </span>
          <div>
            <Button variant="outline-primary" className="my-4 mx-auto px-3" href={SIGN_IN.path} size="lg">ログイン画面へ戻る</Button>
          </div>
        </div>
      </>
    );
  }

  // メールアドレス
  const emailSetting = {
    label: "メールアドレス",
    attrName: "email",
    required: true,
    value: email,
    maxLength: 256,
    md: "",
    message: m("V0007") + m("V0011", "256"),
    onChange: handleOnChange,
  };

  // エラーメッセージの生成
  const alert = errorMessage != null
    ? <Alert variant="danger" className="mt-2" dismissible onClose={e => setErrorMessage(null)}><strong>エラー：</strong>{errorMessage}</Alert>
    : null;

  // 送信ボタンの表示
  const sendButton = !isRequesting
    ? "送信"
    : <Spinner style={{width: "2rem", height: "2rem"}} as="span" animation="border" size="sm" role="status" aria-hidden="true" />;

  return (
    <>
      {PageHeaderDom}

      <div className="form-newpassword">
        <div className="d-flex">
          <h4 className="mb-4 mx-auto">パスワードを再設定しますか？</h4>
        </div>
        {alert}
        <Form noValidate validated={validated} onSubmit={handleSubmit}>

          {/* メールアドレス */}
          <MyRowMail {...emailSetting}></MyRowMail>

          <div className="d-flex">
            <Button type="submit" className="d-block my-4 ml-auto mr-3 px-5" disabled={isRequesting} size="lg">
              {sendButton}
            </Button>
            <Button variant="outline-primary" className="d-block my-4 mr-auto ml-3 px-3" href={SIGN_IN.path} size="lg">
              キャンセル
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};