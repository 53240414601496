import axios, { AxiosResponse } from "axios";
import * as AWS from "constants/aws";
import jwt from "jsonwebtoken";
import jwkToPem from "jwk-to-pem";

/** IDトークンからデコードされた状態でヘッダ部分のみ抽出す */
export const getHeader = (idToken: string) => {
  const a: any = jwt.decode(idToken, { complete: true });
  return a.header;
};

/** IDトークンからKIDを取得 */
export const getKid = (idToken: string): string => {
  return getHeader(idToken).kid;
};

/** Jwksを取得する */
export const getJwks: any = async (userPoolId: string)=> {
  const url: string = `https://cognito-idp.ap-northeast-1.amazonaws.com/${userPoolId}/.well-known/jwks.json`;
  const res: AxiosResponse = await axios.get(url);
  return res.data;
};

/** IDトークンのkidに対応したJWKを取得する */
export const getJwk: any = async (idToken: string, userPoolId: string) => {
  let kid = getKid(idToken);

  let jwks = await getJwks(userPoolId);

  // IDトークンのkidと一致する方のjwkを採用する
  var jwk = {};
  for (var item of jwks.keys) {
    if (kid === item.kid) {
      jwk = item;
    }
  }
  return jwk;
};

/** JWKから公開鍵を生成する */
export const generatePemFromJwk = (jwk: jwkToPem.RSA) => {
  return jwkToPem(jwk);
};

/** IDトークンのkidと一致するJWKと、そこから生成される公開鍵を取得する */
export const generateJwkPemForIdToken = async (idToken: string, userPoolId: string = AWS.COGNITO.USER_POOL_ID): Promise<{"jwk": any, "pem": string}> => {
  var jwk = await getJwk(idToken, userPoolId);
  var pem = generatePemFromJwk(jwk);
  return { jwk: jwk, pem: pem };
};

/**
 * IDトークンの検証処理
 */
export const checkIdToken = (idToken: string, alg: any, pem: string): boolean | void => {
  try {
    // IDトークンの検証
    var ret = jwt.verify(idToken, pem, { algorithms: [alg], ignoreExpiration: false }, function (err, decodedToken) {
      // トークンの検証エラーが発生している場合、検証NG
      // ※改ざんの検証、期限切れの検証は実施してくれるので、発行者(iss)の検証だけ自力でやる

      // 改ざん、期限切れNGの場合
      if (err) {
        console.error(err.message);
        return false;
      }

      // TSのコンパイラが怒るので一回変換する
      const _decodedToken = decodedToken as {iss: string};

      // 発行者（iss）がCognitoのエンドポイント＋自分のユーザプールIDであることの確認
      if (_decodedToken.iss !== AWS.COGNITO.ENDPOINT_URL + AWS.COGNITO.USER_POOL_ID) {
        return false;
      }
      return true;
    });
    return ret;

    // 処理中に例外が発生した場合はNG
  } catch (err) {
    console.error(err);
    return false;
  }
};

/** 引数のIDトークンとローカルストレージのIDトークンが存在しているかどうか */
export const getIdTokenFromLocalStorage = (): string|null => {
  // CognitoIdentityServiceProvider.2bfjuetnn146shhiq8a4vllelc.LastAuthUser
  // CognitoIdentityServiceProvider.2bfjuetnn146shhiq8a4vllelc.hosoya.idToken

  var template = "CognitoIdentityServiceProvider." + AWS.COGNITO.USER_POOL_WEB_CLIENT_ID + ".";
  // 認証したユーザ名取得
  var name = localStorage.getItem(template + "LastAuthUser");
  // IDトークン取得
  return localStorage.getItem(template + name + ".idToken");
};
