import { AxiosRequestConfig } from "axios";
import { MyRow } from "components/parts/InputRow";
import { API_GATEWAY } from "constants/aws";
import { ENDPOINT } from "constants/endpoint";
import { m } from "constants/message";
import { ISSUE_NEW_PASSWORD, SIGN_IN } from "pages/pageInfo";
import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { request } from "util/request";

interface PathParameter {
  code: string
}

export const IssueNewPassword: React.FC<any> = (props) =>{
  const {code} = useParams<PathParameter>();
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [validated, setValidated] = useState(false);
  const [password, setPassword] = useState<string>("");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [finished, setFinished] = useState<boolean>(false);

  useEffect(()=>{
    document.title = ISSUE_NEW_PASSWORD.title;
  }, []);

  // submitボタンのハンドラ
  const handleSubmit = async (event: React.MouseEvent<HTMLInputElement>) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    validateNewEqualComfirm(password, passwordConfirm);

    if (form.checkValidity() === false) {
      await setValidated(true);
      return;
    }

    setIsRequesting(true); // APIの二重コール防止用
    setErrorMessage(null);

    // POSTリクエスト
    const config: AxiosRequestConfig = {
      method: "post",
      data: { password: password, code: code},
      headers: {
        "x-api-key": API_GATEWAY.API_KEY,
      },
    };

    await request(ENDPOINT.UPDATE_PASSWORD, config, setErrorMessage, false)
      .then((res: any) =>{ setFinished(true); })
      .catch((err: any)=>{ setIsRequesting(false); }
      );
  };

  // 入力欄に入力した時のハンドラ
  const handleOnChangeNew = (event: React.ChangeEvent<HTMLInputElement>, ) => {
    // 空文字列はnullに変換する
    setPassword(event.target.value);

    // 相関チェック
    if(validated){
      validateNewEqualComfirm(event.target.value, passwordConfirm);
    }
  };

  // 入力欄に入力した時のハンドラ
  const handleOnChangeConfirm = (event: React.ChangeEvent<HTMLInputElement>, ) => {

    // 空文字列はnullに変換する
    setPasswordConfirm(event.target.value);

    // 相関チェック
    if(validated){
      validateNewEqualComfirm(password, event.target.value);
    }
  };

  // 新パスワードと新パスワード確認の相関チェック
  const validateNewEqualComfirm = (password: string, confirm: string) => {
    const dom: HTMLInputElement | null = document.querySelector("#passwordConfirm");
    if(dom === null){
      return;
    }

    // 一致していない場合、確認の方をバリデートエラー状態にする
    if(password !== confirm){
      dom.setCustomValidity("err");
    } else {
      dom.setCustomValidity("");
    }
  };

  // 申込の実施前後の共通ヘッダ
  const PageHeaderDom = (
    <>
      <div className="pl-5 pr-5 bg-light">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <img src="/img/logo/elematec_logo.png" alt="logo" width="150" className="navbar-brand m-0 p-0"></img>
        </nav>
      </div>
    </>
  );

  // リクエスト完了時に表示する画面
  if(finished){
    return (
      <>
        {PageHeaderDom}
        <div className="form-signin" style={{width: "600px"}}>
          <div className="d-flex">
            <h4 className="mb-4 mx-auto">パスワード再発行完了</h4>
          </div>
          <span>パスワード再発行が完了しました。
          </span>
          <div>
            <Button variant="outline-primary" className="my-4 mx-auto px-3" href={SIGN_IN.path} size="lg">ログイン画面へ戻る</Button>
          </div>
        </div>
      </>
    );
  }

  // パスワード
  const passwordSetting = {
    type: "password",
    label: "パスワード",
    attrName: "password",
    required: true,
    value: password,
    maxLength: 256,
    md: "",
    pattern: "^(?=.*?[a-z])(?=.*?\\d).{8,}$",
    message: m("V0004"),
    placeholder: "新しいパスワードを入力してください",
    onChange: handleOnChangeNew,
  };

  // パスワード確認
  const passwordConfirmSetting = {
    type: "password",
    label: "パスワード確認",
    attrName: "passwordConfirm",
    required: true,
    value: passwordConfirm,
    maxLength: 256,
    md: "",
    pattern: ".+",
    message: m("V0005"),
    placeholder: "確認のため、新しいパスワードをもう一度入力してください",
    onChange: handleOnChangeConfirm,
  };

  // エラーメッセージの生成
  const alert = errorMessage != null
    ? <Alert variant="danger" className="mt-2" dismissible onClose={e => setErrorMessage(null)}>
      <span dangerouslySetInnerHTML={{__html: `<strong>エラー：</strong>${errorMessage.replace("\n", "<br>")}`}}></span>
    </Alert>
    : null;

  // 変更ボタンの表示
  const sendButton = !isRequesting
    ? "変更"
    : <Spinner style={{width: "2rem", height: "2rem"}} as="span" animation="border" size="sm" role="status" aria-hidden="true" />;

  return (
    <>
      {PageHeaderDom}

      <div className="form-newpassword">
        <div className="d-flex">
          <h4 className="mb-4 mx-auto">新パスワードを入力してください</h4>
        </div>
        {alert}

        <Alert variant="info" className="mt-2 pb-0" >
          <Alert.Heading as={"h6"}>パスワードポリシー</Alert.Heading>
          <ul>
            <li>8文字以上</li>
            <li>英小文字、数字を含む</li>
          </ul>
        </Alert>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>

          {/* パスワード */}
          <MyRow {...passwordSetting}></MyRow>

          {/* パスワード確認 */}
          <MyRow {...passwordConfirmSetting}></MyRow>

          <div className="d-flex">
            <Button type="submit" className="d-block my-4 ml-auto mr-3 px-5" disabled={isRequesting} size="lg">
              {sendButton}
            </Button>
            <Button variant="outline-primary" className="d-block my-4 mr-auto ml-3 px-3" href={SIGN_IN.path} size="lg">
              キャンセル
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};