import { AxiosRequestConfig } from "axios";
import "chartjs-plugin-annotation";
import { TimelapsePanel } from "components/parts/Timelapse";
import { ENDPOINT } from "constants/endpoint";
import { useUserInfoContext } from "contexts/userInfoContext";
import GoogleMapReact, { MapOptions, Maps } from "google-map-react";
import jwt from "jsonwebtoken";
import { MENU, MOVIE_PLAYBACK, SESSION_TIMEOUT } from "pages/pageInfo";
import queryString from "query-string";
import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Spinner, Tab, Tabs } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { request } from "util/request";

type COLS = "movie_datetime" | "user_name" | "vehicle_name" | "event_type" | "mng_id" ;

interface TimelapseProps {
  alt: string;
  src: string
}

interface QueryParams {
  source: string,
  type: string,
  mng_ids: string,
  main_mng_id_num: number,
  event_ids: string,
  main_event_id: number,
  main_mng_id: number
}
const GoogleMapWrapper = styled.div`
  height: 65vh;
  width: 100%;
  float: left;
  `;

const Logo = styled.img`
  position: absolute;
  width: 40px;
  height: 40px;
  left: -20px;
  top: -20px;
  textAlign: center;
  padding: 4px;
  `;

const Pin = styled.div<{
    lat: number,
    lng: number
  }>`
  `;

const google_map_key = "AIzaSyBA3-5wbmyiAJ21Fu65g6QpX9kR6RfZCrw";

const danger_icon = "/img/map_icons/danger.png";


export const MoviePlayback: React.FC<any> = (props) => {
  const { signOut, } = useUserInfoContext();

  const [isRequestingInfo, setIsRequestingInfo] = useState<boolean>(false);
  const [isRequestingPlaylist, setIsRequestingPlaylist] = useState<boolean>(false);
  const [data, setData] = useState<any>(null); // 初期状態：null、エラー時：undefined とする
  const [data_playlist, setData_playlist] = useState<any>(null); // 初期状態：null、エラー時：undefined とする
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [queryParams, setQueryParams] = useState<QueryParams>({
    "source": "",
    "type": "",
    "mng_ids": "",
    "main_mng_id_num": 0,
    "event_ids": "",
    "main_event_id": 0,
    "main_mng_id": 0
  });
  const history = useHistory();

  const [timelapseHandle, setTimelapseHandle] = useState<null | boolean>(null);
  const startTimelapse = () => {
    // ウインドウサイズに合わせて画像のサイズを調整する
    resizeTimelaps();

    // 読み込みが終わり次第、再生する場合はこのようにする
    setTimelapseHandle(true);
  };
  const [timelapseHandle2, setTimelapseHandle2] = useState<null | boolean>(null);
  const startTimelapse2 = () => {
    // setTimelapseHandleを使用しないとwarningが出てしまうので取り合えず入れる
    // 連続描画が正常に行われるようにnullを設定する
    setTimelapseHandle2(null);
  };
  const [timelapseHandle3, setTimelapseHandle3] = useState<null | boolean>(null);
  const startTimelapse3 = () => {
    // setTimelapseHandleを使用しないとwarningが出てしまうので取り合えず入れる
    // 連続描画が正常に行われるようにnullを設定する
    setTimelapseHandle3(null);
  };
  const [timelapseHandle4, setTimelapseHandle4] = useState<null | boolean>(null);
  const startTimelapse4 = () => {
    // setTimelapseHandleを使用しないとwarningが出てしまうので取り合えず入れる
    // 連続描画が正常に行われるようにnullを設定する
    setTimelapseHandle4(null);
  };

  // RoadsToAPIで使用
  const [isRequestingRoad, setIsRequestingRoad] = useState<boolean>(false);
  const [roadData, setRoadData] = useState<any>([]);
  const [roadRequestParams, setRoadRequestParams] = useState<any>([]);

  useEffect(() => {
    // アンマウントされる際にTimelapsePanelのタイマーを止める
    return ()=>{
      setTimelapseHandle(false);
      setTimelapseHandle2(false);
      setTimelapseHandle3(false);
      setTimelapseHandle4(false);
    };
  }, []);

  // 再生リストパネルの高さの設定
  const [listMaxHeight, setListMaxHeight] = useState<number>(0);

  // 再生リストの高さサイズを再計算＆再設定する
  const resizeListMaxHeight = () =>{
    const maxheight = document.getElementById("panel_movie_play")?.offsetHeight;
    if(maxheight !== undefined){
      setListMaxHeight(maxheight - 80);
    } else {
      setListMaxHeight(0);
    }
  };

  // 動画の黒背景のサイズ調整
  const resizeNoVideoDom = () => {
    const video = document.querySelector("video") as HTMLElement;
    const videox = video?.offsetWidth;
    const videoy = video?.offsetHeight;

    document.querySelectorAll(".js-no_video").forEach(dom=>{
      // videoと同じ大きさで黒背景のdiv要素を作る
      const _dom = dom as HTMLElement;
      _dom.style.width = videox + "px";
      _dom.style.height = videoy + "px";
      _dom.style.backgroundColor = "black";

      // videoを囲っているdiv部分とサイズが合わないので無理やり調整する
      const _div = dom.parentElement as HTMLElement;
      _div.style.width = videox + 5 + "px";
      _div.style.height = videoy + 5 + "px";
    });
  };

  // 危険挙動の画像サイズ調整
  const [timelapsx , setTimelapsx] = useState<number>(480);
  const resizeTimelaps = () => {
    const dom = document.querySelector(".js-timelapse_top") as HTMLElement;
    if(dom === null){
      return null;
    }

    // -8 は ".js-timelapse_top > #timelapse_top0-4" にpaddingしている分を無理やり調整している
    // ※padding分までcanvasが広がってしまって隙間ができなくなるため
    setTimelapsx(dom.offsetWidth >= 488 ? 480 : dom.offsetWidth - 8);
  };

  // 静止画の画像サイズ調整
  const [snapshotx , setSnapshotx] = useState<number>(480);
  const resizeSnapshot = () => {
    const dom = document.querySelector(".js-snapshot_top") as HTMLElement;
    if(dom === null){
      return null;
    }

    setSnapshotx(dom.offsetWidth >= 480 ? 480 : dom.offsetWidth);
  };

  useEffect(()=>{
    // ウインドウがリサイズされた際に再生リストの高さサイズを再計算＆再設定するイベントリスナー追加
    window.addEventListener( "resize", resizeListMaxHeight );
    window.addEventListener( "resize", resizeNoVideoDom );
    window.addEventListener( "resize", resizeTimelaps );
    window.addEventListener( "resize", resizeSnapshot );
    return ()=>{
      window.removeEventListener("resize", resizeListMaxHeight);
      window.removeEventListener("resize", resizeNoVideoDom);
      window.removeEventListener("resize", resizeTimelaps);
      window.removeEventListener("resize", resizeSnapshot);
    };
  }, []);

  // canplayへのイベントリスナーを１回だけ追加させるための制御フラグ
  const addedevent = useRef<boolean>(false);
  useEffect(()=>{
    const video = document.querySelector("video") as HTMLElement;
    if(video !== null && !addedevent.current){
      addedevent.current = true;
      video.addEventListener("canplay", resizeNoVideoDom);
    }
  });

  useEffect(()=>{
    // クエリストリング取得
    if(queryParams["source"] === ""){
      // source: 01:走行履歴詳細画面,02:動態管理画面/動画情報取得,03:外部連携サービス
      //         04:走行履歴詳細画面/危険挙動,05:走行履歴詳細画面/静止画一覧,06:動態管理画面/危険挙動
      const source = queryString.parse(history.location.search)["source"] as string;
      // type: movie/snapshot
      const type = queryString.parse(history.location.search)["type"] as string;
      // mng_ids: 1111,2222,3333（カンマ区切り）
      const mng_ids = queryString.parse(history.location.search)["mng_ids"] as string;
      // main_mng_id_num: 1111
      const main_mng_id_num
       = parseInt(queryString.parse(history.location.search)["main_mng_id_num"] as string);
      // いずれかのパラメーターが存在していな場合は、TOPページへ遷移させる
      if(isNaN(main_mng_id_num) || mng_ids === undefined
        || source === undefined || type === undefined){
        history.replace(MENU.path);
      }

      // type: movie/snapshot
      const event_ids
         = queryString.parse(history.location.search)["event_id"] as string;
      // 表示対象の管理IDを決定する
      var main_mng_id = 0;
      if(mng_ids !== undefined){
        main_mng_id = parseInt(mng_ids.split(",")[main_mng_id_num]) as number;
      }

      var main_event_id = 0;
      if(event_ids !== undefined){
        main_event_id = parseInt(event_ids.split(",")[main_mng_id_num]) as number;
      }

      setQueryParams({...queryParams, source: source, type: type, mng_ids: mng_ids, main_mng_id_num: main_mng_id_num, event_ids: event_ids,main_event_id: main_event_id, main_mng_id: main_mng_id});
    }
  }, [history, queryParams]);

  useEffect(() => {
    document.title = MOVIE_PLAYBACK.title;

    // 表示用データが空で、リクエスト中でもない場合
    // リクエストを投げてデータを取得する
    if(data === null && !isRequestingInfo && queryParams["source"] !== ""){
      let req_data: any = {
        type: queryParams["type"],
        mng_id: queryParams["main_mng_id"]
      };

      if(!isNaN(queryParams["main_event_id"])){
        req_data["event_id"] = queryParams["main_event_id"];
      }

      (async ()=>{
        setIsRequestingInfo(true); // APIの二重コール防止用
        const config: AxiosRequestConfig = {
          method: "post",
          data: req_data, // クエリパラメータ
        };

        await request(ENDPOINT.MOVIE_INFO_GET, config, setErrorMessage)
          .then((res: any) =>{ setData(res.data); setIsRequestingInfo(false); setRoadRequestParams(res.data.map_graph_info.data);})
          .catch((err: any)=>{
            // トークン不正時、サインアウト処理してreturn ※サインアウト処理の中でタイムアウト画面へ遷移させる
            if(err instanceof jwt.JsonWebTokenError){ signOut(SESSION_TIMEOUT.path); return; }

            setData(undefined); // nullにしたいけど、nullにすると無限にリクエストすることになるのでundefined使う
            setIsRequestingInfo(false); // 必ずsetDataの方が先（dataがnullのまま先にisRequestingをfalseにすると2回目のリクエストを投げるので。。）
            return;
          }
          );
      })();
    }

    // 表示用データが空で、リクエスト中でもない場合
    // リクエストを投げてデータを取得する
    if(data_playlist === null && !isRequestingPlaylist && queryParams["source"] !== ""){
      (async ()=>{
        setIsRequestingPlaylist(true); // APIの二重コール防止用

        // GETリクエスト
        // 再生リスト検索用の管理IDとイベントIDのオブジェクトのリストを生成する
        const playlistIds = makePlaylistIds(queryParams["mng_ids"], queryParams["event_ids"]);

        const config: AxiosRequestConfig = {
          method: "post",
          data: { type: queryParams["type"], infos: playlistIds }, // クエリパラメータ
        };

        await request(ENDPOINT.MOVIE_PLAYLIST_GET, config, setErrorMessage)
          .then((res: any) =>{ setData_playlist(res.data); setIsRequestingPlaylist(false); })
          .catch((err: any)=>{
            // トークン不正時、サインアウト処理してreturn ※サインアウト処理の中でタイムアウト画面へ遷移させる
            if(err instanceof jwt.JsonWebTokenError){ signOut(SESSION_TIMEOUT.path); return; }

            setData_playlist(undefined); // nullにしたいけど、nullにすると無限にリクエストすることになるのでundefined使う
            setIsRequestingPlaylist(false); // 必ずsetDataの方が先（dataがnullのまま先にisRequestingをfalseにすると2回目のリクエストを投げるので。。）
            return;
          }
          );
      })();
    }
  });

  useEffect(() => {
    // リクエストパラメータ用のデータがあり、リクエスト中でもない場合
    // リクエストを投げてデータを取得する
    if(data !== null && data !== undefined){
      if("data" in data.map_graph_info){
        if (roadRequestParams.length > 0 && !isRequestingRoad) {
          (async ()=>{
            setIsRequestingRoad(true); // APIの二重コール防止用

            let path = "";
            // リクエストパラメータの制限が100地点までなので、100件だけ取り出す
            roadRequestParams.slice(0, 100).map((Item: any) => {
              return path += Item.lat + "," + Item.lon + "|";
            });
            path = path.slice(0, -1); // 末尾の"|"を削除

            // GETリクエスト（Roads API）
            const config: AxiosRequestConfig = {
              method: "get",
            };

            await request("https://roads.googleapis.com/v1/snapToRoads?path=" + path + "&interpolate=true&key=" + google_map_key, config, setErrorMessage, false)
              .then((res: any) => {
                setRoadData(() => roadData.concat(res.data.snappedPoints));
                setRoadRequestParams(() => roadRequestParams.slice(100)); // 100件分のデータを削除する
                setIsRequestingRoad(false);
              })
              .catch((err: any)=> {
                setRoadData([]);
                setRoadRequestParams([]);
                setIsRequestingRoad(false); // 必ずsetRoadRequestParamsの方が先（先にisRequestingをfalseにすると2回目のリクエストを投げるので。。）
                return;
              }
              );
          })();
          // }


        }
      }

    }

  }, [data, isRequestingRoad, roadData, roadRequestParams]);


  // 画面表示用のレスポンスを待っている間はSpinner表示
  if(!data && isRequestingInfo){
    return (
      <>
        <div className="mt-1 ml-5 mr-5">
          <Spinner animation="border" className="mt-4" style={{width: "5rem", height: "5rem"}}/>
        </div>
      </>
    );
  }

  // 動画再生リスト取得APIに渡すオブジェクトを生成する
  function makePlaylistIds(mng_ids: string, event_ids: string){
    var objList: any[] = [];
    var event_idList: any[] = [];
    if(event_ids !== null && event_ids !== "" && event_ids !== undefined){
      event_idList = event_ids.split(",");
    }

    mng_ids.split(",").forEach((mng_id: string,index: number) => {
      let obj: any = {};
      obj["mng_id"] = parseInt(mng_id) as number;
      if(event_idList.length !== 0 && event_idList[index] !== null && event_idList[index] !== ""){
        obj["event_id"] = parseInt(event_idList[index]) as number;
      }
      objList.push(obj);
    });
    return objList;
  }

  // 動画再生リストのヘッダ生成
  const cols: {name: string, id: COLS}[] = [
    {"name": "発生日時", "id": "movie_datetime"},
    {"name": "ドライバー", "id": "user_name"},
    {"name": "車両名", "id": "vehicle_name"},
    {"name": "危険挙動", "id": "event_type"},
    {"name": "動画／静止画", "id": "mng_id"}
  ];
  const listHeader = cols.map(col => {
    return <th key={col.id}>{col.name}</th>;
  });

  // 動画詳細パネル・詳細情報タブ部分の生成（レスポンスデータが無い間はLoading表示）
  const movie_info = !data
    ? null
    : (
      <>
        <p/>
        <h6>詳細情報</h6>
        <table className="table" style={{tableLayout: "fixed"}}>
          <tbody>
            <tr>
              <td className="border">発生日時</td>
              <td className="border">
                <p style={{height: "5px"}}>
                  { data.type === "movie"
                  // 動画の場合
                    ? data.movie_details.movie_start_datetime.replace(/-/g,"/")
                  // スナップショット/危険挙動の場合
                    : data.snapshot_details.snapshot_datetime.replace(/-/g,"/") }
                </p>
                <p style={{height: "5px"}}>
                  { data.type === "movie"
                  // 動画の場合
                    ? "~" + data.movie_details.movie_end_datetime.replace(/-/g,"/")
                  // スナップショット/危険挙動の場合
                    : null}
                </p>
              </td>
            </tr>
            <tr>
              <td className="border">危険挙動</td>
              <td className="border">
                {data.type === "movie"
                // 動画の場合
                  ? "-"
                // スナップショット/危険挙動の場合
                  : data.snapshot_details.event_type }
              </td>
            </tr>
            <tr>
              <td className="border">車両名</td>
              <td className="border">{data.vehicle_name}</td>
            </tr>
            <tr>
              <td className="border">ドライバー</td>
              <td className="border">{data.driver_name}</td>
            </tr>
            <tr>
              <td className="border">経緯度</td>
              <td className="border">
                {/* <p style={{height: "5px"}}> */}
                { data.type === "movie"
                  // 動画の場合
                  ? data.movie_details.lat_from !== null && data.movie_details.lon_from !== null
                    // 経緯度がnullでなければ表示
                    ? "E" + data.movie_details.lat_from + " N" + data.movie_details.lon_from
                    : ""
                  // スナップショット/危険挙動の場合
                  : data.snapshot_details.lat !== null && data.snapshot_details.lon !== null
                    // 経緯度がnullでなければ表示
                    ? "E" + data.snapshot_details.lat + " N" + data.snapshot_details.lon
                    : ""
                }
                {/* </p> */}<br/>
                {/* <p style={{height: "5px"}}> */}
                { data.type === "movie"
                  // 動画の場合
                  ? data.movie_details.lat_to !== null && data.movie_details.lon_to !== null
                    // 経緯度がnullでなければ表示
                    ? "~ E" + data.movie_details.lat_to + " N" + data.movie_details.lon_to
                    : ""
                  // スナップショット/危険挙動の場合
                  : null
                }
                {/* </p> */}
              </td>
            </tr>
            <tr>
              <td className="border">方位</td>
              <td className="border">
                {data.type === "movie"
                // 動画の場合
                  ? data.movie_details.heading_from !== null && data.movie_details.heading_to !== null
                    // 方位がnullでなければ表示
                    ? change_heading_to_jap(data.movie_details.heading_from) + "~" + change_heading_to_jap(data.movie_details.heading_to)
                    : ""
                // スナップショット/危険挙動の場合
                  : data.snapshot_details.heading !== null
                    // 方位がnullでなければ表示
                    ? change_heading_to_jap(data.snapshot_details.heading)
                    : ""
                }
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );

  // 方角（数値）を日本語表記に変換する
  function change_heading_to_jap(heading: number): string {

    const res = heading === 0 ? "北"
      : heading < 90 ? "北東"
        : heading === 90 ? "東"
          : heading < 180 ? "南東"
            : heading === 180 ? "南"
              : heading < 270 ? "南西"
                : heading === 270 ? "西"
                  : heading < 360 ? "北西"
                    : "北";
    return res;
  }

  // 再生ボタン押下時イベント
  const handleMoviePlay = () => {
    // 画面内の動画をすべて再生させる
    let videos: HTMLCollectionOf<HTMLMediaElement>;
    videos = document.getElementsByTagName("video");
    var elements = Array.from(videos);
    elements.forEach(v =>
      v.play()
    );
  };

  // 再生ボタン
  const moviePlayButton
    = <Button variant="dark" className="mr-2 mb-2" onClick={handleMoviePlay}>
      <img width="30" height="30" src="/img/playlist/play.png" alt="再生" /></Button>;

  // 一時停止ボタン押下時イベント
  const handleMoviePause = () => {
    // 画面内の動画全てを一時停止する
    let videos: HTMLCollectionOf<HTMLMediaElement>;
    videos = document.getElementsByTagName("video");
    var elements = Array.from(videos);
    elements.forEach(v =>
      v.pause()

    );
  };

  // 一時停止ボタン
  const moviePauseButton
    = <Button variant="dark" className="mr-2 mb-2" onClick={handleMoviePause}>
      <img width="30" height="30" src="/img/playlist/pause.png" alt="一時停止" /></Button>;

  // 停止押下時イベント
  const handleMovieEnd = () => {
    // 画面内の動画をすべて再生させる
    let videos: HTMLCollectionOf<HTMLMediaElement>;
    videos = document.getElementsByTagName("video");
    var elements = Array.from(videos);
    elements.forEach(v =>
      v.pause()
    );
    elements.forEach(v =>
      v.currentTime = 0
    );

  };
    // 停止ボタン
  const movieEndButton
    = <Button variant="dark" className="mr-2 mb-2" onClick={handleMovieEnd}>
      <img width="30" height="30" src="/img/playlist/stop.png" alt="停止" /></Button>;


  // -10押下時イベント
  const handleMovieBack = () => {
    // 画面内の動画をすべて再生させる
    let videos: HTMLCollectionOf<HTMLMediaElement>;
    videos = document.getElementsByTagName("video");
    var elements = Array.from(videos);
    elements.forEach(v =>
      v.currentTime = v.currentTime - 10
    );
  };
    // -10ボタン
  const movieBackButton
    = <Button variant="dark" className="mr-2 mb-2" onClick={handleMovieBack}>
      <img width="30" height="30" src="/img/playlist/minus10.png" alt="10秒戻し" /></Button>;

  // +10押下時イベント
  const handleMovieAdd = () => {
    // 画面内の動画をすべて再生させる
    let videos: HTMLCollectionOf<HTMLMediaElement>;
    videos = document.getElementsByTagName("video");
    var elements = Array.from(videos);
    elements.forEach(v =>
      v.currentTime = v.currentTime + 10
    );
  };
  // +10ボタン
  const movieAddButton
  = <Button variant="dark" className="mr-2 mb-2" onClick={handleMovieAdd}>
    <img width="30" height="30" src="/img/playlist/plus10.png" alt="10秒送り" />
  </Button>;


  // ミュート押下時イベント
  const handleMovieMute = () => {
    // 画面内の動画をすべてミュートにする
    let videos: HTMLCollectionOf<HTMLMediaElement>;
    videos = document.getElementsByTagName("video");
    // ビデオが1つ以上存在していた場合、idが「movie0」のものを取得する
    // これを基準とする
    let status = true;
    let main_video: HTMLMediaElement;
    if(videos.length > 0){
      main_video = document.getElementById("video0") as HTMLMediaElement;
      if(main_video !== null){
        // 基準となるvideo0の音声状態を取得
        status = main_video.muted;
      }
    }
    var elements = Array.from(videos);
    elements.forEach(v => {
      // 基準の音声がミュートの場合は、全てミュート解除
      if(status){
        v.muted = false;
      }else{
        // 基準の音声がミュート解除の場合は、全てミュート
        v.muted = true;
      }
    });
  };
  // ミュートボタン
  const movieMuteButton
    = <Button variant="dark" className="mb-2" onClick={handleMovieMute}>
      <img width="30" height="30" src="/img/playlist/mute.png" alt="ミュート" />
    </Button>;

  // リスト部分の生成（レスポンスデータが無い間はLoading表示）
  const movie_playlist = !data_playlist
    ? <Spinner animation="border" className="mt-4" style={{width: "5rem", height: "5rem"}}/>
    : (
      <>
        <p/>
        <h6>再生リスト</h6>
        <div id="hoge" style={{overflowY: "auto", maxHeight: listMaxHeight}}>
          <table className="table table-striped" style={{tableLayout: "fixed"}}>
            <thead>
              <tr>
                {listHeader}
              </tr>
            </thead>
            <tbody>
              {
                data_playlist.data.map((item: any, index: number)=>{
                  return (
                    <React.Fragment key={item.mng_id}>
                      <tr>
                        {/* 発生日時 */}
                        <td><div style={{wordBreak: "break-all"}}>
                          {data_playlist.type === "movie"
                            ? item.movie_start_datetime.replace(/-/g,"/")
                          + "~" + item.movie_end_datetime.replace(/-/g,"/")
                            : item.occurreddatetime.replace(/-/g,"/")}
                        </div>
                        </td>
                        {/* ドライバー */}
                        <td>{item.driver_name}</td>
                        {/* 車両名 */}
                        <td>{item.vehicle_name}</td>
                        {/* 危険挙動 */}
                        <td>{item.event_type}</td>
                        {/* 動画 */}
                        <td>
                          {data_playlist.type === "snapshot"
                            ? <a href={`${MOVIE_PLAYBACK.path}?source=01&type=${queryParams["type"]}&mng_ids=${queryParams["mng_ids"]}&main_mng_id_num=${index}&event_id=${queryParams["event_ids"]}`}>
                              <img alt="Link" src="/img/images.png" width="35%" height="auto"/>
                            </a>
                            : <a href={`${MOVIE_PLAYBACK.path}?source=01&type=${queryParams["type"]}&mng_ids=${queryParams["mng_ids"]}&main_mng_id_num=${index}`}>
                              <img alt="Link" src="/img/movie.png" width="45%" height="auto"/>
                            </a>
                          }
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </>
    );

  // 再生パネル（動画）
  const play_movie_panel = !data
    ? null
    : data.type === "movie"
      ? (
        <div>
          <div className="row">
            {
              make_movie_players(data).map(function(item, index: any){
                return (
                  <React.Fragment key={"movie-" + index}>
                    <div className="col-md-6 px-1">
                      {/* 動画が存在している場合 */}
                      { item !== "/img/blackout.png" ?
                        <video controls id={"video" + index} width="100%">
                          <source src={item} />
                        </video>
                        // 動画が存在していない場合は、ブラックアウト画像を配置する
                        : <div className="js-no_video"></div>
                      }
                    </div>
                  </React.Fragment>
                );
              })
            }
          </div>
          <div className="row">
            <div className="mx-auto">
              {/* 再生ボタン */}
              {moviePlayButton}
              {/* 一時停止ボタン */}
              {moviePauseButton}
              {/* 停止ボタン */}
              {movieEndButton}
              {/* +10ボタン */}
              {movieBackButton}
              {/* +10ボタン */}
              {movieAddButton}
              {/* ミュートボタン */}
              {movieMuteButton}
            </div>
          </div>
        </div>
      )
      : null;

  // 動画の一覧を生成する
  function make_movie_players(data: { movies: { mp4_file_url: any; }[]; }){

    // 返却用：スナップショットの一覧
    var movie_list: any[] = [];
    // 1.取得してきた走行履歴データの動画一覧からチャネルごとのjpg_url_fileのリストを再生成する。
    const list = data.movies.map(function(item: {mp4_file_url: any;}){
      return item.mp4_file_url;
    });

    // 2.1で生成したリストを基に返却用のリストを生成する
    list.forEach((mp4_file_url) => {
      movie_list.push(mp4_file_url);
    });
    if(data.movies.length < 4){
      // 動画がない場合に表示させるブラックアウト画像
      const blImg = "/img/blackout.png";
      for(let i = movie_list.length; i < 4; i++){
        movie_list.push(blImg);
      }
    }
    return movie_list;

  }

  // 連続描画再生ボタン押下時イベント
  const handleEventsPlay = () => {
    // 画面内の画像の連続描画をすべて再生する
    setTimelapseHandle(true);
  };

  // 連続描画再生ボタン
  const eventsPlayButton
    = <Button variant="dark" className="mr-2 mb-2" onClick={handleEventsPlay}>
      <img width="30" height="30" src="/img/playlist/play.png" alt="再生" /></Button>;

  // 連続描画一時停止ボタン押下時イベント
  const handleEventsPause = () => {
    // 画面内の画像の連続描画をすべて一時停止する
    setTimelapseHandle(false);
  };

  // 連続描画一時停止ボタン
  const eventsPauseButton
    = <Button variant="dark" className="mr-2 mb-2" onClick={handleEventsPause}>
      <img width="30" height="30" src="/img/playlist/pause.png" alt="一時停止" /></Button>;

  // 再生パネル（連続描画:危険挙動）
  const play_events = !data
    ? null
    : data.type === "snapshot" && data.map_graph_info.event_type !== "-"
      ?
      (
        <div>
          <div className="row">
            <style>
              {"#timelapse_top0 canvas {display:block; padding-left: 8px; margin-left: auto}"}
              {"#timelapse_top1 canvas {display:block; padding-left: 8px;}"}
              {"#timelapse_top2 canvas {display:block; padding-left: 8px; margin-left: auto}"}
              {"#timelapse_top3 canvas {display:block; padding-left: 8px;}"}
            </style>
            {create_event_panels(data).map((item: TimelapseProps[], index) => {

              return (
                <React.Fragment key={"event" + index}>
                  <div className="col-md-6 p-0 mb-2">
                    <div id={`timelapse_top${index}`} className="js-timelapse_top" style={{width: "100%"}}>
                      <TimelapsePanel
                        width={timelapsx}
                        images={item} // 描画する画像オブジェクトを渡す
                        timelapseHandle={ // timelapseHandle2-4はnullでしか渡されない。全てのtimelapseHandleを動かすと挙動がおかしくなるため
                          index === 0
                            ? timelapseHandle
                            : index === 1
                              ? timelapseHandle2
                              : index === 2
                                ? timelapseHandle3
                                : timelapseHandle4} // boolean を渡すことで再生、停止が行える（デフォルトはnull）
                        preloadedCallback={
                          index === 0
                            ? startTimelapse // startTimelapse2-4はnull→nullにしかならない。全てのstartTimelapseがnull意外だと挙動がおかしくなるため
                            : index === 1
                              ? startTimelapse2
                              : index === 2
                                ? startTimelapse3
                                : startTimelapse4}
                      />
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
          <div className="row">
            <div className="mx-auto">
              {/* 連続描画再生ボタン */}
              {eventsPlayButton}
              {/* 連続描画一時停止ボタン */}
              {eventsPauseButton}
            </div>
          </div>
        </div>
      )
      : null;

  // 静止画一覧を連続表示させるためのリストを生成する
  function create_event_panels(data: { snapshot: { data: any; }[]; }){
    // 返却用：timelapseを使用するためのチャネルごとのプロパティのリスト
    var channel_list: TimelapseProps[][] = [] as TimelapseProps[][];
    // 1.取得してきた走行履歴データの静止画一覧からチャネルごとのjpg_url_fileのリストを再生成する。
    const list = data.snapshot.map(function(item: {data: any;}){
      return item.data.map(function(item: {jpg_file_url: any;}){
        return item.jpg_file_url;
      });
    });
    // 2.1で生成したリストを基に返却用のプロパティのリストを生成する
    list.forEach((jpg_file_url) => {
      // チャネル単位のプロパティのリスト
      var obj_list: TimelapseProps[] = [] as TimelapseProps[];
      jpg_file_url.forEach(function(element: any){
        // プロパティ
        let obj = {
          alt: "" as string,
          src: "" as string,
        } as TimelapseProps;
        // プロパティに値を設定する
        obj["src"] = element;
        obj["alt"] = "undefined";
        // チャネル単位のプロパティのリストに生成したプロパティを加える
        obj_list.push(obj);
      });
      // チャネル単位のプロパティのリストを返却用のリストに追加する
      channel_list.push(obj_list);
    });
    // 表示対象のチャネルごとの静止画一覧が4つ未満の場合は、ブラックアウトにするように不足分を追加する
    if(channel_list.length < 4){
      var obj_list2: TimelapseProps[] = [] as TimelapseProps[];
      // 画像がない場合に表示させるブラックアウト画像
      const blImg = "/img/blackout.png";
      for(let i = channel_list.length; i < 4; i++){
        let obj = {
          alt: "" as string,
          src: "" as string,
        } as TimelapseProps;
        obj["src"] = blImg;
        obj["alt"] = "undefined";
        obj_list2.push(obj);
        channel_list.push(obj_list2);
      }
    }
    return channel_list;
  }

  // 再生パネル（スナップショット）
  const play_snapshots = !data
    ? null
    : data.type === "snapshot" && data.map_graph_info.event_type === "-"
      ? (
        <div>
          <div className="row align-items-right">
            {create_snapshot_panels(data).map(function(item, index){

              const snapshot = index % 2 === 0
                ? <img width={snapshotx} height="auto" alt={"snapshot"} src={item} onLoad={resizeSnapshot} className={"d-block pl-2 ml-auto"} ></img>
                : <img width={snapshotx} height="auto" alt={"snapshot"} src={item} onLoad={resizeSnapshot} className={"d-block pl-2"} ></img>;

              return (
                <React.Fragment key={"snapshot-" + index}>
                  <div className="col-md-6 p-0 mb-2 js-snapshot_top">
                    {snapshot}
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      )
      : null;

  // 静止画表示させるためのリストを生成する
  function create_snapshot_panels(data: { snapshot: { data: any; }[]; }){
    // 返却用：スナップショットの一覧
    var snapshot_list: any[] = [];
    // 1.取得してきた走行履歴データの静止画一覧からチャネルごとのjpg_url_fileのリストを再生成する。
    const list = data.snapshot.map(function(item: {data: any;}){
      return item.data.map(function(item: {jpg_file_url: any;}){
        return item.jpg_file_url;
      });
    });
    // 2.1で生成したリストを基に返却用のリストを生成する
    list.forEach((jpg_file_url) => {
      jpg_file_url.forEach(function(element: any){
        snapshot_list.push(element);
      });
    });
    // 画像が4枚以下の場合、代わりに表示させるブラックアウト画像を追加する
    if(snapshot_list.length < 4){
      // 画像がない場合に表示させるブラックアウト画像
      const blImg = "/img/blackout.png";
      for(let i = snapshot_list.length; i < 4; i++){
        snapshot_list.push(blImg);
      }
    }
    return snapshot_list;
  }

  // 再生パネル生成
  const player = !data
    ? null
    : data.type === "movie"
      // 動画の場合
      ? (
        <div>
          {play_movie_panel}
        </div>
      )
      : data.map_graph_info.event_type === "-"
        // スナップショットの場合
        ? (
          <div>
            {play_snapshots}
          </div>
        )
        // 危険挙動の場合
        : (
          <div>
            {play_events}
          </div>
        );

  // グラフ（速度：GPS速度、パルス速度）を生成する
  const chart = !data
    ? null
    : "data" in data.map_graph_info
      ? (
        <div id="chart1" style={{float: "inline-end"}}>
          <Line data={create_speed_dataset()} height={300} options={create_speed_option()} />
        </div>
      )
      : null;

  // グラフ（速度：GPS速度、パルス速度）のデータ部分生成
  function create_speed_dataset(){
    // ラベル
    const labelList = data.map_graph_info.data.map(function(item: { movie_time: any; }){
      return item.movie_time;
    });
    // GPS速度のデータリスト
    const dataList = data.map_graph_info.data.map(function(item: { g_speed: any; }){
      return item.g_speed;
    });
    // パルス速度のデータリスト
    const pspeedList = data.map_graph_info.data.map(function(item: { p_speed: any; }){
      return item.p_speed;
    });
    // グラフ
    const data_chart = {
      // ラベル
      labels: labelList,
      // データ
      datasets: [
        // GPS速度
        {
          label: "GPS速度",
          fill: true,
          lineTension: 0.1,
          backgroundColor: "rgba(0,0,0,0)",
          borderColor: "rgba(50,205,50,1)",
          borderCapStyle: "round",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "square",
          pointBorderColor: "rgba(0,100,0,1)",
          pointBackgroundColor: "#eee",
          pointBorderWidth: 2,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 1,
          pointRadius: 1,
          pointHitRadius: 10,
          borderWidth: 1.5,
          // データ
          data: dataList
        },
        // パルス速度
        {
          label: "パルス速度",
          fill: true,
          lineTension: 0.1,
          backgroundColor: "rgba(0,0,0,0)",
          borderColor: "rgba(220,20,60,1)",
          borderCapStyle: "round",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "square",
          pointBorderColor: "rgba(139,0,0,1)",
          pointBackgroundColor: "#eee",
          pointBorderWidth: 2,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 1,
          pointRadius: 1,
          pointHitRadius: 10,
          borderWidth: 1.5,
          // データ
          data: pspeedList
        }
      ]
    };
    return data_chart;
  }

  // 速度グラフのオプションを生成する
  function create_speed_option(){
    /** グラフオプション */
    const graphOption = {
      scales: {
        xAxes: [ // x軸設定
          {
            ticks: {
              autoSkip: true, //横幅が狭くなったときに表示を間引くか否か
              maxRotation: 45, //下のと合わせて表示される角度を決める
              minRoation: 45 //横幅を最小にしたときに縦に表示される
            },
          },
        ],
        yAxes: [ // y軸設定
          {
            scaleLabel: {
              display: true,
              labelString: "速度",
            },
            ticks: { // 軸目盛設定
              // 0から開始するかの指定
              //beginAtZero: true,
              callback: function (value: any) {
                return `${value} km/h`;
              },
              autoSkip: true, //縦幅が狭くなったときに表示を間引くか否か
            },
          },
        ],
      },
      legend: {
        // ラベルの表示位置
        position: "bottom"
      },
      maintainAspectRatio: false,
    };
    return graphOption;
  }

  // グラフ（速度：GPS速度、パルス速度）を生成する
  const gsensor_chart = !data
    ? null
    : "data" in data.map_graph_info
      ? (
        <div id="chart2" style={{float: "inline-end"}}>
          <Line data={create_gsensor_dataset()} height={300} options={create_gsensor_option()} />
        </div>
      )
      : null;

  // グラフ（速度：GPS速度、パルス速度）のデータ部分生成
  function create_gsensor_dataset(){
    // ラベル
    const labelList = data.map_graph_info.data.map(function(item: { movie_time: any; }){
      return item.movie_time;
    });
    // Xのデータリスト
    const xList = data.map_graph_info.data.map(function(item: { gsensor: { x: any; }; }){
      return item.gsensor.x;
    });
    // Yのデータリスト
    const yList = data.map_graph_info.data.map(function(item: { gsensor: { y: any; }; }){
      return item.gsensor.y;
    });
    // Zのデータリスト
    const zList = data.map_graph_info.data.map(function(item: { gsensor: { z: any; }; }){
      return item.gsensor.z;
    });
    // グラフ
    const data_chart = {
      // ラベル
      labels: labelList,
      // データ
      datasets: [
        // GセンサーX
        {
          label: "X",
          fill: true,
          lineTension: 0.1,
          backgroundColor: "rgba(0,0,0,0)",
          borderColor: "rgba(220,20,60,1)",
          borderCapStyle: "round",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "square",
          pointBorderColor: "rgba(139,0,0,1)",
          pointBackgroundColor: "#eee",
          pointBorderWidth: 2,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 1,
          pointRadius: 1,
          pointHitRadius: 10,
          borderWidth: 1.5,
          // データ
          data: xList
        },
        // GセンサーY
        {
          label: "Y",
          fill: true,
          lineTension: 0.1,
          backgroundColor: "rgba(0,0,0,0)",
          borderColor: "rgba(50,205,50,1)",
          borderCapStyle: "round",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "square",
          pointBorderColor: "rgba(0,100,0,1)",
          pointBackgroundColor: "#eee",
          pointBorderWidth: 2,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 1,
          pointRadius: 1,
          pointHitRadius: 10,
          borderWidth: 1.5,
          // データ
          data: yList
        },
        // GセンサーZ
        {
          label: "Z",
          fill: true,
          lineTension: 0.1,
          backgroundColor: "rgba(0,0,0,0)",
          borderColor: "rgba(30,144,255,1)",
          borderCapStyle: "round",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "square",
          pointBorderColor: "rgba(0,0,139,1)",
          pointBackgroundColor: "#eee",
          pointBorderWidth: 2,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 1,
          pointRadius: 1,
          pointHitRadius: 10,
          borderWidth: 1.5,
          // データ
          data: zList
        }
      ]
    };
    return data_chart;
  }

  // 速度グラフのオプションを生成する
  function create_gsensor_option(){
    /** グラフオプション */
    const graphOption = {
      scales: {
        xAxes: [ // x軸設定
          {
            ticks: {
              autoSkip: true, //横幅が狭くなったときに表示を間引くか否か
              maxRotation: 45, //下のと合わせて表示される角度を決める
              minRoation: 45 //横幅を最小にしたときに縦に表示される
            },
          },
        ],
        yAxes: [ // y軸設定
          {
            scaleLabel: {
              display: true,
              labelString: "mG",
            },
            ticks: { // 軸目盛設定
              // 0から開始するかの指定
              //beginAtZero: true,
              callback: function (value: any) {
                return `          ${value}mG`;
              },
              autoSkip: true, //縦幅が狭くなったときに表示を間引くか否か
            },
          },
        ],
      },
      legend: {
        // ラベルの表示位置
        position: "bottom"
      },
      maintainAspectRatio: false,
    };
    return graphOption;
  }

  interface PinProps {
    lat: number,
    lng: number
  }
  // 車両のマーカー
  // 動画の場合
  var lineSymbol_FORWARD_CLOSED_ARROW = {
    path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    scale: 5,
    strokeColor: "#0000cd",
    strokeWeight: 9
  };
  // 危険挙動/静止画の場合
  var lineSymbol_CIRCLE = {
    path: google.maps.SymbolPath.CIRCLE,
    scale: 5,
    strokeColor: "#0000cd",
    strokeWeight: 9
  };

  const apiLoaded = (map: any, maps: any, pins: any, icon: any) => {
    const path = new maps.Polyline({
      path: pins.map((p: PinProps) => ({
        lat: p.lat,
        lng: p.lng
      })),
      geodesic: true,
      strokeColor: "#DC143C",
      strokeOpacity: 1,
      strokeWeight: 6,
      icons: [{icon: icon, offset: "100%"}]
    });

    path.setMap(map);
  };


  // 走行経路の位置情報のリストを生成する
  function make_polyline(data: any){
    var pins: PinProps[] = [];
    var length = 0;
    if("data" in data.map_graph_info){
      // 走行データの長さを取得する
      length = Object.keys(data.map_graph_info.data).length;
    }
    if(length < 2){
      // 位置情報が2つ以上ないと表示されないので2つ生成する
      // スナップショットの場合（走行データが1つ）
      if(data.type !== "movie" && data.map_graph_info.event_type === "-"
        && data.map_graph_info.lat !== null && data.map_graph_info.lat !== null){
        for(let i = 0; i <= 1; i++){
          pins.push(
            {
              lat: data.map_graph_info.lat,
              lng: data.map_graph_info.lon
            }
          );
        }
      }else if(length === 1){
        // 動画/危険挙動の場合（走行データが1つ）
        for(let i = 0; i <= 1; i++){
          pins.push(
            {
              lat: data.map_graph_info.data[length - 1].lat,
              lng: data.map_graph_info.data[length - 1].lon
            }
          );
        }
      }
    }else{
      // 動画と危険挙動（地点が複数の時のみ補正をする）
      pins = roadData.map((p: any) => ({
        lat: p.location.latitude,
        lng: p.location.longitude
      }));

    }
    return pins;
  }

  // Mapのオプションを設定する
  const createMapOptions = (maps: Maps): MapOptions => {
    return {
      mapTypeControlOptions: {
        position: maps.ControlPosition.TOP_RIGHT,
      },
      mapTypeControl: true,
      zoomControl: true,
      scaleControl: true,
      streetViewControl: true,
      fullscreenControl: true,
      clickableIcons: true
    };
  };

  // 地図パネル
  const map = !data
    ? null
    : (data.type === "movie" || "data" in data.map_graph_info) && Object.keys(data.map_graph_info.data).length !== 0
      ? !(roadData.length && !roadRequestParams.length)
        ? <Spinner animation="border" className="mt-4" style={{width: "5rem", height: "5rem"}}/>
        // 補完後データがあり、リクエストパラメータ用のデータがない場合、走行軌跡を表示する
        : (
          <GoogleMapWrapper>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: google_map_key
              }}
              defaultCenter={{
                lat: get_center_lattitude(data, "lat"),
                lng: get_center_lattitude(data, "lon")
              }}
              defaultZoom={15}
              options={createMapOptions}
              onGoogleApiLoaded={({ map, maps }) => apiLoaded(map, maps, make_polyline(data), select_vehicle_icon(data))}
              yesIWantToUseGoogleMapApiInternals={true}
            >
              {
                create_pins(data).map((pin: {
                    lat: number,
                    lng: number,
                    type: string,
                    heading: number
                  }, index: number) => (
                  <Pin key={"pin" + index}
                    lat={pin.lat}
                    lng={pin.lng}
                  >
                    <Logo src={danger_icon} />
                  </Pin>
                ))
              }
            </GoogleMapReact>
          </GoogleMapWrapper>
        )
      // 補完後データがあり、リクエストパラメータ用のデータがない場合、走行軌跡を表示する
      : (
        <GoogleMapWrapper>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: google_map_key
            }}
            defaultCenter={{
              lat: get_center_lattitude(data, "lat"),
              lng: get_center_lattitude(data, "lon")
            }}
            defaultZoom={15}
            options={createMapOptions}
            onGoogleApiLoaded={({ map, maps }) => apiLoaded(map, maps, make_polyline(data), select_vehicle_icon(data))}
            yesIWantToUseGoogleMapApiInternals={true}
          >
            {
              create_pins(data).map((pin: {
                  lat: number,
                  lng: number,
                  type: string,
                  heading: number
                }, index: number) => (
                <Pin key={"pin" + index}
                  lat={pin.lat}
                  lng={pin.lng}
                >
                  <Logo src={danger_icon} />
                </Pin>
              ))
            }
          </GoogleMapReact>
        </GoogleMapWrapper>
      );

  // 車両アイコンの種類を選択する
  function select_vehicle_icon(data: { map_graph_info: { data: {}; }; }){
    let icon = lineSymbol_CIRCLE;
    let length = 0;
    if("data" in data.map_graph_info){
      // 走行データの長さを取得する
      length = Object.keys(data.map_graph_info.data).length;
    }
    // 走行データが複数ある場合のみ、矢印を使う
    if(length >= 2){
      icon = lineSymbol_FORWARD_CLOSED_ARROW;
    }

    return icon;
  }

  // 地図上に表示させるアイコンの一覧を作成する
  function create_pins(data: { map_graph_info: { data: { lat: any; lon: any; heading: any; }[]; event_type: string; lat: any; lon: any; heading: any; }; type: string; movie_details: { lat_to: any; lon_to: any; heading_to: any; }; }){
    const pins: {
      lat: number,
      lng: number,
      type: string,
      heading: number
    }[] = [
    ];

    if(data.type !== "movie"){
      // 危険挙動
      if(data.map_graph_info.event_type !== "-" && data.map_graph_info.lat !== null
        && data.map_graph_info.lon !== null && data.map_graph_info.heading !== null){
        // 危険挙動アイコン
        pins.push(
          {
            lat: data.map_graph_info.lat,
            lng: data.map_graph_info.lon,
            type: data.map_graph_info.event_type,
            heading: data.map_graph_info.heading
          }
        );
      }
    }
    return pins;
  }

  // 地図の表示位置を決定する
  function get_center_lattitude(data: { type: string; map_graph_info: any; }, type: string){
    var ret = 0;
    // 緯度
    if(type === "lat"){
      // 動画（緯度）
      if(data.type === "movie"){
        ret = movie_lattitude(data, "lat");
      } else{
        // 静止画（経度）
        ret = snapshot_lattitude(data, "lat");
      }
    }else{
      // 経度
      // 動画（緯度）
      if(data.type === "movie"){
        ret = movie_lattitude(data, "lon");
      } else{
        // 静止画（経度）
        ret = snapshot_lattitude(data, "lon");
      }
    }
    return ret;
  }
  // 動画の緯度経度情報を取得する
  function movie_lattitude(data: { map_graph_info: { data: string | any[]; }; }, type: string){
    var ret = 0;
    if(data.map_graph_info.data != null && Object.keys(data.map_graph_info.data).length !== 0){
      // 動画の場合走行データの最新の緯度経度情報を取得する
      const length = Object.keys(data.map_graph_info.data).length;
      // 緯度の場合
      if(type === "lat"){
        ret = data.map_graph_info.data[length - 1].lat;
      }else{
        // 経度の場合
        ret = data.map_graph_info.data[length - 1].lon;
      }
    }
    return ret;
  }

  // 静止画の緯度経度情報を取得する
  function snapshot_lattitude(data: { map_graph_info: { lat: number; lon: number; }; }, type: string){
    var ret = 0;
    // 緯度の場合
    if(type === "lat"){
      ret = data.map_graph_info.lat;
    }else{
      // 経度の場合
      ret = data.map_graph_info.lon;
    }
    return ret;
  }

  // エラーメッセージの生成
  const alert = errorMessage != null
    ? <Alert variant="danger" className="mt-2" dismissible onClose={() => setErrorMessage(null)}><strong>エラー：</strong>{errorMessage}</Alert>
    : null;

  return (
    <React.Fragment key='movie_playback'>
      <div className="mt-5 ml-5 mr-5">
        {alert}
        <h2 className="">{MOVIE_PLAYBACK.title}</h2>
        {/* 行1 */}
        <div className="row row-eq-height" >
          {/* 動画詳細情報 */}
          <div className="col-md-4">
            <div className="container overflow-auto">
              <Tabs onSelect={resizeListMaxHeight}>
                <Tab eventKey="movie_info" title="詳細情報">
                  <div>
                    {movie_info}
                  </div>
                </Tab>
                <Tab eventKey="movie_playlist" title="再生リスト">
                  <div>
                    {movie_playlist}
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
          <div id="panel_head" className="col-md-8 border-left border-bottom mb-1">
            <div id="panel_movie_play">
              {player}
            </div>
          </div>
        </div>
        {/* 行2 */}
        <br/>
        <div className="row">
          {/* 7列幅分のボックス */}
          <div className="col-md-6 px-0" style={{float: "inline-end"}}>
            {map}
          </div>
          {/* 5列幅分のボックス */}
          <div className="col-md-6" style={{float: "inline-end"}}>
            {chart}
            {gsensor_chart}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};