import { AxiosRequestConfig } from "axios";
import { ConfirmDialog } from "components/parts/ConfirmDialog";
import { MyRadioInline, MyRow, MyRowMail, MySelect } from "components/parts/InputRow";
import { ENDPOINT } from "constants/endpoint";
import { m } from "constants/message";
import { useUserInfoContext } from "contexts/userInfoContext";
import jwt from "jsonwebtoken";
import { MENU, SESSION_TIMEOUT, USER_EDIT, USER_LIST } from "pages/pageInfo";
import queryString from "query-string";
import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { request } from "util/request";

interface State {
  "loginId": string,
  "roleId": string,
  "groupName": string,
  "userName": string,
  "userNameKana": string,
  "mail": string,
  "receiveEventMail": string,
  "receiveEventMailMessage": string,
  "adminUserFlg": string,
}

export const UserEdit: React.FC<any> = (props) =>{
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const history = useHistory();
  const [validated, setValidated] = useState(false);
  const [state, setState] = useState<State>({
    "loginId": "",
    "roleId": "",
    "groupName": "",
    "userName": "",
    "userNameKana": "",
    "mail": "",
    "receiveEventMail": "",
    "receiveEventMailMessage": "",
    "adminUserFlg": "",
  });
  const [showDeleteDlg, setSowDeleteDlg] = useState<boolean>(false);
  const userId = useRef(0); // 0:初期状態
  const { signOut, getUser } = useUserInfoContext();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(()=>{
    document.title = USER_EDIT.title;

    const query = queryString.parse(history.location.search)["user_id"] as string;
    if (query === undefined) {
      history.replace(MENU.path);
    }
    userId.current = parseInt(query);
  },[history]);

  useEffect(()=>{
    // ログインIDが空で、リクエスト中でもない場合
    // リクエストを投げてデータを取得する
    if(state.loginId === "" && !isRequesting){
      (async ()=>{
        setIsRequesting(true); // APIの二重コール防止用

        // GETリクエスト
        const config: AxiosRequestConfig = { method: "post", data: {user_id: userId.current}};

        await request(ENDPOINT.USER_DETAIL_INFO_GET, config, setErrorMessage)
          .then((res: any) =>{

            // 実データ部分のバインド
            const _state = {
              loginId: res.data.login_id,
              roleId: res.data.role_id,
              groupName: res.data.group_name !== null ? res.data.group_name : "",
              userName: res.data.user_name,
              userNameKana: res.data.user_name_kana !== null ? res.data.user_name_kana : "",
              mail: res.data.mail,
              receiveEventMail: res.data.receive_event_mail,
              receiveEventMailMessage: "",
              adminUserFlg: res.data.admin_user_flg,
            };

            setState({..._state});
            setIsRequesting(false);
          })
          .catch((err: any)=>{
            // トークン不正時、サインアウト処理してreturn ※サインアウト処理の中でタイムアウト画面へ遷移させる
            if(err instanceof jwt.JsonWebTokenError){ signOut(SESSION_TIMEOUT.path); return; }

            // 無理やりデータがない状態にする
            const _state = {
              loginId: "nodata",
              roleId: "nodata",
              groupName: "nodata",
              userName: "nodata",
              userNameKana: "nodata",
              mail: "nodata",
              receiveEventMail: "nodata",
              receiveEventMailMessage: "nodata",
              adminUserFlg: "nodata",
            };

            setState({..._state});
            setIsRequesting(false);
            return;
          }
          );
      })();
    }
  });

  let isAdminUserId = false;
  let isManager = false;
  let isDeleteable = true;
  let roleLabel = "*権限";

  // アドミンユーザ（企業）の編集時
  if(String(state.adminUserFlg) === "1"){
    isDeleteable = false;
    isAdminUserId = true;
    roleLabel = "権限";
  }

  // ログインしている自分を消せないように設定
  if(String(state.loginId) === getUser().username){
    isDeleteable = false;
  }

  // submitボタンのハンドラ
  const handleSubmit = async (event: React.MouseEvent<HTMLInputElement>) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    // 入力チェック
    if (form.checkValidity() === false || (isManager === true && state["receiveEventMail"] === "")) {
      const rem = state["receiveEventMail"] === "" ? m("V0013") : "";
      setState({...state, receiveEventMailMessage: rem});
      await setValidated(true);
      return;
    }

    // 危険挙動メール受信設定がドライバーの場合と空の場合は0に設定
    if(isManager === false || state["receiveEventMail"] === "") {
      state["receiveEventMail"] = "0";
    }

    setIsRequesting(true); // APIの二重コール防止用
    setErrorMessage(null);

    // POSTリクエスト
    const config: AxiosRequestConfig = {
      method: "post",
      data: {
        user_id: userId.current,
        login_id: state["loginId"],
        role_id: state["roleId"],
        group_name: state["groupName"] ? state["groupName"] : null,
        user_name: state["userName"],
        user_name_kana: state["userNameKana"] ? state["userNameKana"] : null,
        mail: state["mail"],
        receive_event_mail: parseInt(state["receiveEventMail"]),
      },
    };

    await request(ENDPOINT.USER_UPDATE, config, setErrorMessage)
      .then((res: any) =>{ history.push(USER_LIST.path, sessionStorage.setItem("successMessage", m("M0004"))); })
      .catch((err: any)=>{
      // トークン不正時、サインアウト処理してreturn ※サインアウト処理の中でタイムアウト画面へ遷移させる
        if(err instanceof jwt.JsonWebTokenError){ signOut(SESSION_TIMEOUT.path); return; }
        setIsRequesting(false);
        return;
      }
      );
  };

  const handleDelete = async (event: React.MouseEvent<HTMLInputElement>) => {
    setIsRequesting(true); // APIの二重コール防止用
    setErrorMessage(null);

    // POSTリクエスト
    const config: AxiosRequestConfig = {
      method: "post",
      data: {
        user_id: userId.current,
        login_id: state["loginId"],
      },
    };

    await request(ENDPOINT.USER_DELETE, config, setErrorMessage)
      .then((res: any) =>{ history.push(USER_LIST.path, sessionStorage.setItem("successMessage", m("M0005"))); })
      .catch((err: any)=>{
        // トークン不正時、サインアウト処理してreturn ※サインアウト処理の中でタイムアウト画面へ遷移させる
        if(err instanceof jwt.JsonWebTokenError){ signOut(SESSION_TIMEOUT.path); return; }
        setIsRequesting(false);
        setSowDeleteDlg(false);
        return;
      }
      );
  };

  // 入力欄に入力した時のハンドラ
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, target: string) => {
    // 空文字列はnullに変換する
    setState({...state, [target]: event.target.value});
  };

  // ログインIDが空 ＆ リクエスト中でもない ＆ エラーメッセージが入っている場合、
  //  初期情報の取得でエラー終了しているのでエラーメッセージだけ出す
  if(state.loginId === "nodata" && !isRequesting && errorMessage){
    return (
      <div className="mt-5 ml-5 mr-5">
        <div className="d-flex">
          <h2 className="">{USER_EDIT.title}</h2>
          <Button size="lg" variant="outline-primary" className="ml-auto" href={USER_LIST.path}>戻る</Button>
        </div>
        <Alert variant="danger" className="mt-2" dismissible onClose={e => setErrorMessage(null)}><strong>エラー：</strong>{errorMessage}</Alert>
      </div>
    );
  }

  // ドロップダウン表示用のレスポンスを取得できていない場合、Loadingを表示
  if(state.loginId === "" || !state){
    return (
      <div className="mt-5 ml-5 mr-5">
        <div className="d-flex">
          <h2 className="">{USER_EDIT.title}</h2>
          <Button size="lg" variant="outline-primary" className="ml-auto" href={USER_LIST.path}>戻る</Button>
        </div>
        <Spinner animation="border" className="ml-4 mt-4" style={{width: "5rem", height: "5rem"}}/>
      </div>
    );
  }

  // ログインID
  const loginId = {
    label: "ログインID",
    attrName: "loginId",
    pattern: "",
    required: false,
    disabled: true,
    value: state.loginId,
    maxLength: 32,
    md: "5",
    message: "",
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "loginId");},
  };

  // 権限リストの生成
  let roles = [{id: "manager", name: "企業管理者"}, {id: "driver", name: "ドライバー"},];
  const rolesDom = roles.map( item => {
    return <option key={item.id} value={item.id}>{item.name}</option>;
  });

  // 権限
  const roleId = {
    label: roleLabel,
    attrName: "roleId",
    required: true,
    disabled: isAdminUserId,
    value: state.roleId,
    listDom: rolesDom,
    message: m("V0007"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "roleId");},
  };

  // 権限でマネージャーが選択された場合
  if (roleId.value === "manager"){
    isManager = true;
  }

  // 組織名
  const groupName = {
    label: "組織名",
    attrName: "groupName",
    pattern: "^.{1,32}$",
    required: false,
    value: state.groupName,
    maxLength: 32,
    md: "5",
    message: m("V0008") + m("V0009", "32"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "groupName");},
  };

  // 利用者名
  const userName = {
    label: "*利用者名",
    attrName: "userName",
    pattern: "^.{1,32}$",
    required: true,
    value: state.userName,
    maxLength: 32,
    md: "5",
    message: m("V0007") + m("V0009", "32"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "userName");},
  };

  // 利用者名（カナ）
  const userNameKana = {
    label: "利用者名（カナ）",
    attrName: "userNameKana",
    pattern: "^.{1,64}$",
    required: false,
    value: state.userNameKana,
    maxLength: 64,
    md: "8",
    message: m("V0008") + m("V0009", "64"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "userNameKana");},
  };

  // メールアドレス
  const mail = {
    label: "*メールアドレス",
    attrName: "mail",
    required: true,
    value: state.mail,
    maxLength: 256,
    md: "12",
    message: m("V0007") + m("V0011", "256"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "mail");},
  };


  // ラジオボタンの値を設定
  const eventMailValues = ["1", "0"];
  const eventMailvaluesBool = eventMailValues.map(value=>{
    return String(state.receiveEventMail) === value;
  });

  // 危険挙動メール受信設定
  const receiveEventMail = {
    label: "*危険挙動メール受信設定",
    className: "mx-0 pb-3 mt-2",
    itemLabels: ["する", "しない"],
    attrName: "receiveEventMail",
    checked: eventMailvaluesBool,
    values: ["1", "0"],
    disabled: Array(2).fill(isRequesting),
    messageClassName: "mx-0 pb-3 small text-danger",
    message: state.receiveEventMailMessage,
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "receiveEventMail");},
  };

  // エラーメッセージの生成
  const alert = errorMessage != null
    ? <Alert variant="danger" className="mt-2" dismissible onClose={e => setErrorMessage(null)}><strong>エラー：</strong>{errorMessage}</Alert>
    : null;

  // 保存ボタンの表示
  const saveButton = !isRequesting
    ? "保存"
    : <Spinner style={{width: "2rem", height: "2rem"}} as="span" animation="border" size="sm" role="status" aria-hidden="true" />;

  // 削除ダイアログのはいボタンの表示
  const yesButton = !isRequesting
    ? "はい"
    : <Spinner style={{width: "1.5rem", height: "1.5rem"}} as="span" animation="border" size="sm" role="status" aria-hidden="true" />;

  // 削除ダイアログのonHideの制御（リクエスト中は背景部分を押しても反応させないようにする）
  const handleOnHide = ()=>{
    if(!isRequesting){
      setSowDeleteDlg(false);
    }
  };

  return (
    <>
      <div className="mt-5 ml-5 mr-5">
        <div className="d-flex">
          <h2 className="">{USER_EDIT.title}</h2>
          <Button size="lg" variant="outline-primary" className="ml-auto" href={USER_LIST.path}>戻る</Button>
        </div>
        {alert}
        <Form noValidate validated={validated} onSubmit={handleSubmit}>

          <div className="text-danger my-3">*は必須入力</div>

          {/* ログインID */}
          <MyRow {...loginId}></MyRow>

          {/* 権限 */}
          <MySelect {...roleId}></MySelect>

          {/* 組織名 */}
          <MyRow {...groupName}></MyRow>

          {/* 利用者名 */}
          <MyRow {...userName}></MyRow>

          {/* 利用者名（カナ） */}
          <MyRow {...userNameKana}></MyRow>

          {/* メールアドレス */}
          <MyRowMail {...mail}></MyRowMail>

          {/* 危険挙動メール受信設定 */}
          <div>
            {isManager && <MyRadioInline {...receiveEventMail}></MyRadioInline>}
          </div>

          <div className="d-flex my-4">
            <Button type="submit" className="d-block mr-4 px-5" disabled={isRequesting} size="lg">
              {saveButton}
            </Button>
            <div>
              {isDeleteable && <Button className="d-block px-5" onClick={e=>{setSowDeleteDlg(true);}} disabled={isRequesting} size="lg">
              削除
              </Button>}
            </div>
          </div>
        </Form>
      </div>

      <ConfirmDialog
        show={showDeleteDlg}
        isRequesting={isRequesting}
        handleClose={handleOnHide}
        handleYes={handleDelete}
        yesButtonDom={yesButton}
        message={m("CONFIRM_DELETE")}
      >
      </ConfirmDialog>
    </>
  );
};