import { AxiosRequestConfig } from "axios";
import { MyRow, MySelect, MyTextArea } from "components/parts/InputRow";
import { ENDPOINT } from "constants/endpoint";
import { m } from "constants/message";
import { useUserInfoContext } from "contexts/userInfoContext";
import jwt from "jsonwebtoken";
import { RECORDER_LIST, RECORDER_REGISTER, SESSION_TIMEOUT } from "pages/pageInfo";
import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { request } from "util/request";

interface State {
  "companyId": string,
  "manufacturer": string,
  "deviceModel": string,
  "driveRecorderId": string,
  "imei": string,
  "firmwareVersion": string,
  "remarksDevice": string,
  "imsi": string,
  "apn": string,
  "apnUser": string,
  "apnPassword": string,
  "tel": string,
  "remarksSim": string,
}

export const RecorderRegister: React.FC<any> = (props) =>{
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const history = useHistory();
  const [data, setData] = useState<any>(null); // 初期状態：null、エラー時：undefined とする
  const [validated, setValidated] = useState(false);
  const [state, setState] = useState<State>({
    "companyId": "",
    "manufacturer": "",
    "deviceModel": "",
    "driveRecorderId": "",
    "imei": "",
    "firmwareVersion": "",
    "remarksDevice": "",
    "imsi": "",
    "apn": "",
    "apnUser": "",
    "apnPassword": "",
    "tel": "",
    "remarksSim": "",
  });
  const { signOut, } = useUserInfoContext();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(()=>{
    document.title = RECORDER_REGISTER.title;

    // 表示用データが空で、リクエスト中でもない場合
    // リクエストを投げてデータを取得する
    if(data === null && !isRequesting){
      (async ()=>{
        setIsRequesting(true); // APIの二重コール防止用

        // GETリクエスト
        const config: AxiosRequestConfig = { method: "post", data: {}};

        await request(ENDPOINT.RECORDER_REGISTER_INFO_GET, config, setErrorMessage)
          .then((res: any) =>{ setData(res.data); setIsRequesting(false); })
          .catch((err: any)=>{
            // トークン不正時、サインアウト処理してreturn ※サインアウト処理の中でタイムアウト画面へ遷移させる
            if(err instanceof jwt.JsonWebTokenError){ signOut(SESSION_TIMEOUT.path); return; }

            setData(undefined); // nullにしたいけど、nullにすると無限にリクエストすることになるのでundefined使う
            setIsRequesting(false); // 必ずsetDataの方が先（dataがnullのまま先にisRequestingをfalseにすると2回目のリクエストを投げるので。。）
            return;
          }
          );
      })();
    }
  });

  // submitボタンのハンドラ
  const handleSubmit = async (event: React.MouseEvent<HTMLInputElement>) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() === false) {
      await setValidated(true);
      return;
    }

    setIsRequesting(true); // APIの二重コール防止用
    setErrorMessage(null);

    // POSTリクエスト
    const config: AxiosRequestConfig = {
      method: "post",
      data: {
        company_id: parseInt(state["companyId"]),
        manufacturer: state["manufacturer"],
        device_model: state["deviceModel"],
        drive_recorder_id: state["driveRecorderId"],
        imei: state["imei"] ? state["imei"] : null,
        firmware_version: state["firmwareVersion"] ? state["firmwareVersion"] : null,
        remarks_device: state["remarksDevice"] ? state["remarksDevice"] : null,
        imsi: state["imsi"],
        apn: state["apn"] ? state["apn"] : null,
        apn_user: state["apnUser"] ? state["apnUser"] : null,
        apn_password: state["apnPassword"] ? state["apnPassword"] : null,
        tel: state["tel"] ? state["tel"] : null,
        remarks_sim: state["remarksSim"] ? state["remarksSim"] : null,

      },
    };

    await request(ENDPOINT.RECORDER_REGISTER, config, setErrorMessage)
      .then((res: any) =>{ history.push(RECORDER_LIST.path, sessionStorage.setItem("successMessage", m("M0003"))); })
      .catch((err: any)=>{
        // トークン不正時、サインアウト処理してreturn ※サインアウト処理の中でタイムアウト画面へ遷移させる
        if(err instanceof jwt.JsonWebTokenError){ signOut(SESSION_TIMEOUT.path); return; }
        setIsRequesting(false);
        return;
      }
      );
  };

  // 入力欄に入力した時のハンドラ
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, target: string) => {
    // 空文字列はnullに変換する
    setState({...state, [target]: event.target.value});
  };

  // ドロップダウン表示用のレスポンスを取得できていない ＆ リクエスト中でもない ＆ エラーメッセージが入っている場合、
  //  初期情報の取得でエラー終了しているのでエラーメッセージだけ出す
  if(!data && !isRequesting && errorMessage){
    return (
      <div className="mt-5 ml-5 mr-5">
        <div className="d-flex">
          <h2 className="">{RECORDER_REGISTER.title}</h2>
          <Button size="lg" variant="outline-primary" className="ml-auto" href={RECORDER_LIST.path}>戻る</Button>
        </div>
        <Alert variant="danger" className="mt-2" dismissible onClose={e => setErrorMessage(null)}><strong>エラー：</strong>{errorMessage}</Alert>
      </div>
    );
  }

  // ドロップダウン表示用のレスポンスを取得できていない場合、Loadingを表示
  if(!data){
    return (
      <div className="mt-5 ml-5 mr-5">
        <div className="d-flex">
          <h2 className="">{RECORDER_REGISTER.title}</h2>
          <Button size="lg" variant="outline-primary" className="ml-auto" href={RECORDER_LIST.path}>戻る</Button>
        </div>
        <Spinner animation="border" className="ml-4 mt-4" style={{width: "5rem", height: "5rem"}}/>
      </div>
    );
  }

  // 顧客リストの生成
  const companyList = data.company_list.map((item: any) => {
    return <option key={item.company_id} value={item.company_id}>{item.company_name}</option>;
  });

  // 顧客名
  const companyName = {
    label: "*顧客名",
    attrName: "companyId",
    required: true,
    value: state.companyId,
    listDom: companyList,
    message: m("V0007"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "companyId");},
  };

  // メーカーリストの生成
  const manufacturerList = data.manufacturer_list.map((item: any) => {
    return <option key={item.manufacturer} value={item.manufacturer}>{item.manufacturer_name}</option>;
  });


  // メーカー名
  const manufacturerName = {
    label: "*メーカー名",
    attrName: "manufacturer",
    required: true,
    value: state.manufacturer,
    listDom: manufacturerList,
    message: m("V0007"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "manufacturer");},
  };

  // モデルリストの生成
  const modelList = data.device_model_list.map((item: any) => {
    return <option key={item.device_model} value={item.device_model}>{item.device_model_name}</option>;
  });

  // モデル名
  const modelName = {
    label: "*モデル名",
    attrName: "deviceModel",
    required: true,
    value: state.deviceModel,
    listDom: modelList,
    message: m("V0007"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "deviceModel");},
  };

  // ドラレコID
  const driveRecorderId = {
    label: "*ドラレコID",
    attrName: "driveRecorderId",
    pattern: "^.{1,32}$",
    required: true,
    value: state.driveRecorderId,
    maxLength: 32,
    md: "5",
    message: m("V0007") + m("V0009", "32"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "driveRecorderId");},
  };

  // シリアルNo（IMEI）
  const imei = {
    label: "シリアルNo（IMEI）",
    attrName: "imei",
    pattern: "^.{1,15}$",
    required: false,
    value: state.imei,
    maxLength: 15,
    md: "5",
    message: m("V0008") + m("V0009", "15"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "imei");},
  };

  // ファームウェアバージョン情報
  const firmwareVersion = {
    label: "ファームウェアバージョン情報",
    attrName: "firmwareVersion",
    pattern: "^.{1,128}$",
    required: false,
    value: state.firmwareVersion,
    maxLength: 128,
    md: "12",
    message: m("V0008") + m("V0009", "128"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "firmwareVersion");},
  };

  // 備考欄(端末)
  const remarksDevice = {
    label: "備考欄（端末）",
    attrName: "remarksDevice",
    required: false,
    value: state.remarksDevice,
    maxLength: 512,
    md: "12",
    message: m("V0008") + m("V0009", "512"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "remarksDevice");},
  };

  // SIM情報（IMSI）
  const imsi = {
    label: "*SIM情報（IMSI）",
    attrName: "imsi",
    pattern: "^.{1,15}$",
    required: true,
    value: state.imsi,
    maxLength: 15,
    md: "5",
    message: m("V0007") + m("V0009", "15"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "imsi");},
  };

  // APN
  const apn = {
    label: "APN",
    attrName: "apn",
    pattern: "^.{1,128}$",
    required: false,
    value: state.apn,
    maxLength: 128,
    md: "12",
    message: m("V0008") + m("V0009", "128"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "apn");},
  };

  // APNID
  const apnUser = {
    label: "APN　ID",
    attrName: "apnUser",
    pattern: "^.{1,128}$",
    required: false,
    value: state.apnUser,
    maxLength: 128,
    md: "12",
    message: m("V0008") + m("V0009", "128"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "apnUser");},
  };

  // APNパスワード
  const apnPassword = {
    label: "APN　パスワード",
    attrName: "apnPassword",
    pattern: "^.{1,128}$",
    required: false,
    value: state.apnPassword,
    maxLength: 128,
    md: "12",
    message: m("V0008") + m("V0009", "128"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "apnPassword");},
  };

  // 電話番号
  const tel = {
    label: "電話番号",
    attrName: "tel",
    pattern: "^[0-9-]{1,20}$",
    required: false,
    value: state.tel,
    maxLength: 20,
    md: "5",
    message: m("V0008") + m("V0016", "20"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "tel");},
  };


  // 備考欄（SIM）
  const remarksSim = {
    label: "備考欄（SIM）",
    attrName: "remarksSim",
    required: false,
    value: state.remarksSim,
    maxLength: 512,
    md: "12",
    message: m("V0008") + m("V0009", "512"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "remarksSim");},
  };

  // エラーメッセージの生成
  const alert = errorMessage != null
    ? <Alert variant="danger" className="mt-2" dismissible onClose={e => setErrorMessage(null)}><strong>エラー：</strong>{errorMessage}</Alert>
    : null;

  // 登録ボタンの表示
  const registerButton = !isRequesting
    ? "登録"
    : <Spinner style={{width: "2rem", height: "2rem"}} as="span" animation="border" size="sm" role="status" aria-hidden="true" />;


  return (
    <>
      <div className="mt-5 ml-5 mr-5">
        <div className="d-flex">
          <h2 className="">{RECORDER_REGISTER.title}</h2>
          <Button size="lg" variant="outline-primary" className="ml-auto" href={RECORDER_LIST.path}>戻る</Button>
        </div>
        {alert}
        <Form noValidate validated={validated} onSubmit={handleSubmit}>

          <div className="text-danger my-3">*は必須入力</div>

          <h5 className="mt-4 mb-4">端末情報</h5>

          {/* 顧客名 */}
          <MySelect {...companyName}></MySelect>

          {/* メーカー名 */}
          <MySelect {...manufacturerName}></MySelect>

          {/* モデル名 */}
          <MySelect {...modelName}></MySelect>

          {/* ドラレコID */}
          <MyRow {...driveRecorderId}></MyRow>

          {/* シリアルNo（IMEI） */}
          <MyRow {...imei}></MyRow>

          {/* ファームウェアバージョン情報 */}
          <MyRow {...firmwareVersion}></MyRow>

          {/* 備考欄（端末） */}
          <MyTextArea {...remarksDevice}></MyTextArea>

          <h5 className="mt-4 mb-4">SIM情報</h5>

          {/* SIM情報（IMSI） */}
          <MyRow {...imsi}></MyRow>

          {/* APN */}
          <MyRow {...apn}></MyRow>

          {/* APNID */}
          <MyRow {...apnUser}></MyRow>

          {/* APNパスワード */}
          <MyRow {...apnPassword}></MyRow>

          {/* 電話番号 */}
          <MyRow {...tel}></MyRow>

          {/* 備考欄（SIM） */}
          <MyTextArea {...remarksSim}></MyTextArea>

          <Button type="submit" className="d-block my-4 mr-auto px-5" disabled={isRequesting} size="lg">
            {registerButton}
          </Button>
        </Form>
      </div>
    </>
  );
};