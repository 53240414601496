import React from "react";
import { Form } from "react-bootstrap";

export interface SelectBoxGroupProps {
  id: string;
  label: string;
  value: string;
  options: Readonly<string[]>;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void;
  testid?: string;
  error?: string | null;
  disabled?: boolean
}

const SelectBoxGroup = (props: SelectBoxGroupProps) => {
  return (
    <Form.Group controlId={props.id}>
      <Form.Label>{props.label}</Form.Label>
      <Form.Control
        as="select"
        value={props.value}
        data-testid={props.testid}
        onChange={props.onChange}
        onBlur={props.onBlur}
        isInvalid={props.error !== null}
        disabled={props.disabled}
      >
        {props.options.map((option) => (
          <option key={option}>{option}</option>
        ))}
      </Form.Control>
      {props.error && (
        <Form.Control.Feedback type="invalid">
          {props.error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default SelectBoxGroup;
