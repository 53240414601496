import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import SelectBoxGroup from "../../components/layout/SelectBoxGroup";
import TextInputGroup from "../../components/layout/TextInputGroup";
import { validate } from "../../components/validate";
import { CompanyInterface } from "../../interfaces/companyInterface";
import { ErrorMessage } from "../../interfaces/errorInterface";
import {
  ArrayOfRolesToShowForAdmin
} from "../../interfaces/userInfoInterface";
import { REGISTER_USER_FOR_ADMIN } from "../pageInfo";

export interface RegisterUserForAdminError {
  email: ErrorMessage;
  role: ErrorMessage;
  companyId: ErrorMessage;
}

export const generateRoleToPassApi = (role: string) => {
  switch (role) {
    case "管理者":
      return "admin";
    case "マネージャー":
      return "manager";
    case "一般ユーザー":
      return "user";
    default:
      return "user";
  }
};

const RegisterUserForAdmin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("一般ユーザー");
  const [companyId, setCompanyId] = useState("");
  const [companyList, setCompanyList] = useState<string[]>(["企業ID"]);
  const [errors, setErrors] = useState<RegisterUserForAdminError>({
    email: null,
    role: null,
    companyId: null,
  });

  useEffect(() => {
    document.title = REGISTER_USER_FOR_ADMIN.title;
    const init = {
      headers: {},
      response: true,
    };
    API.get("sample", "/company/getall", init)
      .then((res) => {
        setCompanyList(
          Array.of("企業ID").concat(
            res.data.map((c: CompanyInterface) => c.companyId)
          )
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault();
    const init = {
      headers: {},
      response: true,
      body: {
        email: email,
        role: generateRoleToPassApi(role),
        companyId: companyId,
        temporaryPassword: "12345678",
      },
    };
    API.post("sample", "/user/register", init)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateEmail = () => {
    setErrors({
      email: null,
      role: errors.role,
      companyId: errors.companyId,
    });
  };

  // validator だけ外に出して(ErrorMessage返却する)使い回すのでもよい。
  const validateCompanyId = (): void => {
    if (validate(companyId, () => companyId === "企業ID")) {
      setErrors({
        email: errors.email,
        role: errors.role,
        companyId: "有効な企業IDを入力してください",
      });
    } else {
      setErrors({
        email: errors.email,
        role: errors.role,
        companyId: null,
      });
    }
  };

  if (isLoading) {
    return <React.Fragment />;
  } else {
    return (
      <Form>
        <TextInputGroup
          id={"email"}
          label={"Email"}
          type={"email"}
          placeholder={"example@email.com"}
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(e.target.value)
          }
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) => validateEmail()}
          error={errors.email}
        ></TextInputGroup>
        <SelectBoxGroup
          id={"role"}
          label={"User Role"}
          value={role}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            setRole(e.target.value)
          }
          options={ArrayOfRolesToShowForAdmin}
          error={errors.role}
        />
        <SelectBoxGroup
          id={"companyId"}
          label={"企業名"}
          value={companyId}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            setCompanyId(e.target.value);
          }}
          onBlur={(e: React.ChangeEvent<HTMLSelectElement>) =>
            validateCompanyId()
          }
          options={companyList}
          error={errors.companyId}
        />
        <Button
          variant="primary"
          type="submit"
          data-testid="button-submit"
          onClick={(e: React.MouseEvent<HTMLInputElement>) => handleClick(e)}
        >
          登録
        </Button>
      </Form>
    );
  }
};

export default RegisterUserForAdmin;
