import { AxiosRequestConfig } from "axios";
import { MyRow, MySelect } from "components/parts/InputRow";
import { ENDPOINT } from "constants/endpoint";
import { m } from "constants/message";
import { useUserInfoContext } from "contexts/userInfoContext";
import jwt from "jsonwebtoken";
import { SESSION_TIMEOUT, VEHICLE_LIST, VEHICLE_REGISTER } from "pages/pageInfo";
import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { request } from "util/request";

interface State {
  "vehicleType": string,
  "vehicleName": string,
  "vehicleNumber": string,
  "parkingAddress": string,
  "driverId": string,
  "deviceId": string,
}

export const VehicleRegister: React.FC<any> = (props) =>{
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const history = useHistory();
  const [data, setData] = useState<any>(null); // 初期状態：null、エラー時：undefined とする
  const [validated, setValidated] = useState(false);
  const [state, setState] = useState<State>({
    "vehicleType": "",
    "vehicleName": "",
    "vehicleNumber": "",
    "parkingAddress": "",
    "driverId": "",
    "deviceId": "",
  });
  const { signOut, } = useUserInfoContext();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(()=>{
    document.title = VEHICLE_REGISTER.title;

    // 表示用データが空で、リクエスト中でもない場合
    // リクエストを投げてデータを取得する
    if(data === null && !isRequesting){
      (async ()=>{
        setIsRequesting(true); // APIの二重コール防止用

        // GETリクエスト
        const config: AxiosRequestConfig = { method: "post", data: {}};

        await request(ENDPOINT.VEHICLE_REGISTER_INFO_GET, config, setErrorMessage)
          .then((res: any) =>{ setData(res.data); setIsRequesting(false); })
          .catch((err: any)=>{
            // トークン不正時、サインアウト処理してreturn ※サインアウト処理の中でタイムアウト画面へ遷移させる
            if(err instanceof jwt.JsonWebTokenError){ signOut(SESSION_TIMEOUT.path); return; }

            setData(undefined); // nullにしたいけど、nullにすると無限にリクエストすることになるのでundefined使う
            setIsRequesting(false); // 必ずsetDataの方が先（dataがnullのまま先にisRequestingをfalseにすると2回目のリクエストを投げるので。。）
            return;
          }
          );
      })();
    }
  });

  // submitボタンのハンドラ
  const handleSubmit = async (event: React.MouseEvent<HTMLInputElement>) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() === false) {
      await setValidated(true);
      return;
    }

    setIsRequesting(true); // APIの二重コール防止用
    setErrorMessage(null);

    // POSTリクエスト
    const config: AxiosRequestConfig = {
      method: "post",
      data: {
        vehicle_name: state["vehicleName"],
        vehicle_number: state["vehicleNumber"] ? state["vehicleNumber"] : null,
        vehicle_type: state["vehicleType"] === "" ? null : state["vehicleType"],
        parking_address: state["parkingAddress"] ? state["parkingAddress"] : null,
        driver_id: parseInt(state["driverId"]),
        device_id: parseInt(state["deviceId"]),
      },
    };

    await request(ENDPOINT.VEHICLE_REGISTER, config, setErrorMessage)
      .then((res: any) =>{ history.push(VEHICLE_LIST.path, sessionStorage.setItem("successMessage", m("M0003"))); })
      .catch((err: any)=>{
        // トークン不正時、サインアウト処理してreturn ※サインアウト処理の中でタイムアウト画面へ遷移させる
        if(err instanceof jwt.JsonWebTokenError){ signOut(SESSION_TIMEOUT.path); return; }
        setIsRequesting(false);
        return;
      }
      );
  };

  // 入力欄に入力した時のハンドラ
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, target: string) => {
    // 空文字列はnullに変換する
    setState({...state, [target]: event.target.value});
  };

  // ドロップダウン表示用のレスポンスを取得できていない ＆ リクエスト中でもない ＆ エラーメッセージが入っている場合、
  //  初期情報の取得でエラー終了しているのでエラーメッセージだけ出す
  if(!data && !isRequesting && errorMessage){
    return (
      <div className="mt-5 ml-5 mr-5">
        <div className="d-flex">
          <h2 className="">{VEHICLE_REGISTER.title}</h2>
          <Button size="lg" variant="outline-primary" className="ml-auto" href={VEHICLE_LIST.path}>戻る</Button>
        </div>
        <Alert variant="danger" className="mt-2" dismissible onClose={e => setErrorMessage(null)}><strong>エラー：</strong>{errorMessage}</Alert>
      </div>
    );
  }

  // ドロップダウン表示用のレスポンスを取得できていない場合、Loadingを表示
  if(!data){
    return (
      <div className="mt-5 ml-5 mr-5">
        <div className="d-flex">
          <h2 className="">{VEHICLE_REGISTER.title}</h2>
          <Button size="lg" variant="outline-primary" className="ml-auto" href={VEHICLE_LIST.path}>戻る</Button>
        </div>
        <Spinner animation="border" className="ml-4 mt-4" style={{width: "5rem", height: "5rem"}}/>
      </div>
    );
  }

  // 車両種別リストの生成
  const types = data.vehicle_type_list.map((item: any) => {
    return <option key={item.vehicle_type} value={item.vehicle_type}>{item.vehicle_type_name}</option>;
  });

  const vehicleType = {
    label: "車両種別",
    attrName: "vehicleType",
    required: false,
    value: state.vehicleType,
    listDom: types,
    message: m("V0007"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "vehicleType");},
  };

  // 車両名
  const vehicleName = {
    label: "*車両名",
    attrName: "vehicleName",
    pattern: "^.{1,32}$",
    required: true,
    value: state.vehicleName,
    maxLength: 32,
    md: "5",
    message: m("V0007") + m("V0009", "32"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "vehicleName");},
  };


  // 車両ナンバー
  const vehicleNumber = {
    label: "車両ナンバー",
    attrName: "vehicleNumber",
    pattern: "^.{1,16}$",
    required: false,
    value: state.vehicleNumber,
    maxLength: 16,
    md: "5",
    message: m("V0008") + m("V0009", "16"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "vehicleNumber");},
  };

  // 駐車場住所
  const parkingAddress = {
    label: "駐車場住所",
    attrName: "parkingAddress",
    pattern: "^.{1,100}$",
    required: false,
    value: state.parkingAddress,
    maxLength: 100,
    md: "12",
    message: m("V0008") + m("V0009", "100"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "parkingAddress");},
  };

  // ドライバーリストの生成
  const drivers = data.driver_list.map((item: any) => {
    return <option key={item.driver_id} value={item.driver_id}>{item.driver_name}</option>;
  });

  const driverId = {
    label: "ドライバー",
    attrName: "driverId",
    required: false,
    value: state.driverId,
    listDom: drivers,
    message: m("V0007"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "driverId");},
  };

  // 車載機リストの生成
  const devices = data.device_list.map((item: any) => {
    return <option key={item.device_id} value={item.device_id}>{item.device_name}</option>;
  });

  const deviceId = {
    label: "車載機",
    attrName: "deviceId",
    required: false,
    value: state.deviceId,
    listDom: devices,
    message: m("V0007"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "deviceId");},
  };

  // エラーメッセージの生成
  const alert = errorMessage != null
    ? <Alert variant="danger" className="mt-2" dismissible onClose={e => setErrorMessage(null)}><strong>エラー：</strong>{errorMessage}</Alert>
    : null;

  // 登録ボタンの表示
  const registerButton = !isRequesting
    ? "登録"
    : <Spinner style={{width: "2rem", height: "2rem"}} as="span" animation="border" size="sm" role="status" aria-hidden="true" />;


  return (
    <>
      <div className="mt-5 ml-5 mr-5">
        <div className="d-flex">
          <h2 className="">{VEHICLE_REGISTER.title}</h2>
          <Button size="lg" variant="outline-primary" className="ml-auto" href={VEHICLE_LIST.path}>戻る</Button>
        </div>
        {alert}
        <Form noValidate validated={validated} onSubmit={handleSubmit}>

          <div className="text-danger my-3">*は必須入力</div>

          {/* 車両種別 */}
          <MySelect {...vehicleType}></MySelect>

          {/* 車両名 */}
          <MyRow {...vehicleName}></MyRow>

          {/* 車両ナンバー */}
          <MyRow {...vehicleNumber}></MyRow>

          {/* 駐車場住所 */}
          <MyRow {...parkingAddress}></MyRow>

          {/* ドライバー */}
          <MySelect {...driverId}></MySelect>

          {/* ドライバー */}
          <MySelect {...deviceId}></MySelect>

          <Button type="submit" className="d-block my-4 mr-auto px-5" disabled={isRequesting} size="lg">
            {registerButton}
          </Button>
        </Form>
      </div>
    </>
  );
};