import { AxiosRequestConfig } from "axios";
import { ConfirmDialog } from "components/parts/ConfirmDialog";
import { MyDatePicker, MyRow, MyRowMail, MyTextArea } from "components/parts/InputRow";
import { ENDPOINT } from "constants/endpoint";
import { m } from "constants/message";
import { useUserInfoContext } from "contexts/userInfoContext";
import jwt from "jsonwebtoken";
import { COMPANY_EDIT, COMPANY_LIST, MENU, SESSION_TIMEOUT } from "pages/pageInfo";
import queryString from "query-string";
import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { request } from "util/request";

interface State {
  "coPrefix": string,
  "companyName": string,
  "companyNameKana": string,
  "companyZip": string,
  "companyAddress": string,
  "companyUrl": string,
  "agentName": string,
  "salesmanName": string,
  "contractName": string,
  "contractGroup": string,
  "contractZip": string,
  "contractAddress": string,
  "contractTel": string,
  "contractFax": string,
  "contractMail": string,
  "loginId": string,
  "userName": string,
  "groupName": string,
  "adminZip": string,
  "adminAddress": string,
  "adminTel": string,
  "adminFax": string,
  "mail": string,
  "serviceContractDate": Date | null,
  "serviceStartDate": Date| null,
  "serviceEndDate": Date| null,
  "remarks": string,
}

export const CompanyEdit: React.FC<any> = (props) =>{
  const now = new Date();
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const history = useHistory();
  const [validated, setValidated] = useState(false);
  const [state, setState] = useState<State>({
    "coPrefix": "",
    "companyName": "",
    "companyNameKana": "",
    "companyZip": "",
    "companyAddress": "",
    "companyUrl": "",
    "agentName": "",
    "salesmanName": "",
    "contractName": "",
    "contractGroup": "",
    "contractZip": "",
    "contractAddress": "",
    "contractTel": "",
    "contractFax": "",
    "contractMail": "",
    "loginId": "",
    "userName": "",
    "groupName": "",
    "adminZip": "",
    "adminAddress": "",
    "adminTel": "",
    "adminFax": "",
    "mail": "",
    "serviceContractDate": null,
    "serviceStartDate": null,
    "serviceEndDate": null,
    "remarks": "",
  });
  const [showDeleteDlg, setSowDeleteDlg] = useState<boolean>(false);
  const companyId = useRef(0); // 0:初期状態
  const { signOut, } = useUserInfoContext();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [dateErrorMessage, setDateErrorMessage] = useState<string | null>(null);

  useEffect(()=>{
    document.title = COMPANY_EDIT.title;

    const query = queryString.parse(history.location.search)["company_id"] as string;
    if (query === undefined) {
      history.replace(MENU.path);
    }
    companyId.current = parseInt(query);
  },[history]);

  useEffect(()=>{
    // ログインIDが空で、リクエスト中でもない場合
    // リクエストを投げてデータを取得する
    if(state.loginId === "" && !isRequesting && !errorMessage){
      (async ()=>{
        setIsRequesting(true); // APIの二重コール防止用

        // GETリクエスト
        const config: AxiosRequestConfig = { method: "post", data: {company_id: companyId.current}};

        await request(ENDPOINT.COMPANY_DETAIL_INFO_GET, config, setErrorMessage)
          .then((res: any) =>{

            // 実データ部分のバインド
            const _state = {
              coPrefix: res.data.co_prefix,
              companyName: res.data.company_name,
              companyNameKana: res.data.company_name_kana !== null ? res.data.company_name_kana : "",
              companyZip: res.data.company_zip !== null ? res.data.company_zip : "",
              companyAddress: res.data.company_address !== null ? res.data.company_address : "",
              companyUrl: res.data.company_url !== null ? res.data.company_url : "",
              agentName: res.data.agent_name !== null ? res.data.agent_name : "",
              salesmanName: res.data.salesman_name !== null ? res.data.salesman_name : "",
              contractName: res.data.contract_name,
              contractGroup: res.data.contract_group !== null ? res.data.contract_group : "",
              contractZip: res.data.contract_zip !== null ? res.data.contract_zip : "",
              contractAddress: res.data.contract_address,
              contractTel: res.data.contract_tel,
              contractFax: res.data.contract_fax !== null ? res.data.contract_fax : "",
              contractMail: res.data.contract_mail,
              loginId: res.data.login_id,
              userName: res.data.user_name,
              groupName: res.data.group_name !== null ? res.data.group_name : "",
              adminZip: res.data.admin_zip !== null ? res.data.admin_zip : "",
              adminAddress: res.data.admin_address,
              adminTel: res.data.admin_tel,
              adminFax: res.data.admin_fax !== null ? res.data.admin_fax : "",
              mail: res.data.mail,
              serviceContractDate: res.data.service_contract_date !== null ? new Date(res.data.service_contract_date) : null,
              serviceStartDate: res.data.service_start_date !== null ? new Date(res.data.service_start_date) : null,
              serviceEndDate: res.data.service_end_date !== null ? new Date(res.data.service_end_date) : null,
              remarks: res.data.remarks !== null ? res.data.remarks : "",
            };

            setState({..._state});
            setIsRequesting(false);
          })
          .catch((err: any)=>{
            // トークン不正時、サインアウト処理してreturn ※サインアウト処理の中でタイムアウト画面へ遷移させる
            if(err instanceof jwt.JsonWebTokenError){ signOut(SESSION_TIMEOUT.path); return; }

            setIsRequesting(false);
            return;
          }
          );
      })();
    }
  });

  // submitボタンのハンドラ
  const handleSubmit = async (event: React.MouseEvent<HTMLInputElement>) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    // 入力チェック
    if (dateCorrelationCheck(state["serviceStartDate"], state["serviceEndDate"]) === false || form.checkValidity() === false) {
      await setValidated(true);
      return;
    }

    // 日付のフォーマット
    const zeroPadding = (num: number, len: number) => (String(num).padStart(len, "0"));
    const serviceContractDate = state["serviceContractDate"];
    const fomatedServiceContractDate = serviceContractDate ? zeroPadding(serviceContractDate.getFullYear(), 4) + "/" + zeroPadding(serviceContractDate.getMonth() + 1, 2) + "/" + zeroPadding(serviceContractDate.getDate(), 2) : null;
    const serviceStartDate = state["serviceStartDate"];
    const fomatedServiceStartDate = serviceStartDate ? zeroPadding(serviceStartDate.getFullYear(), 4) + "/" + zeroPadding(serviceStartDate.getMonth() + 1, 2) + "/" + zeroPadding(serviceStartDate.getDate(), 2) : null;
    const serviceEndDate = state["serviceEndDate"];
    const fomatedServiceEndDate = serviceEndDate ? zeroPadding(serviceEndDate.getFullYear(), 4) + "/" + zeroPadding(serviceEndDate.getMonth() + 1, 2) + "/" + zeroPadding(serviceEndDate.getDate(), 2) : null;

    setIsRequesting(true); // APIの二重コール防止用
    setErrorMessage(null);

    // POSTリクエスト
    const config: AxiosRequestConfig = {
      method: "post",
      data: {
        company_id: companyId.current,
        company_name: state["companyName"],
        company_name_kana: state["companyNameKana"] ? state["companyNameKana"] : null,
        company_zip: state["companyZip"] ? state["companyZip"] : null,
        company_address: state["companyAddress"] ? state["companyAddress"] : null,
        company_url: state["companyUrl"] ? state["companyUrl"] : null,
        agent_name: state["agentName"] ? state["agentName"] : null,
        salesman_name: state["salesmanName"] ? state["salesmanName"] : null,
        contract_name: state["contractName"],
        contract_group: state["contractGroup"] ? state["contractGroup"] : null,
        contract_zip: state["contractZip"] ? state["contractZip"] : null,
        contract_address: state["contractAddress"],
        contract_tel: state["contractTel"],
        contract_fax: state["contractFax"] ? state["contractFax"] : null,
        contract_mail: state["contractMail"],
        login_id: state["loginId"],
        user_name: state["userName"],
        group_name: state["groupName"] ? state["groupName"] : null,
        admin_zip: state["adminZip"] ? state["adminZip"] : null,
        admin_address: state["adminAddress"],
        admin_tel: state["adminTel"],
        admin_fax: state["adminFax"] ? state["adminFax"] : null,
        mail: state["mail"],
        service_contract_date: fomatedServiceContractDate,
        service_start_date: fomatedServiceStartDate,
        service_end_date: fomatedServiceEndDate,
        remarks: state["remarks"] ? state["remarks"] : null,
      },
    };

    await request(ENDPOINT.COMPANY_UPDATE, config, setErrorMessage)
      .then((res: any) =>{ history.push(COMPANY_LIST.path, sessionStorage.setItem("successMessage", m("M0004"))); })
      .catch((err: any)=>{
      // トークン不正時、サインアウト処理してreturn ※サインアウト処理の中でタイムアウト画面へ遷移させる
        if(err instanceof jwt.JsonWebTokenError){ signOut(SESSION_TIMEOUT.path); return; }
        setIsRequesting(false);
        return;
      }
      );
  };

  const handleDelete = async (event: React.MouseEvent<HTMLInputElement>) => {
    setIsRequesting(true); // APIの二重コール防止用
    setErrorMessage(null);

    // POSTリクエスト
    const config: AxiosRequestConfig = {
      method: "post",
      data: {
        company_id: companyId.current,
        login_id: state["loginId"],
      },
    };

    await request(ENDPOINT.COMPANY_DELETE, config, setErrorMessage)
      .then((res: any) =>{ history.push(COMPANY_LIST.path, sessionStorage.setItem("successMessage", m("M0005"))); })
      .catch((err: any)=>{
        // トークン不正時、サインアウト処理してreturn ※サインアウト処理の中でタイムアウト画面へ遷移させる
        if(err instanceof jwt.JsonWebTokenError){ signOut(SESSION_TIMEOUT.path); return; }
        setIsRequesting(false);
        setSowDeleteDlg(false);
        return;
      }
      );
  };

  // 入力欄に入力した時のハンドラ
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, target: string) => {
    // 空文字列はnullに変換する
    setState({...state, [target]: event.target.value});
  };

  // serviceContractDate入力欄に入力した時のハンドラ
  const handleOnChangeDate = (date: any, target: string) => {
    setState({...state, [target]: date});
  };

  // serviceStartDate入力欄に入力した時のハンドラ
  const handleOnChangeServiceStartDate = (date: any, target: string) => {
    setState({...state, [target]: date});
    if (validated === true){
      dateCorrelationCheck(date, state["serviceEndDate"]);
    }
  };

  // serviceEndDate入力欄に入力した時のハンドラ
  const handleOnChangeServiceEndDate = (date: any, target: string) => {
    setState({...state, [target]: date});
    if (validated === true){
      dateCorrelationCheck(state["serviceStartDate"], date);
    }
  };

  // ログインIDが空 ＆ リクエスト中でもない ＆ エラーメッセージが入っている場合、
  //  初期情報の取得でエラー終了しているのでエラーメッセージだけ出す
  if(state.loginId === "" && !isRequesting && errorMessage){
    return (
      <div className="mt-5 ml-5 mr-5">
        <div className="d-flex">
          <h2 className="">{COMPANY_EDIT.title}</h2>
          <Button size="lg" variant="outline-primary" className="ml-auto" href={COMPANY_LIST.path}>戻る</Button>
        </div>
        <Alert variant="danger" className="mt-2" dismissible onClose={e => setErrorMessage(null)}><strong>エラー：</strong>{errorMessage}</Alert>
      </div>
    );
  }

  // レスポンスを取得できていない場合、Loadingを表示
  if(state.loginId === "" || !state){
    return (
      <div className="mt-5 ml-5 mr-5">
        <div className="d-flex">
          <h2 className="">{COMPANY_EDIT.title}</h2>
          <Button size="lg" variant="outline-primary" className="ml-auto" href={COMPANY_LIST.path}>戻る</Button>
        </div>
        <Spinner animation="border" className="ml-4 mt-4" style={{width: "5rem", height: "5rem"}}/>
      </div>
    );
  }

  // dateの相関チェックを行う関数
  const dateCorrelationCheck = (startDate: any, endDate: any) =>{
    if(startDate !== null && endDate !== null){
      if(startDate > endDate){
        setDateErrorMessage(m("V0017", "サービス終了日", "サービス開始日"));
        return false;
      }
    }
    setDateErrorMessage(null);
    return true;
  };

  // 企業プレフィックス
  const coPrefix = {
    label: "企業プレフィックス",
    attrName: "coPrefix",
    pattern: "",
    required: false,
    disabled: true,
    value: state.coPrefix,
    maxLength: 4,
    md: "5",
    message: "",
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "coPrefix");},
  };

  // 企業名
  const companyName = {
    label: "*企業名",
    attrName: "companyName",
    pattern: "^.{1,256}$",
    required: true,
    value: state.companyName,
    maxLength: 256,
    md: "10",
    message: m("V0007") + m("V0009", "256"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "companyName");},
  };

  // 企業名（カナ）
  const companyNameKana = {
    label: "企業名（カナ）",
    attrName: "companyNameKana",
    pattern: "^.{1,512}$",
    required: false,
    value: state.companyNameKana,
    maxLength: 512,
    md: "10",
    message: m("V0008") + m("V0009", "512"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "companyNameKana");},
  };

  // 郵便番号
  const companyZip = {
    label: "郵便番号",
    attrName: "companyZip",
    pattern: "^[0-9-]{1,16}$",
    required: false,
    value: state.companyZip,
    maxLength: 16,
    md: "5",
    message: m("V0008") + m("V0016", "16"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "companyZip");},
  };

  // 住所
  const companyAddress = {
    label: "住所",
    attrName: "companyAddress",
    pattern: "^.{1,100}$",
    required: false,
    value: state.companyAddress,
    maxLength: 100,
    md: "10",
    message: m("V0008") + m("V0009", "100"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "companyAddress");},
  };

  // 企業ホームページURL
  const companyUrl = {
    label: "企業ホームページURL",
    attrName: "companyUrl",
    pattern: "^.{1,256}$",
    required: false,
    value: state.companyUrl,
    maxLength: 256,
    md: "10",
    message: m("V0008") + m("V0009", "256"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "companyUrl");},
  };

  // 代理店名
  const agentName = {
    label: "代理店名",
    attrName: "agentName",
    pattern: "^.{1,256}$",
    required: false,
    value: state.agentName,
    maxLength: 256,
    md: "10",
    message: m("V0008") + m("V0009", "256"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "agentName");},
  };

  // 営業担当者名
  const salesmanName = {
    label: "営業担当者名",
    attrName: "salesmanName",
    pattern: "^.{1,32}$",
    required: false,
    value: state.salesmanName,
    maxLength: 32,
    md: "10",
    message: m("V0008") + m("V0009", "32"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "salesmanName");},
  };

  // 担当者名
  const contractName = {
    label: "*担当者名",
    attrName: "contractName",
    pattern: "^.{1,32}$",
    required: true,
    value: state.contractName,
    maxLength: 32,
    md: "10",
    message: m("V0007") + m("V0009", "32"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "contractName");},
  };

  // 組織名
  const contractGroup = {
    label: "組織名",
    attrName: "contractGroup",
    pattern: "^.{1,32}$",
    required: false,
    value: state.contractGroup,
    maxLength: 32,
    md: "10",
    message: m("V0008") + m("V0009", "32"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "contractGroup");},
  };

  // 郵便番号
  const contractZip = {
    label: "郵便番号",
    attrName: "contractZip",
    pattern: "^[0-9-]{1,16}$",
    required: false,
    value: state.contractZip,
    maxLength: 16,
    md: "5",
    message: m("V0008") + m("V0016", "16"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "contractZip");},
  };

  // 住所
  const contractAddress = {
    label: "*住所",
    attrName: "contractAddress",
    pattern: "^.{1,100}$",
    required: true,
    value: state.contractAddress,
    maxLength: 100,
    md: "10",
    message: m("V0007") + m("V0009", "100"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "contractAddress");},
  };

  // 電話番号
  const contractTel = {
    label: "*電話番号",
    attrName: "contractTel",
    pattern: "^[0-9-]{1,20}$",
    required: true,
    value: state.contractTel,
    maxLength: 20,
    md: "10",
    message: m("V0007") + m("V0016", "20"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "contractTel");},
  };

  // FAX番号
  const contractFax = {
    label: "FAX番号",
    attrName: "contractFax",
    pattern: "^[0-9-]{1,20}$",
    required: false,
    value: state.contractFax,
    maxLength: 20,
    md: "10",
    message: m("V0008") + m("V0016", "20"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "contractFax");},
  };

  // メールアドレス
  const contractMail = {
    label: "*メールアドレス",
    attrName: "contractMail",
    required: true,
    value: state.contractMail,
    maxLength: 256,
    md: "10",
    message: m("V0007") + m("V0011", "256"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "contractMail");},
  };

  // 管理者ログインID
  const loginId = {
    label: "管理者ログインID",
    attrName: "loginId",
    pattern: "",
    required: false,
    disabled: true,
    value: state.loginId,
    maxLength: 32,
    md: "10",
    message: "",
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "loginId");},
  };

  // 企業管理者名
  const userName = {
    label: "*企業管理者名",
    attrName: "userName",
    pattern: "^.{1,32}$",
    required: true,
    value: state.userName,
    maxLength: 32,
    md: "10",
    message: m("V0007") + m("V0009", "32"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "userName");},
  };

  // 組織名
  const groupName = {
    label: "組織名",
    attrName: "groupName",
    pattern: "^.{1,32}$",
    required: false,
    value: state.groupName,
    maxLength: 32,
    md: "10",
    message: m("V0008") + m("V0009", "32"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "groupName");},
  };

  // 郵便番号
  const adminZip = {
    label: "郵便番号",
    attrName: "adminZip",
    pattern: "^[0-9-]{1,16}$",
    required: false,
    value: state.adminZip,
    maxLength: 16,
    md: "5",
    message: m("V0008") + m("V0016", "16"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "adminZip");},
  };

  // 住所
  const adminAddress = {
    label: "*住所",
    attrName: "adminAddress",
    pattern: "^.{1,100}$",
    required: true,
    value: state.adminAddress,
    maxLength: 100,
    md: "10",
    message: m("V0007") + m("V0009", "100"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "adminAddress");},
  };

  // 電話番号
  const adminTel = {
    label: "*電話番号",
    attrName: "adminTel",
    pattern: "^[0-9-]{1,20}$",
    required: true,
    value: state.adminTel,
    maxLength: 20,
    md: "10",
    message: m("V0007") + m("V0016", "20"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "adminTel");},
  };

  // FAX番号
  const adminFax = {
    label: "FAX番号",
    attrName: "adminFax",
    pattern: "^[0-9-]{1,20}$",
    required: false,
    value: state.adminFax,
    maxLength: 20,
    md: "10",
    message: m("V0008") + m("V0016", "20"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "adminFax");},
  };

  // メールアドレス
  const mail = {
    label: "*メールアドレス",
    attrName: "mail",
    required: true,
    value: state.mail,
    maxLength: 256,
    md: "10",
    message: m("V0007") + m("V0011", "256"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "mail");},
  };

  // 契約日
  const serviceContractDate = {
    label: "契約日",
    startDate: state.serviceContractDate,
    dateFormat: "yyyy/MM/dd",
    showTimeInput: false,
    timeInputLabel: "",
    maxDate: new Date(now.getFullYear() + 100, now.getMonth(), now.getDate()),
    isClearable: true,
    disabled: false,
    onChange: (e: any)=>{handleOnChangeDate(e, "serviceContractDate");},
  };

  // サービス開始日
  const serviceStartDate = {
    label: "サービス開始日",
    startDate: state.serviceStartDate,
    dateFormat: "yyyy/MM/dd",
    showTimeInput: false,
    timeInputLabel: "",
    maxDate: new Date(now.getFullYear() + 100, now.getMonth(), now.getDate()),
    isClearable: true,
    disabled: false,
    messageClassName: "mx-0 pb-3 small text-danger",
    message: dateErrorMessage,
    onChange: (e: any)=>{handleOnChangeServiceStartDate(e, "serviceStartDate");},
  };

  // サービス終了日
  const serviceEndDate = {
    label: "サービス終了日",
    startDate: state.serviceEndDate,
    dateFormat: "yyyy/MM/dd",
    showTimeInput: false,
    timeInputLabel: "",
    maxDate: new Date(now.getFullYear() + 100, now.getMonth(), now.getDate()),
    isClearable: true,
    disabled: false,
    onChange: (e: any)=>{handleOnChangeServiceEndDate(e, "serviceEndDate");},
  };

  // 備考
  const remarks = {
    label: "備考",
    attrName: "remarks",
    required: false,
    value: state.remarks,
    maxLength: 512,
    md: "10",
    message: m("V0008") + m("V0009", "512"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "remarks");},
  };


  // エラーメッセージの生成
  const alert = errorMessage != null
    ? <Alert variant="danger" className="mt-2" dismissible onClose={e => setErrorMessage(null)}><strong>エラー：</strong>{errorMessage}</Alert>
    : null;

  // 保存ボタンの表示
  const saveButton = !isRequesting
    ? "保存"
    : <Spinner style={{width: "2rem", height: "2rem"}} as="span" animation="border" size="sm" role="status" aria-hidden="true" />;

  // 削除ダイアログのはいボタンの表示
  const yesButton = !isRequesting
    ? "はい"
    : <Spinner style={{width: "1.5rem", height: "1.5rem"}} as="span" animation="border" size="sm" role="status" aria-hidden="true" />;

  // 削除ダイアログのonHideの制御（リクエスト中は背景部分を押しても反応させないようにする）
  const handleOnHide = ()=>{
    if(!isRequesting){
      setSowDeleteDlg(false);
    }
  };

  return (
    <>
      <div className="mt-5 ml-5 mr-5">
        <div className="d-flex">
          <h2 className="">{COMPANY_EDIT.title}</h2>
          <Button size="lg" variant="outline-primary" className="ml-auto" href={COMPANY_LIST.path}>戻る</Button>
        </div>
        {alert}
        <Form noValidate validated={validated} onSubmit={handleSubmit}>

          <div className="text-danger my-3">*は必須入力</div>

          <h5 className="mt-4 mb-4">契約企業情報</h5>

          {/* 企業プレフィックス */}
          <MyRow {...coPrefix}></MyRow>

          {/* 企業名 */}
          <MyRow {...companyName}></MyRow>

          {/* 企業名（カナ） */}
          <MyRow {...companyNameKana}></MyRow>

          {/* 郵便番号 */}
          <MyRow {...companyZip}></MyRow>

          {/* 住所 */}
          <MyRow {...companyAddress}></MyRow>

          {/* 企業ホームページURL */}
          <MyRow {...companyUrl}></MyRow>

          {/* 代理店名 */}
          <MyRow {...agentName}></MyRow>

          {/* 営業担当者名 */}
          <MyRow {...salesmanName}></MyRow>

          <h5 className="mt-4 mb-4">契約担当者</h5>

          {/* 担当者名 */}
          <MyRow {...contractName}></MyRow>

          {/* 組織名 */}
          <MyRow {...contractGroup}></MyRow>

          {/* 郵便番号 */}
          <MyRow {...contractZip}></MyRow>

          {/* 住所 */}
          <MyRow {...contractAddress}></MyRow>

          <div className="form-group row">
            <div className="col-sm-5">
              {/* 電話番号 */}
              <MyRow {...contractTel}></MyRow>
            </div>
            <div className="col-sm-5">
              {/* FAX番号 */}
              <MyRow {...contractFax}></MyRow>
            </div>
          </div>

          {/* メールアドレス */}
          <MyRowMail {...contractMail}></MyRowMail>

          <h5 className="mt-4 mb-4">企業管理者</h5>

          {/* 管理者ログインID */}
          <MyRow {...loginId}></MyRow>

          {/* 企業管理者名 */}
          <MyRow {...userName}></MyRow>

          {/* 組織名 */}
          <MyRow {...groupName}></MyRow>

          {/* 郵便番号 */}
          <MyRow {...adminZip}></MyRow>

          {/* 住所 */}
          <MyRow {...adminAddress}></MyRow>

          <div className="form-group row">
            <div className="col-sm-5">
              {/* 電話番号 */}
              <MyRow {...adminTel}></MyRow>
            </div>
            <div className="col-sm-5">
              {/* FAX番号 */}
              <MyRow {...adminFax}></MyRow>
            </div>
          </div>

          {/* メールアドレス */}
          <MyRowMail {...mail}></MyRowMail>

          <h5 className="mt-4 mb-4">サービス設定</h5>

          {/* 契約日 */}
          <MyDatePicker {...serviceContractDate}></MyDatePicker>

          <div className="form-group row">
            <div className="col-sm-3">
              {/* サービス開始日 */}
              <MyDatePicker {...serviceStartDate}></MyDatePicker>
            </div>
            <div className="col-sm-3">
              {/* サービス終了日 */}
              <MyDatePicker {...serviceEndDate}></MyDatePicker>
            </div>
          </div>

          {/* 備考 */}
          <MyTextArea {...remarks}></MyTextArea>

          <div className="d-flex my-4">
            <Button type="submit" className="d-block mr-4 px-5" disabled={isRequesting} size="lg">
              {saveButton}
            </Button>
            <Button className="d-block px-5" onClick={e=>{setSowDeleteDlg(true);}} disabled={isRequesting} size="lg">
              削除
            </Button>
          </div>
        </Form>
      </div>

      <ConfirmDialog
        show={showDeleteDlg}
        isRequesting={isRequesting}
        handleClose={handleOnHide}
        handleYes={handleDelete}
        yesButtonDom={yesButton}
        message={m("CONFIRM_DELETE")}
      >
      </ConfirmDialog>
    </>
  );
};