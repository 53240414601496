import { Auth } from "aws-amplify";
import axios, { AxiosRequestConfig } from "axios";
import { m } from "constants/message";
import jwt from "jsonwebtoken";
import { checkIdToken, generateJwkPemForIdToken, getIdTokenFromLocalStorage } from "util/idtoken";
export const request = async (
  url: string,
  config: AxiosRequestConfig,
  setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>,
  // ヘッダーにIDトークンを付与しない場合はfalseを指定する
  // (Roads APIなど、外部APIをコールするときにPreflight requestを避けたいのと、不要な情報の付与を防ぐため)
  isNeedToken: boolean = true) => {

  // ディープコピー
  let _cnf = JSON.parse(JSON.stringify(config));

  if(isNeedToken) {
    // IDトークンチェック用にLocalStorageからIDトークンを取得する
    let idToken = getIdTokenFromLocalStorage();

    // localStorageからIDトークンとれない場合、エラー
    if(idToken == null){
      throw new jwt.JsonWebTokenError("invalid token");
    }

    // IDトークンの検証を実施する
    const jwk = await generateJwkPemForIdToken(idToken);
    const tokenCheckResult = checkIdToken(idToken, jwk.jwk.alg, jwk.pem);

    // 不正なトークン or IDトークンの期限切れの場合、エラー
    if(!tokenCheckResult){
      throw new jwt.JsonWebTokenError("invalid token");
    }

    // IDトークンの更新
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    await cognitoUser.refreshSession(currentSession.getRefreshToken(), () => { });

    _cnf.headers = !_cnf.headers ? {} : _cnf.headers; // headersが指定されていない場合はheadersを作る
    _cnf.headers["x-id-token"] = currentSession.getIdToken().getJwtToken(); // x-id-tokenを付与する
  }

  return await axios(url, _cnf)
    .catch((err)=>{
      // エラーレスポンスの場合

      let message = null;
      // レスポンスに何も入っていない場合（Lambdaより前でエラーになっている場合）
      if(!err.response.data){
        message = m("AM9999");

      } else {
        // error_messageがレスポンスに入っている場合、アラートのメッセージとする
        // error_messageがレスポンスに入っていない場合、共通エラーアラートとする
        message = ("message" in err.response.data)
          ? err.response.data.message
          : m("AM9999");
      }

      setErrorMessage(message);
      throw err;
    });
};