import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { AxiosRequestConfig } from "axios";
import "chartjs-plugin-annotation";
import { ENDPOINT } from "constants/endpoint";
import { m } from "constants/message";
import { useUserInfoContext } from "contexts/userInfoContext";
import GoogleMapReact, { MapOptions, Maps } from "google-map-react";
import jwt from "jsonwebtoken";
import { DRIVING_HISTORY_DETAIL, SESSION_TIMEOUT, MOVIE_PLAYBACK, TRACKING_MANAGEMENT, TRACKING_MANAGEMENT_GETFILE } from "pages/pageInfo";
import queryString from "query-string";
import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Container, Dropdown, Row, Spinner } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import useInterval from "use-interval";
import { request } from "util/request";

interface QueryParams {
  "vehicleId": string
}

const GoogleMapWrapper = styled.div`
  height: 78vh;
  width: 100%;
  float: left;
  `;

const Logo = styled.img`
  position: absolute;
  width: 40px;
  height: 40px;
  left: -20px;
  top: -20px;
  textAlign: center;
  padding: 4px;
  transform: scale(-1,1);
  transform: rotate(${({theme}) => theme.main}deg);
  cursor: pointer;
  `;

const HeadingIcon = styled.img`
  height: 30px;
  transform: scale(-1,1);
  transform: rotate(${({theme}) => theme.main}deg);
  `;

const DropdownMenuEvents = styled(Dropdown.Menu)`
  background-color: white;
  width: 270px;
  transform: translate(-230px, 34px);
  `;

const Pin = styled.div<{
    lat: number,
    lng: number
  }>`
  `;

const CustomToggle = styled(Dropdown.Toggle)`
  &::after {
    content: none;
  }
`;

const google_map_key = "AIzaSyBA3-5wbmyiAJ21Fu65g6QpX9kR6RfZCrw";
// 危険挙動アイコン
const danger_icon = "/img/map_icons/danger.png";
// 駐車中アイコン
const stopped_icon = "/img/stopped.svg";
// 進行中アイコン
const runninng_icon = "/img/vehicle.svg";
// 地図検索アイコン
// const search_icon = "/img/search.svg";
// 地図リセットアイコン
// const reset_icon = "/img/reset.svg";

// 運行動態情報取得APIを実行するインターバル
const apiIntervalTime = 60000;

// 車両の緯度経度が取得できない場合に設定する座標(東京駅)
const DEFAULT_CENTER_LAT = 35.6809591;
const DEFAULT_CENTER_LNG = 139.7673068;

interface MapFocusProps {
  lat: number;
  lng: number
}

export const TrackingManagement = () => {
  const { signOut, } = useUserInfoContext();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const history = useHistory();
  // 運行動態情報取得API実行中Flg
  const [isRequestingTrackingManagement, setIsRequestingTrackingManagement] = useState<boolean>(false);
  // 車両一覧に表示させるデータ一覧
  const [vehicleData, setVehicleData] = useState<any>(null); // 初期状態：null、エラー時：undefined とする
  // 更新フラグ（車両一覧、地図）
  const updateFlg = useRef<boolean>(true);
  // 車両最終通信時刻マップ
  const vehicleLastUpdatedTimeMap = useRef<Map<any,any>>(new Map<any,any>());
  // 車両停止中フラグ
  const stoppedVehiclesList = useRef<any>([]);
  // 表示対象の地図データ
  const [mapData, setMapData] = useState<any>(null); // 初期状態：null
  // クエリストリング取得フラグ
  const getQueryStringFlg = useRef<boolean>(false);
  // クエリストリング
  const queryParams = useRef<QueryParams>({
    "vehicleId": ""
  });
  // 表示対象の車両ID
  const [mainVehicleId, setMainVehicleId] = useState<number| undefined>(0);
  // 地図の危険挙動ポップアップで使用
  const [currentKey, setCurrentKey] = useState(-1);
  // 表示対象の車両情報が存在しない場合のエラーメッセージ
  const [noVehicleInfoErrorMessage, setNoVehicleInfoErrorMessage] = useState<string | null>(null);
  // APIで取得してきたres.data.vehicle_infosそのまま
  const vehicle_infos = useRef<any>(null);
  // セッションストレージ（タブを削除すればデータは消える）
  const storage = sessionStorage;
  // 初期表示フラグ
  const firstShowFlg = useRef<boolean>(true);

  // 地図の中心位置
  const [mapFocus, setMapFocus] = useState<MapFocusProps>({
    "lat": DEFAULT_CENTER_LAT,
    "lng": DEFAULT_CENTER_LNG
  });

  // 地図検索の入力補完
  const [autoSrc, setAutoSrc] = useState([]);


  // クエリストリングの取得処理
  useEffect(()=>{
    document.title = TRACKING_MANAGEMENT.title;
    // クエリストリング取得
    if(queryParams.current["vehicleId"] === "" && !getQueryStringFlg.current){
      // vehicle_id: 9000 / なし
      const vehicleId = queryString.parse(history.location.search)["vehicle_id"] as string;
      // クエリストリングが存在していた場合
      if(vehicleId !== undefined){
        // クエリストリングに車両IDを設定する（地図上に表示させる車両）
        queryParams.current["vehicleId"] = vehicleId;
      }else{
        // クエリストリングが存在しない場合は、セッション（車両情報一覧のスクロールバーの位置情報を削除する）
        storage.removeItem("TrackingManagementVehicleListScrollTop");
      }
    }
    // クエリストリングチェックを完了
    getQueryStringFlg.current = true;
  });

  // 車両情報一覧のスクロール位置を設定する
  useEffect(()=>{
    // 運行動態情報取得API実行が完了、初期表示、クエリストリングが存在している場合のみスクロール位置を設定する
    if(!isRequestingTrackingManagement && firstShowFlg.current && getQueryStringFlg.current){
      // クエリストリングがある場合（車両IDが指定されている場合）
      if(queryParams.current["vehicleId"] !== ""){
        // 車両情報一覧のdiv
        const scroll = document.getElementById("vehicleList");
        if(scroll){
          // 車両情報一覧divのスクロールバーの位置に、セッションストレージ（スクロール位置）を設定する
          const scrollTop = storage.getItem("TrackingManagementVehicleListScrollTop");
          if(scrollTop){
            scroll.scrollTop = parseInt(scrollTop);
          }
          // 初期表示フラグをfalseにする
          firstShowFlg.current = false;
        }
      }
    }
  });

  // 運行動態情報取得APIの定期実行処理
  useInterval(() => {

    if(!isRequestingTrackingManagement && getQueryStringFlg.current){

      (async () => {
        setIsRequestingTrackingManagement(true); // APIの二重コール防止用

        // POSTリクエスト（走行履歴詳細情報取得
        const config: AxiosRequestConfig = {
          method: "post",
          data: {
          },
        };

        await request(ENDPOINT.TRACKING_MANAGEMENT_GET, config, setErrorMessage)
          .then((res: any) => {
            // 更新フラグの初期化 falseにする
            updateFlg.current = false;

            // 表示対象の車両が存在していた場合
            if(res.data.vehicle_infos.length > 0){
              // APIで取得してきたデータ（加工なし）を保持する
              vehicle_infos.current = res.data.vehicle_infos;

              // 車両最終通信時刻マップの要素数が0の場合は更新フラグをtrueにする
              if(vehicleLastUpdatedTimeMap.current.size <= 0){
                updateFlg.current = true;
              }else{
              // 車両最終通信時刻マップにデータが存在していた場合
              // 運行動態情報取得APIで取得したデータと比較し、差異があれば更新フラグをtrueにする
                updateFlg.current = res.data.vehicle_infos.some(function(item: any){
                // key: number
                // time: string
                // 車両最終通信時刻マップに対象となる車両IDが存在していない場合（新しく車両の情報が追加された場合）
                  if(!vehicleLastUpdatedTimeMap.current.has(item.vehicle_id)){
                  // フラグを立てる
                    return true;
                  }else{
                  // 車両最終通信時刻マップに対象となる車両IDが存在しているが、最終通信時刻が更新されていた場合
                    if(vehicleLastUpdatedTimeMap.current.get(item.vehicle_id) !== item.time){
                    // フラグを立てる
                      return true;
                    }
                  }
                  // 差異がなければ更新フラグはfalseのまま返す
                  return false;
                });

              }
              // 車両最終通信時刻マップを運行動態情報取得APIで取得したデータで更新する
              res.data.vehicle_infos.map((Item: any) => {
                return vehicleLastUpdatedTimeMap.current.set(Item.vehicle_id,Item.time);
              });

              // 最終通信時刻（運行動態情報取得API）と現在時刻を比較して差が3分以上の場合、走行方向を「stopped」に書き換える
              // 現在時刻-3分のDateオブジェクトを生成する
              const dt = new Date();
              res.data.vehicle_infos.forEach(function(item: any){
              // key: number
              // time: string 2020-10-19 08:19:40

                // timeからDateオブジェクトを生成する(timeがnullの場合、UNIX時間の元期にする)
                let changedDate = item.time !== null ? changeToDate(item.time) : new Date(1970, 0, 1, 9, 0, 0);

                // 現在時刻と最終通信時刻の差を求める
                let diff = dt.getTime() - changedDate.getTime();
                // 求めた差を分に直す
                const diffMin = diff / (1000 * 60);
                // 差が3分以上であれば走行方向を「stopped」に書き換える
                if(diffMin >= 3){
                  item.heading = "stopped";
                  // 停止中車両一覧に存在していない場合
                  if(stoppedVehiclesList.current.indexOf(item.vehicle_id) < 0){
                  // 更新フラグをtrueにする
                    updateFlg.current = true;
                    // 停止中車両一覧に追加する
                    stoppedVehiclesList.current.push(item.vehicle_id);
                  }
                // 停止中車両一覧に存在している場合は特に何もしない
                } else{
                // 差が3分未満の場合
                // 停止中車両一覧に既に登録されているかをチェック：存在している→配列の要素番号、存在していない→-1
                  let num = stoppedVehiclesList.current.indexOf(item.vehicle_id);
                  // 停止中車両一覧に登録されている場合
                  if(num >= 0){
                  // 更新フラグをtrueにする
                    updateFlg.current = true;
                    // 停止中車両一覧から除外する
                    stoppedVehiclesList.current.splice(num, 1);
                  }
                // 停止中車両一覧に存在していない場合は特に何もしない
                }

              });
            } else{
              // 直前まで最終通信時刻Mapにデータが存在していた場合、
              if(vehicleLastUpdatedTimeMap.current.size > 0){
                // 最終通信時刻Map、停止中車両一覧Listを初期化する
                vehicleLastUpdatedTimeMap.current.clear();
                stoppedVehiclesList.current = [];
                // 更新フラグをtrueにする
                updateFlg.current = true;
              }
              // 表示対象の車両がない旨のメッセージを設定する
              setNoVehicleInfoErrorMessage(m("M0006"));
            }
            // 更新フラグがtrueの場合、再レンダリングする
            if(updateFlg.current === true){
              // 車両一覧情報を設定する
              setVehicleData(res.data);
              // 表示対象の車両情報を設定する
              const selectedMapInfo = selectMapInfo(res.data.vehicle_infos);
              setMapData(selectedMapInfo);
              // 地図の中心を設定する（緯度経度がnullの場合、初期位置の値で設定する)
              if(selectedMapInfo.lat && selectedMapInfo.lon) {
                setMapFocus({...mapFocus, lat: selectedMapInfo.lat, lng: selectedMapInfo.lon});
              } else {
                setMapFocus({...mapFocus, lat: DEFAULT_CENTER_LAT, lng: DEFAULT_CENTER_LNG});
              }
            }
            setIsRequestingTrackingManagement(false);
          }
          )
          .catch((err: any) => {
            // トークン不正時、サインアウト処理してreturn ※サインアウト処理の中でタイムアウト画面へ遷移させる
            if (err instanceof jwt.JsonWebTokenError){ signOut(SESSION_TIMEOUT.path); return; }
            setVehicleData(undefined);
            setIsRequestingTrackingManagement(false); // 必ずsetDetailDataの方が先（detailDataがnullのまま先にisRequestingをfalseにすると2回目のリクエストを投げるので。。）
            return;
          }
          );
      })();
    }

  // apiIntervalTimeにインターバル時間を指定する。第三引数は最初に処理を行うかどうか
  }, apiIntervalTime, true);

  // 表示対象の車両情報を決定する
  function selectMapInfo(vehicle_infos: any[]){
    // 返却値
    let result;
    // クエリストリングで指定された車両ID
    const targetVehicleId = queryParams.current["vehicleId"];
    // APIで取得した車両情報が1件以上存在していた場合
    if(vehicle_infos.length > 0){
      // クエリストリングで車両IDが指定されていた場合
      if(targetVehicleId === ""){
        // 車両一覧の先頭車両を表示対象とする
        result = vehicle_infos[0];
        // 表示対象の車両IDを設定する
        setMainVehicleId(vehicle_infos[0].vehicle_id);
      }else{
        // クエリストリングで指定された車両IDが存在するかチェック
        const isVehicleData = vehicle_infos.find((v) => v.vehicle_id === parseInt(targetVehicleId));
        // 存在する場合
        if(isVehicleData){
          // 対象の車両IDの情報を表示対象とする
          result = vehicle_infos.find((v) => v.vehicle_id === parseInt(targetVehicleId));
          setMainVehicleId(result.vehicle_id);
        }else{
          // 存在しない場合、車両一覧の先頭車両を表示対象とする
          result = vehicle_infos[0];
          setMainVehicleId(vehicle_infos[0].vehicle_id);
        }
      }

    }
    return result;
  }

  // 「yyyy-mm-dd HH:MM:ss」の形式の日付文字列をDate型に変換して返す
  var changeToDate = function (strDate: string){
    // timeを年月日時分秒に分解する
    const year = parseInt(strDate.slice(0,4));
    const month = parseInt(strDate.slice(5,7));
    const date = parseInt(strDate.slice(8,10));
    const hour = parseInt(strDate.slice(11,13));
    const minutes = parseInt(strDate.slice(14,16));
    const seconds = parseInt(strDate.slice(17,19));

    const time = new Date(year,(month - 1),date,hour,minutes,seconds);

    return time;

  };

  // 検索条件部分の生成
  const condition = (
    <React.Fragment>
      <div>
        <h5>車両一覧</h5>
      </div>
      <hr />
    </React.Fragment>
  );

  // 車両選択ボタンのハンドラ
  const TestHandleClick = (event: React.MouseEvent<HTMLInputElement>, vehicle_id: string) => {
    event.preventDefault();
    event.stopPropagation();
    // 選択中の車両情報の車両IDと選択した車両IDが一致していた場合、何もしないで返す
    if(queryParams.current["vehicleId"] === vehicle_id){return;}
    // 選択した車両情報を設定しなおす
    changeSelectedData(vehicle_id);
    // 画面は遷移させない（URLのクエリストリングが変わるだけ）
    history.replace(`${TRACKING_MANAGEMENT.path}?vehicle_id=${vehicle_id}`);
  };

  // 車両情報を対象の車両IDの情報に設定しなおす
  function changeSelectedData(vehicle_id: string){
    // エラーメッセージ
    setErrorMessage(null);
    // 表示対象の車両IDを再設定
    // クエリストリング（取得）の車両IDを設定しなおす
    queryParams.current["vehicleId"] = vehicle_id;
    // 表示対象の車両IDを再設定
    setMainVehicleId(parseInt(vehicle_id));
    // 対象の車両IDの地図情報を取得しなおす
    const result = vehicle_infos.current.find((v: { vehicle_id: number; }) => v.vehicle_id === parseInt(vehicle_id));
    // 地図情報を再設定する
    setMapData(result);
    // 地図の中心を再設定する（緯度経度がnullの場合、初期位置の値で再設定する)
    if(result.lat && result.lon) {
      setMapFocus({...mapFocus, lat: result.lat, lng: result.lon});
    } else {
      setMapFocus({...mapFocus, lat: DEFAULT_CENTER_LAT, lng: DEFAULT_CENTER_LNG});
    }
  }

  // 車両情報一覧のスクロールバーのハンドラ
  const VehicleInfoListHandleScroll = () => {
    const scroll = document.getElementById("vehicleList");
    if(scroll){
      // セッションストレージに車両情報一覧のスクロール位置を設定する
      storage.setItem("TrackingManagementVehicleListScrollTop",scroll.scrollTop.toString());
    }
  };

  // 動画像取得ボタンのハンドラ
  const TrackingManagementGetFileHandleClick = (event: React.MouseEvent<HTMLInputElement>, vehicle_id: string) => {
    event.preventDefault();
    event.stopPropagation();

    window.open(`${TRACKING_MANAGEMENT_GETFILE.path}?vehicle_id=${vehicle_id}`);
  };

  // 車両一覧部分の生成
  const vehicleList = !vehicleData
    ? (isRequestingTrackingManagement
      // レスポンスデータ無しでリクエスト中の場合、Loading表示
      ? <Spinner animation="border" className="mt-4" style={{width: "5rem", height: "5rem"}}/>
      // レスポンスデータ無しでリクエスト中でもない場合、何も表示しない（動態管理画面からの遷移の場合）
      : null
    )
    : (
      <React.Fragment>
        <div id="vehicleList" className="scroll" style={{overflowX: "auto", position: "relative"}} onScroll={() => VehicleInfoListHandleScroll()} >
          {
            vehicleData.vehicle_infos.map((item: any, index: any) => {
              return (
                <React.Fragment key={item.drive_date + item.vehicle_id}>
                  <div className="mt-lg-1" style={{display: "inline-block", position: "relative"}}>
                    <div style={{display: "inline-block"}}>
                      <Button
                        variant="light"
                        className="text-left"
                        type="submit"
                        size="sm"
                        style={{paddingRight: "100px"}}
                        active={mainVehicleId === item.vehicle_id ? true : false}
                        onClick={(e: React.MouseEvent<HTMLInputElement>) => TestHandleClick(e, item.vehicle_id)}
                      >
                        <table className="table table-borderless table-sm" style={{tableLayout: "fixed"}}>
                          <tbody>
                            <tr>
                              {/* 進行方向アイコン */}
                              <td className="align-middle" style={{padding: "5px 0px 0px 6px"}} width="40px">
                                { item.heading === "stopped"
                                  ? <HeadingIcon theme={{main: 0}} alt="Link" src={stopped_icon} />
                                  : <HeadingIcon theme={{main: item.heading}} alt="Link" src={runninng_icon} />
                                }
                              </td>
                              {/* 車両名 */}
                              <td style={{padding: "10px 0px 0px 6px"}} width="95px">{item.vehicle_name}</td>
                              {/* 車両ナンバー */}
                              <td style={{padding: "10px 0px 0px 10px"}} width="85px">{item.vehicle_number}</td>
                            </tr>
                          </tbody>
                        </table>
                        <table className="table table-borderless table-sm" style={{tableLayout: "fixed"}}>
                          <tbody>
                            <tr>
                              {/* 速度 */}
                              <td className="align-center" width="40px">
                                { item.heading === "stopped"
                                  ? "駐車中"
                                  // p_speedがなければg_speedを使う
                                  : item.p_speed
                                    ? item.p_speed + " km/h"
                                    : item.g_speed + " km/h"
                                }
                              </td>
                              {/* ドライバー */}
                              <td className="align-center" style={{padding: "4px 0px 0px 6px"}} width="95px">{item.driver_name}</td>
                              {/* 最終通信時刻 */}
                              <td style={{padding: "4px 0px 0px 10px"}} width="85px">{item.time}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Button>
                    </div>
                    {/* その他メニュー */}
                    <div style={{display: "inline-block",position: "absolute", top: "8px", right: "20px"}}>
                      <table className="table table-borderless table-sm">
                        <tbody>
                          <tr>
                            <td className="col-sm" style={{padding: "0px 0px 0px 0px"}}>
                              <Dropdown style={{left: "10px"}}>
                                <CustomToggle variant="light" id="dropdown-basic"
                                  style={{fontSize: "11px",whiteSpace: "normal",width: "45px",height: "25px",padding: "0px"}}>・・・
                                </CustomToggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    disabled={ item.heading === "stopped" ? true : false } >
                                    {/* 駐車中の場合は非活性状態とする */}
                                    <Button
                                      onClick={(e: React.MouseEvent<HTMLInputElement>) => TrackingManagementGetFileHandleClick(e, item.vehicle_id)}
                                      type="submit"
                                      style={{maxWidth: "100px", maxHeight: "40px", minWidth: "100px", minHeight: "40px"}}
                                      variant="info" disabled={ item.heading === "stopped" ? true : false } >
                                      動画像取得
                                    </Button>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    href={`${DRIVING_HISTORY_DETAIL.path}?source=02&vehicle_id=${item.vehicle_id}&driver_id=${item.driver_id}&drive_date=${item.drive_date}`}
                                    disabled={ item.lat && item.lon ? false : true }>
                                    {/* 車両の緯度経度がnullの場合は非活性状態とする */}
                                    <Button
                                      type="submit"
                                      style={{maxWidth: "110px", maxHeight: "40px", minWidth: "110px", minHeight: "40px"}}
                                      variant="info" disabled={ item.lat && item.lon ? false : true }>
                                      走行軌跡表示
                                    </Button>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      {/* 危険挙動アイコン */}
                      { !("events" in item)
                        ? null
                        : <table style={{position: "absolute", top: "35px",left: "10px",maxWidth: "45px", maxHeight: "50px", minWidth: "45px", minHeight: "50px"}}>
                          <tbody>
                            <tr>
                              <td>
                                <Dropdown>
                                  <CustomToggle
                                    variant="light" id="dropdown-basic"
                                    style={{padding: "0px"}
                                    }>
                                    <img alt="Link" src={danger_icon} width="80%"/>
                                  </CustomToggle>
                                  <DropdownMenuEvents>
                                    {item.events.map((item: any, index: any, array: any) => {return (
                                      <React.Fragment key={item.event_id}>
                                        <Dropdown.Item style={{whiteSpace: "normal"}}
                                          disabled={ item.snapshot_mng_id ? false : true }>
                                          {/* 静止画管理IDがnullの場合は非活性状態とする */}
                                          <Button
                                            onClick={() => window.open(`${MOVIE_PLAYBACK.path}?source=06&type=snapshot&mng_ids=${item.snapshot_mng_id}&main_mng_id_num=0&event_id=${item.event_id}`)}
                                            type="submit"
                                            style={{textAlign: "left", width: "100%", padding: "10px", wordBreak: "break-all"}}
                                            variant="warning" disabled={ item.snapshot_mng_id ? false : true }>
                                            <div>
                                              {
                                                // イベント緯度、経度がなかった場合、文字色を赤にする
                                                item.event_lat && item.event_lon
                                                  ? <p style={{color: "black"}}>危険挙動：{item.event_name}</p>
                                                  : <p style={{color: "red"}}>危険挙動：{item.event_name}</p>
                                              }
                                            </div>
                                            <div>
                                              {
                                                // イベント緯度、経度がなかった場合、文字色を赤にする
                                                item.event_lat && item.event_lon
                                                  ? <p style={{color: "black"}}>発生日時：{item.event_datetime}</p>
                                                  : <p style={{color: "red"}}>発生日時：{item.event_datetime}</p>
                                              }
                                            </div>
                                          </Button>
                                        </Dropdown.Item>
                                      </React.Fragment>
                                    );
                                    })}
                                  </DropdownMenuEvents>
                                </Dropdown>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      }
                    </div>
                  </div>
                </React.Fragment>
              );
            })
          }
        </div>
      </React.Fragment>
    );

  // Mapのオプションを設定
  const createMapOptions = (maps: Maps): MapOptions => {
    return {
      mapTypeControlOptions: {
        position: maps.ControlPosition.TOP_RIGHT,
      },
      mapTypeControl: true,
      zoomControl: true,
      scaleControl: true,
      streetViewControl: true,
      fullscreenControl: true,
      clickableIcons: true,
      // 地図の最小Zoom
      minZoom: 5,
      // 地図の最大Zoom
      maxZoom: 21
    };
  };

  const apiLoaded = (map: any, maps: any) => {

    // map_searchをクリックしたら、Geocoding処理が走り、次の処理へ移行（非同期処理）
    document.getElementById("map_search")?.addEventListener("click", function() {
      // 位置検索処理 非同期処理なのでafterResults内で位置更新処理は行うこと
      geocodeAddress(afterResults);
    });

    // map_resetをクリックしたら、位置のリセット処理が走る
    document.getElementById("map_reset")?.addEventListener("click", function() {
      // 位置リセット処理
      mapReset();
    });
  };

  // リセットボタン押下時処理
  function mapReset(){
    // 地図の中心を再設定する
    // 対象の車両IDの地図情報を取得しなおす
    let targetVehicleId = mainVehicleId;
    // クエリストリングがある場合
    if(queryParams.current["vehicleId"] !== ""){
      // クエリストリングが不正でないかチェック
      const isVehicleData = vehicle_infos.current.find((v: { vehicle_id: number; }) => v.vehicle_id === parseInt(queryParams.current["vehicleId"]));
      // 対象車両情報が存在していた場合
      if(isVehicleData){
        targetVehicleId = parseInt(queryParams.current["vehicleId"]);
      }
      // 存在していなかった場合は、mainVehicleIdを使う
    }
    // クエリストリングの対象車両が不正の場合
    const result = vehicle_infos.current.find((v: { vehicle_id: number; }) => v.vehicle_id === targetVehicleId);
    // 緯度経度がnullでなければ、地図の中心を再設定する
    if(result.lat && result.lon) {
      setMapFocus({...mapFocus, lat: result.lat, lng: result.lon});
    }
  }

  // 入力された住所から緯度経度を取得する
  function geocodeAddress(callback: { (geoCodeResults: any): void; (arg0: { lat: number; lng: number; }): void; })
  {
    var geocoder = new google.maps.Geocoder();
    const address = document.getElementById("address") as HTMLInputElement;
    // 検索欄に入力された値を基に、geocodeを使用して緯度経度を取得する
    geocoder.geocode({"address": address.value}, function(results, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        callback({"lat": results[0].geometry.location.lat(), "lng": results[0].geometry.location.lng()});
      } else {
        console.log("Geocode was not successful for the following reason: " + status);
      }
    });
  }

  // 位置情報取得処理（Geocode）実行後
  function afterResults(geoCodeResults: any){
    // 地図の中心を設定する
    setMapFocus({...mapFocus, lat: geoCodeResults.lat, lng: geoCodeResults.lng});
  }

  // アイコン押下時の吹き出し
  const IconInfo = ({lat, lng, event_id, snapshot_mng_id, iconTexts}: {lat: number, lng: number, event_id: number, snapshot_mng_id: number, iconTexts: string[]}) => (
    <div style={{
      position: "absolute",
      width: 300,
      transform: "translate(-50%, -115%)",
    }}>
      <div style={{
        padding: "15px",
        backgroundColor: "#FFFFFF",
        wordBreak: "normal",
        fontSize: 14,
        boxShadow: "0 2px 7px 1px rgba(0,0,0,0.3)",
        borderRadius: 8,
      }}>
        {
          // 一行ずつ表示する
          iconTexts.map((item: any, index) => {
            return (
              <React.Fragment key={`icon-text-line-${index}`}>
                {
                  snapshot_mng_id && index === 0
                    ? <Link to={`${MOVIE_PLAYBACK.path}?source=06&type=snapshot&mng_ids=${snapshot_mng_id}&main_mng_id_num=0&event_id=${event_id}`} target="_blank" rel="noopener noreferrer">
                      <div>{item}</div>
                    </Link>
                    : <div>{item}</div>
                }
              </React.Fragment>
            );
          })
        }
        <div style={{
          position: "absolute",
          width: 0,
          height: 0,
          top: "100%",
          left: "50%",
          transform: "translate(-50%, 0)",
          borderStyle: "solid",
          borderWidth: "10px 10px 0px 10px",
          borderColor: "#FFFFFF transparent transparent transparent",
        }}>
        </div>
      </div>
    </div>
  );

  // 危険挙動アイコンを押下したときに発生するイベント
  const changeBalloon = (key: string) => {
    const keyNumber = Number(key);
    // 対象の危険挙動情報をポップアップ表示する
    if (currentKey === keyNumber) {
      setCurrentKey(-1);
    } else {
      setCurrentKey(keyNumber);
    }
  };

  // 検索候補一覧を更新する
  const handleAutoCompleteUpdate = (searchValue: any, callBack: { (dataSource: React.SetStateAction<never[]>): void; (arg0: any): void; }) => {
    const searchQuery = {
      input: searchValue,
    };
    var autoCompleteService = new google.maps.places.AutocompleteService();
    // 入力があった場合、google autoCompleteServiceを使用して検索する
    searchQuery.input &&
      autoCompleteService.getQueryPredictions(searchQuery, (response) => {
        // The name of each GoogleMaps place suggestion is in the "description" field
        if (response) {
          const dataSource = response.map((resp) => resp.description);
          // 取得した補完一覧をセットする
          callBack(dataSource);
        }
      });
  };

  // 補完機能
  const handleChange = (e: { target: { value: any; }; }) => {
    handleAutoCompleteUpdate(e.target.value, (dataSource: React.SetStateAction<never[]>) =>
      setAutoSrc(dataSource)
    );
  };

  // 地図検索ボタン
  // const mapSearchButton
  //   = <Button id="map_search" variant="dark" className="mr-2 mb-2">
  //     <img width="30" height="30" src={search_icon} alt="検索" /></Button>;

  // 地図リセットボタン
  // const mapResetButton
  //   = <Button id="map_reset" variant="dark" className="mr-2 mb-2">
  //     <img width="30" height="30" src={reset_icon} alt="リセット" /></Button>;

  // 検索ボックス内でEnterキーを押下した際に位置検索処理を実施する
  const keyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    // 位置検索処理 非同期処理なのでafterResults内で位置更新処理は行うこと
    geocodeAddress(afterResults);
  };

  // 地図パネル
  const map = !vehicleData
    ? null
    : vehicleData.vehicle_infos.length > 0 && !mapData
      ? null
    // 補完後データがあり、リクエストパラメータ用のデータがない場合、走行軌跡を表示する
      : vehicleData.vehicle_infos.length > 0
        ? (
          <GoogleMapWrapper>
            {/* 検索ボタン */}
            {/* {mapSearchButton} */}
            {/* 位置リセットボタン */}
            {/* {mapResetButton} */}
            <GoogleMapReact
              bootstrapURLKeys={{
                key: google_map_key,
                libraries: "places",
              }}
              // defaultCenterだと更新できないよと怒られるのでcenterを使う
              center={{
                lat: mapFocus.lat,
                lng: mapFocus.lng
              }}
              // この値を変更するときは、diffLat,diffLonの初期値も修正すること
              defaultZoom={15}
              options={createMapOptions}
              onGoogleApiLoaded={({ map, maps }) => apiLoaded(map, maps)}
              yesIWantToUseGoogleMapApiInternals={true}
              onChildClick={(key: string) => changeBalloon(key)}
            >
              {/* 以下で地図上に表示するアイコンを定義する(アイコンが重なる場合、後に定義したものが前面に表示される) */}
              {
                // 危険挙動アイコンを表示する
                create_pins_event().map((pin: {
                      lat: number,
                      lng: number,
                      type: string,
                      heading: number,
                      datetime: string,
                      vehicle_name: string,
                      vehicle_number: string,
                      driver_name: string,
                      event_name: string,
                      event_id: number,
                      snapshot_mng_id: number
                    }, index: number) => (
                  <Pin key={index}
                    lat={pin.lat}
                    lng={pin.lng}
                  >
                    <Logo src={danger_icon} />
                  </Pin>
                ))
              }
              {
                // 車両位置アイコンを表示する
                create_pins().map((pin: {
                      lat: number,
                      lng: number,
                      heading: number
                    }, index: number) => (
                  <Pin key={"vehicle_" + index}
                    lat={pin.lat}
                    lng={pin.lng}
                  >
                    {
                      mapData.heading === "stopped"
                        ? <Logo src={stopped_icon} />
                        : <Logo theme={{main: pin.heading}} src={runninng_icon} />
                    }
                  </Pin>
                ))
              }
              {
                // 危険挙動アイコン押下時の吹き出しを表示する
                // (create_pins_event関数を使用しているが、吹き出し用の関数を別途定義してもよい)
                create_pins_event().map((pin: {
                      lat: number,
                      lng: number,
                      type: string,
                      heading: number,
                      datetime: string,
                      vehicle_name: string,
                      vehicle_number: string,
                      driver_name: string,
                      event_name: string,
                      event_id: number,
                      snapshot_mng_id: number
                    }, index: number) => (
                  <Pin key={index}
                    lat={pin.lat}
                    lng={pin.lng}
                  >
                    {
                      index === currentKey &&
                    <div style={{width: "100px"}}>
                      <IconInfo
                        key={index}
                        lat={pin.lat}
                        lng={pin.lng}
                        event_id={pin.event_id}
                        snapshot_mng_id={pin.snapshot_mng_id}
                        iconTexts={[`発生日時：${pin.datetime}`,`車両名：${pin.vehicle_name}`,`ナンバー：${pin.vehicle_number ? pin.vehicle_number : ""}`,`ドライバー：${pin.driver_name}`,`危険挙動種別：${pin.event_name}`]}
                      />
                    </div>
                    }
                  </Pin>
                ))
              }
            </GoogleMapReact>
            {/* GoogleMaps placeを使用して入力補完をする */}
            <Autocomplete
              id="address"
              style={{
                position: "absolute",
                backgroundColor: "white",
                width: 300,
                top: "15px",
                left: "30px",
              }}
              options={autoSrc}
              onKeyPress={(e) => keyPress(e)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Location'
                  variant='outlined'
                  //fullWidth
                  placeholder='Search...'
                  onChange={handleChange}
                  size='small'
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </GoogleMapWrapper>
        )
        : null;

  // 地図上に表示させる車両位置アイコンを作成する
  function create_pins(){
    const pins: {
          lat: number,
          lng: number,
          heading: number
        }[] = [
        ];
    // 車両の緯度経度のいずれかがnullの場合は地図上に表示できないので除外する
    if(mapData.lat && mapData.lon) {
      pins.push(
        {
          // 位置情報はzoomレベルに応じて補正する
          lat: mapData.lat,
          lng: mapData.lon,
          heading: mapData.heading
        }
      );
    }
    return pins;
  }

  // 地図上に表示させるアイコンの一覧を作成する
  function create_pins_event(){

    const pins: {
          lat: number,
          lng: number,
          type: string,
          heading: number,
          datetime: string,
          vehicle_name: string,
          vehicle_number: string,
          driver_name: string,
          event_name: string,
          event_id: number,
          snapshot_mng_id: number
        }[] = [
        ];

    if("events" in mapData){
      JSON.parse(JSON.stringify(mapData.events)).forEach(function(item: any, index: number, self: any){
        // 危険挙動の緯度経度のいずれかがnullの場合は地図上に表示させない（表示出来ないので除外する）
        if(item.event_lat && item.event_lon){
          // 発生日時が同じイベントは、1つのアイコンにまとめて表示する
          if(index !== (self.length - 1) && item.event_datetime === self[index + 1].event_datetime){
            self[index + 1].event_name = item.event_name + "／" + self[index + 1].event_name;
          } else{
            // 危険挙動アイコンの表示位置はZoomLevelに応じて補正する
            pins.push(
              {
                lat: item.event_lat,
                lng: item.event_lon,
                type: item.event_type,
                heading: item.heading,
                datetime: item.event_datetime,
                vehicle_name: mapData.vehicle_name,
                vehicle_number: mapData.vehicle_number,
                driver_name: mapData.driver_name,
                event_name: item.event_name,
                event_id: item.event_id,
                snapshot_mng_id: item.snapshot_mng_id
              }
            );
          }
        }
      });
    }
    return pins;
  }

  // エラーメッセージの生成
  const alert = errorMessage != null
    ? <Alert variant="danger" className="mt-2" dismissible onClose={e => setErrorMessage(null)}><strong>エラー：</strong>{errorMessage}</Alert>
    : null;

  // 表示対象となる車両情報が存在しない場合に表示させるエラーメッセージの生成
  const searchAlert = noVehicleInfoErrorMessage != null
    ? <Alert variant="danger" className="mt-2" dismissible onClose={e => setNoVehicleInfoErrorMessage(null)}><strong>エラー：</strong>{noVehicleInfoErrorMessage}</Alert>
    : null;

  return (
    <div className="mt-5 ml-5 mr-5">
      {alert}
      {searchAlert}
      <h2 className="">{TRACKING_MANAGEMENT.title}</h2>
      <Container fluid>
        <Row>
          <Col className="col-sm-3 border border-top-0 border-left-0 border-bottom-0" >
            {condition}
            {vehicleList}
          </Col>
          <Col className="col-sm-9">
            {map}
          </Col>
        </Row>
      </Container>

    </div>
  );
};