import React from "react";
import { Form } from "react-bootstrap";

export interface TextInputGroupProps {
  id: string;
  label: string;
  type: string;
  placeholder: string;
  value: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  testid?: string;
  disabled?: boolean;
  error?: string | null;
}

const TextInputGroup = (props: TextInputGroupProps) => {
  return (
    <Form.Group controlId={props.id}>
      <Form.Label>{props.label}</Form.Label>
      <Form.Control
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        disabled={props.disabled}
        data-testid={props.testid}
        isInvalid={props.error !== null}
      />
      {props.error && (
        <Form.Control.Feedback type="invalid">
          {props.error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default TextInputGroup;
