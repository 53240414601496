import { AxiosRequestConfig } from "axios";
import { MyRadioInline, MyRow, MyRowMail, MySelect } from "components/parts/InputRow";
import { ENDPOINT } from "constants/endpoint";
import { m } from "constants/message";
import { useUserInfoContext } from "contexts/userInfoContext";
import jwt from "jsonwebtoken";
import { SESSION_TIMEOUT, USER_LIST, USER_REGISTER } from "pages/pageInfo";
import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import { request } from "util/request";

interface State {
  "loginId": string,
  "roleId": string,
  "groupName": string,
  "userName": string,
  "userNameKana": string,
  "mail": string,
  "receiveEventMail": string,
  "receiveEventMailMessage": string,
}

interface RegisteredInfoState {
  "login_id": string,
  "password": string,
}

export const UserRegister: React.FC<any> = (props) =>{
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [validated, setValidated] = useState(false);
  const [state, setState] = useState<State>({
    "loginId": "",
    "roleId": "",
    "groupName": "",
    "userName": "",
    "userNameKana": "",
    "mail": "",
    "receiveEventMail": "",
    "receiveEventMailMessage": "",
  });
  const { signOut, } = useUserInfoContext();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [registerSucceed, setRegisterSucceed] = useState<boolean>(false);
  const [registeredInfo, setRegisteredInfo] = useState<RegisteredInfoState>({
    "login_id": "",
    "password": "",
  });

  useEffect(()=>{
    document.title = USER_REGISTER.title;
  });

  let isManager = false;

  // submitボタンのハンドラ
  const handleSubmit = async (event: React.MouseEvent<HTMLInputElement>) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    // 入力チェック
    if (form.checkValidity() === false || (isManager === true && state["receiveEventMail"] === "")) {
      const rem = state["receiveEventMail"] === "" ? m("V0013") : "";
      setState({...state, receiveEventMailMessage: rem});
      await setValidated(true);
      return;
    }

    // 危険挙動メール受信設定がドライバーの場合と空の場合は0に設定
    if(isManager === false || state["receiveEventMail"] === "") {
      state["receiveEventMail"] = "0";
    }

    setIsRequesting(true); // APIの二重コール防止用
    setErrorMessage(null);

    // POSTリクエスト
    const config: AxiosRequestConfig = {
      method: "post",
      data: {
        login_id: state["loginId"],
        role_id: state["roleId"],
        group_name: state["groupName"] ? state["groupName"] : null,
        user_name: state["userName"],
        user_name_kana: state["userNameKana"] ? state["userNameKana"] : null,
        mail: state["mail"],
        receive_event_mail: parseInt(state["receiveEventMail"]),
      },
    };

    await request(ENDPOINT.USER_REGISTER, config, setErrorMessage)
      .then((res: any) =>{ setRegisterSucceed(true); setRegisteredInfo({login_id: res.data.login_id, password: res.data.password, });})
      .catch((err: any)=>{
        // トークン不正時、サインアウト処理してreturn ※サインアウト処理の中でタイムアウト画面へ遷移させる
        if(err instanceof jwt.JsonWebTokenError){ signOut(SESSION_TIMEOUT.path); return; }
        setIsRequesting(false);
        return;
      }
      );
  };

  // 登録完了メッセージの生成
  const registerd = <Alert variant="success" className="mt-2"><strong>成功：</strong>利用者情報を登録しました。</Alert>;


  // 新規登録に成功している場合、ログインIDとパスワードを出して画面表示を終了する。
  if(registerSucceed){

    return (
      <>
        <div style={{margin: "5vh 15% 0vh 15%"}}>
          {registerd}<br></br>
          <div style={{margin: "12px 20px 50px 20px"}}>
            <h5>ログインID</h5>
            <h4>{registeredInfo.login_id}</h4><br></br>
            <h5>パスワード</h5>
            <h4>{registeredInfo.password}</h4>
            <p className="mt-2" style={{color: "red"}}><strong>※パスワードは今後確認できませんのでご注意ください。</strong></p>
          </div>
          <div className="text-center">
            <Button size="lg" variant="outline-primary" href={USER_LIST.path}>利用者一覧画面へ</Button>
          </div>
        </div>
      </>
    );
  }

  // 入力欄に入力した時のハンドラ
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, target: string) => {
    // 空文字列はnullに変換する
    setState({...state, [target]: event.target.value});
  };

  // ログインID
  const loginId = {
    label: "*ログインID",
    attrName: "loginId",
    pattern: "^[a-zA-Z0-9_-]{1,32}$",
    required: true,
    value: state.loginId,
    maxLength: 32,
    md: "8",
    message: m("V0007") + m("V0010", "32"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "loginId");},
  };

  // 権限リストの生成
  const roles = [{id: "manager", name: "企業管理者"}, {id: "driver", name: "ドライバー"},];
  const rolesDom = roles.map( item => {
    return <option key={item.id} value={item.id}>{item.name}</option>;
  });

  // 権限
  const roleId = {
    label: "*権限",
    attrName: "roleId",
    required: true,
    value: state.roleId,
    listDom: rolesDom,
    message: m("V0007"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "roleId");},
  };

  // 権限でマネージャーが選択された場合
  if (roleId.value === "manager"){
    isManager = true;
  } else {
    state["receiveEventMail"] = "";
  }

  // 組織名
  const groupName = {
    label: "組織名",
    attrName: "groupName",
    pattern: "^.{1,32}$",
    required: false,
    value: state.groupName,
    maxLength: 32,
    md: "5",
    message: m("V0008") + m("V0009", "32"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "groupName");},
  };

  // 利用者名
  const userName = {
    label: "*利用者名",
    attrName: "userName",
    pattern: "^.{1,32}$",
    required: true,
    value: state.userName,
    maxLength: 32,
    md: "5",
    message: m("V0007") + m("V0009", "32"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "userName");},
  };

  // 利用者名（カナ）
  const userNameKana = {
    label: "利用者名（カナ）",
    attrName: "userNameKana",
    pattern: "^.{1,64}$",
    required: false,
    value: state.userNameKana,
    maxLength: 64,
    md: "8",
    message: m("V0008") + m("V0009", "64"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "userNameKana");},
  };

  // メールアドレス
  const mail = {
    label: "*メールアドレス",
    attrName: "mail",
    required: true,
    value: state.mail,
    maxLength: 256,
    md: "12",
    message: m("V0007") + m("V0011", "256"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "mail");},
  };

  // 危険挙動メール受信設定
  const receiveEventMail = {
    label: "*危険挙動メール受信設定",
    className: "mx-0 pb-3 mt-2",
    itemLabels: ["する", "しない"],
    attrName: "receiveEventMail",
    values: ["1", "0"],
    disabled: Array(2).fill(isRequesting),
    messageClassName: "mx-0 pb-3 small text-danger",
    message: state.receiveEventMailMessage,
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>{handleOnChange(e, "receiveEventMail");},
  };

  // エラーメッセージの生成
  const alert = errorMessage != null
    ? <Alert variant="danger" className="mt-2" dismissible onClose={e => setErrorMessage(null)}><strong>エラー：</strong>{errorMessage}</Alert>
    : null;

  // 登録ボタンの表示
  const registerButton = !isRequesting
    ? "登録"
    : <Spinner style={{width: "2rem", height: "2rem"}} as="span" animation="border" size="sm" role="status" aria-hidden="true" />;


  return (
    <>
      <div className="mt-5 ml-5 mr-5">
        <div className="d-flex">
          <h2 className="">{USER_REGISTER.title}</h2>
          <Button size="lg" variant="outline-primary" className="ml-auto" href={USER_LIST.path}>戻る</Button>
        </div>
        {alert}
        <Form noValidate validated={validated} onSubmit={handleSubmit}>

          <div className="text-danger my-3">*は必須入力</div>

          {/* ログインID */}
          <MyRow {...loginId}></MyRow>

          {/* 権限 */}
          <MySelect {...roleId}></MySelect>

          {/* 組織名 */}
          <MyRow {...groupName}></MyRow>

          {/* 利用者名 */}
          <MyRow {...userName}></MyRow>

          {/* 利用者名（カナ） */}
          <MyRow {...userNameKana}></MyRow>

          {/* メールアドレス */}
          <MyRowMail {...mail}></MyRowMail>

          {/* 危険挙動メール受信設定 */}
          <div>
            {isManager && <MyRadioInline {...receiveEventMail}></MyRadioInline>}
          </div>

          <Button type="submit" className="d-block my-4 mr-auto px-5" disabled={isRequesting} size="lg">
            {registerButton}
          </Button>
        </Form>
      </div>
    </>
  );
};