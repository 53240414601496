import React from "react";
import { Timelapse } from "react-timelapse";
import { ImageProps } from "react-timelapse/component/Timelapse";

interface TimelapseProps {
  width: number
  images: ImageProps[]
  timelapseHandle: boolean | null;
  preloadedCallback: () => void;
}

export const TimelapsePanel: React.FC<TimelapseProps> = (props) => {
  return (
    <Timelapse
      fps={7} // 描画する速さを指定
      // width={props.width} // 描画ウィンドウの幅
      height={props.width * 320 / 480} // 描画ウィンドウの高さ
      {...props}
    />
  );
};

export default TimelapsePanel;