import { Auth } from "aws-amplify";
import { MyRow, MySimpleCheckBox } from "components/parts/InputRow";
import { m } from "constants/message";
import { useUserInfoContext } from "contexts/userInfoContext";
import { Map } from "immutable";
import { PageInfoMap } from "interfaces/pageInfoInterface";
import { User, UserRole } from "interfaces/userInfoInterface";
import { COMPANY_LIST, MENU, SIGN_IN, TRACKING_MANAGEMENT } from "pages/pageInfo";
import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";

export const signInToCognito = async (
  email: string,
  password: string
): Promise<User> => {
  return await Auth.signIn(email, password);
};

const LOCAL_STORAGE_USER_ID = "userid";

export const SignIn = () => {
  const signInMap: PageInfoMap = Map(SIGN_IN);

  const history = useHistory();
  const { updateUserInfo,getUserRole } = useUserInfoContext();

  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [userId, setUserId] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [checkSaveUserId, setCheckSaveUserId] = useState<boolean>(false);

  useEffect(() => {
    document.title = signInMap.get("title");

    // 認証済みの場合、トップページへ遷移させる
    if(getUserRole() !== "unauthorized"){
      history.push(MENU.path); }
  });

  // "ログインＩＤを保存する"を反映させる用
  useEffect(() => {
    const _userid = localStorage.getItem(LOCAL_STORAGE_USER_ID);

    // localStorageに項目が登録されていればチェック入れる
    if(_userid !== null){
      setCheckSaveUserId(true);
      setUserId(_userid);
    }
  }, []);

  // submitボタンのハンドラ
  const handleSubmit = async (event: React.MouseEvent<HTMLInputElement>) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if(checkSaveUserId){
      localStorage.setItem(LOCAL_STORAGE_USER_ID, userId);
    } else {
      localStorage.removeItem(LOCAL_STORAGE_USER_ID);
    }

    if (form.checkValidity() === false) {
      await setValidated(true);
      return;
    }

    setIsRequesting(true); // APIの二重コール防止用
    setErrorMessage(null);

    try {
      const user: any = await signInToCognito(userId, password);

      // NEW_PASSWORD_REQUIREDが返ってくることはあり得ないが（ユーザの登録APIの中でCONFIRMEDに変えるため）
      // 一応、防御用のコードとして残しておく
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        updateUserInfo({
          userRole: "unauthorized",
          authStatus: "NEW_PASSWORD_REQUIRED",
          companyId: "",
          email: "",
          user: user,
        });
        history.push("/changepassword");

      } else {
        // サインイン成功時の値の詰め込み等はPrivateRouteでやる
        // ※システム管理者以外は動態管理画面をトップにする
        const role: UserRole = user.attributes["custom:role"];
        if(role === "admin") {
          history.push(COMPANY_LIST.path);
        } else {
          history.push(TRACKING_MANAGEMENT.path);
        }
      }

    } catch(err){
      setIsRequesting(false);

      const errs = [
        {
          name: "UserNotFoundException",
          message: "User does not exist.",
          display: m("M0001")
        },
        {
          name: "NotAuthorizedException",
          message: "Incorrect username or password.",
          display: m("M0001")
        },
        {
          name: "UserLambdaValidationException",
          message: "PostAuthentication failed with error user expired.",
          display: m("M0002")
        },
        {
          name: "NotAuthorizedException",
          message: "User is disabled.",
          display: m("M0002")
        },
      ];

      let match = false;

      // 想定しているエラーと一致している場合、該当のエラーメッセージを表示
      errs.forEach(item => {
        if(item.name === err.name && item.message === err.message ){
          match = true;
          setErrorMessage(item.display);
        }
      });

      // 想定しているエラーと一致しない場合、汎用エラーメッセージ
      if(!match){
        setErrorMessage(m("M9999"));
      }
    }
  };

  const alert = errorMessage != null
    ? (
      <Alert variant="danger" className="mt-2" dismissible onClose={e => setErrorMessage(null)}>
        {errorMessage}
      </Alert>
    )
    : null;

  // ログインＩＤ
  const userIdDomSetting = {
    label: "ログインＩＤ",
    attrName: "userId",
    placeholder: "",
    pattern: ".+",
    required: true,
    value: userId,
    maxLength: 32,
    md: null,
    message: m("V0007"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => setUserId(e.target.value),
  };

  // パスワード
  const passwordDomSetting = {
    type: "password",
    label: "パスワード",
    attrName: "password",
    placeholder: "",
    pattern: ".+",
    required: true,
    value: password,
    maxLength: 32,
    md: null,
    message: m("V0007"),
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value),
  };

  // ログインＩＤを保存するチェック
  const saveUserIdSetting = {
    id: "saveUserId",
    inline: true,
    label: "ログインＩＤを保存する",
    checked: checkSaveUserId,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => setCheckSaveUserId(e.target.checked),
  };

  const loginButton = !isRequesting
    ? "ログイン"
    : <Spinner style={{width: "1.5rem", height: "1.5rem"}} as="span" animation="border" size="sm" role="status" aria-hidden="true" />;

  return (
    <Form className="form-signin" noValidate validated={validated} onSubmit={handleSubmit}>

      <div>
        <img className="mb-4" src="/img/logo/elematec_logo.png" alt="logo" width="200"></img>
      </div>

      <div>
        <img className="mb-4" src="/img/logo/telemakun_logo.png" alt="logo" width="300"></img>
      </div>

      {alert}

      {/* ログインＩＤ */}
      <div className="mx-auto" style={{textAlign: "left", width: "330px"}}> {/* text-alignはバリデートエラーメッセージを左詰めする用 */}
        <MyRow {...userIdDomSetting}></MyRow>
      </div>

      {/* パスワード */}
      <div className="mx-auto" style={{textAlign: "left", width: "330px"}}> {/* text-alignはバリデートエラーメッセージを左詰めする用 */}
        <MyRow {...passwordDomSetting}></MyRow>
      </div>

      <MySimpleCheckBox {...saveUserIdSetting} />

      <div className="mx-auto" style={{width: "150px"}}>
        <Button variant="primary" type="submit" data-testid="button-submit" className="my-4" size="lg" block={true} disabled={isRequesting}>
          {loginButton}
        </Button>
      </div>

      <Link to="/request_new_password">パスワードをお忘れですか？</Link>
    </Form>
  );
};
