import { Auth } from "aws-amplify";
import { useUserInfoContext } from "contexts/userInfoContext";
import { PageInfo } from "interfaces/pageInfoInterface";
import { UserRole } from "interfaces/userInfoInterface";
import * as pg from "pages/pageInfo";
import React, { useEffect, useState } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { checkIdToken, generateJwkPemForIdToken, getIdTokenFromLocalStorage } from "util/idtoken";

export interface PrivateRouteProps extends RouteProps {
  pageInfo: PageInfo;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [timeouted, setTimeouted] = useState(false);
  const [currentRole, setCurrentRole] = useState<UserRole>("unauthorized");
  const {
    hasBeenAuthorized,
    getUserRole,
    updateUserInfo,
  } = useUserInfoContext();


  useEffect(() => {
    // LocalStorageからユーザ情報取得が終わっている場合、何もしない
    if(!isLoading || timeouted){
      return;
    }

    // コンポーネント削除後にState更新をしようとしているのでワーニングが出る。回避するには、クリーンアップ系の処理を入れる。
    // LocalStorageから読み込むユーザ情報がReact内に既に存在している場合
    if (hasBeenAuthorized()) {
      setIsLoading(false);
      setCurrentRole(getUserRole());

    } else {
      // LocalStorageからユーザ情報取得
      (async () => {
        try{
          // IDトークンチェック用にLocalStorageからIDトークンを取得する
          let idToken = getIdTokenFromLocalStorage();
          // ↓期限切れのIDトークンのサンプル
          // localStorage.setItem("CognitoIdentityServiceProvider.6ulb68g23e0c3aidv2hqbbuvdo.api-test.idToken", "eyJraWQiOiJVcUhseVh4cFZHb3pCQnNlNVUrNFZUYlBiOEtkanNyMCtvaURseWg1NjE0PSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyMTMzNjA0MS1iNjFhLTQ2MmEtOGZiNS04NGU2ZWFkOTIwOGQiLCJhdWQiOiJwdHRlbTU2ZDNjZTJ2Mmo1ZzJsM3ZmYThwIiwiZXZlbnRfaWQiOiI0YjYxNGQzMC01NGUzLTQ1YmEtOGI2OS1hMzQ2MDI3NTU0NTYiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTYwMzI2MTMwMSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmFwLW5vcnRoZWFzdC0xLmFtYXpvbmF3cy5jb21cL2FwLW5vcnRoZWFzdC0xX3VSSkk0M1VSUyIsImNvZ25pdG86dXNlcm5hbWUiOiJhcGktdGVzdCIsImV4cCI6MTYwMzI3MzE5MywiY3VzdG9tOnJvbGUiOiJhZG1pbiIsImlhdCI6MTYwMzI2OTU5MywiY3VzdG9tOmNvbXBhbnlfaWQiOiIxIn0.GDtz_q0Zf791CLM2YD6bbI0x_19mT7IGyY8pfef6UsEph1QFSBCO0iQ93NbsVUyg3716RY1E4CaZHJB3V5RK-yRCN8_5WuXR0BjhiXIgnrj8yRA6FYb41asr8fBb2CtvpfoVf_2JJoFthjIBRHRd1QIrdjOtIP5xmmm2IGNUdhKUHCrGHWtWhM-6eNia-eIZXjXVCBYbTJoPkZ6Rlg7ul2IxFQPOdbKCbvwiyXS_NjJPh9AI7BthYkdIBXVrhxwiR-BfbZDgtXQOY16YldT86qInKXAMzUo_0hEtIC-CAYEV_OQjrFmz5oILTGgGnNiNCrAQ-EBmn40Qd31B6lM5qQ");

          // localStorageからIDトークンとれなかったら未認証
          if(idToken == null){
            setCurrentRole("unauthorized");
            return;
          }

          // IDトークンの検証を実施する
          const jwk = await generateJwkPemForIdToken(idToken);
          const tokenCheckResult = checkIdToken(idToken, jwk.jwk.alg, jwk.pem);

          // 不正なトークン or IDトークンの期限切れの場合、タイムアウト画面へ飛ばす
          if(!tokenCheckResult){
            setTimeouted(true);
            return;
          }

          // IDトークンの期限切れの場合、メソッド（currentUserPoolUser）内で勝手にIDトークンを更新するので、
          // トークンチェックの後にこの辺の処理を行う
          const res = await Auth.currentUserPoolUser();

          // PrivateRoute内のroleを更新する
          setCurrentRole(res.getSignInUserSession()?.getIdToken().payload["custom:role"]);

          // コンテクストの更新
          updateUserInfo({
            userRole: res.getSignInUserSession()?.getIdToken().payload["custom:role"],
            authStatus: "SIGNED_IN",
            companyId: res.getSignInUserSession()?.getIdToken().payload["custom:companyId"],
            email: res.getSignInUserSession()?.getIdToken().payload["email"],
            user: res,
          });

        } catch(e){
          setCurrentRole("unauthorized");

        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [isLoading, timeouted, hasBeenAuthorized, getUserRole, updateUserInfo]);

  // LocalStorageからトークンの読み込みが終わるまで待機する
  if (isLoading) {
    return <React.Fragment />;

  } else {
    // 不正なトークン or IDトークンの期限切れの場合、タイムアウト画面へ飛ばす
    // タイムアウト画面側でサインアウト処理する
    if(timeouted){
      return <Redirect to={{ pathname: pg.SESSION_TIMEOUT.path }} />;
    }

    return props.pageInfo.allowedRoles.includes(currentRole) ? (
      <Route {...props} component={props.component} render={undefined} />
    ) : (
      <Redirect to={{ pathname: pg.SIGN_IN.path }} />
    );
  }
};