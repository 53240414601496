import ja from "date-fns/locale/ja";
import React from "react";
import { Col, Form, FormCheckProps } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface MyRowProps {
    type?: string,
    label: any,
    attrName: string,
    pattern: string,
    required: boolean,
    value: string,
    maxLength: number,
    md: string | null,
    message: string,
    placeholder?: string,
    disabled?: boolean | null,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  }

export const MyRow: React.FC<MyRowProps> = (props) => {
  let myRowItem: any = {
    type: "text",
    pattern: props.pattern,
    required: props.required,
    value: props.value,
    maxLength: props.maxLength,
    placeholder: props.message,
    onChange: props.onChange,
  };

  if (props.type !== undefined && props.type !== null) {
    myRowItem.type = props.type;
  }
  if (props.disabled !== undefined && props.disabled !== null) {
    myRowItem.disabled = props.disabled;
  }
  if (props.placeholder !== undefined && props.placeholder !== null) {
    myRowItem.placeholder = props.placeholder;
  }

  return (
    <Form.Row>
      <Form.Group as={Col} md={props.md} controlId={props.attrName}>
        <Form.Label>{props.label}</Form.Label>
        <Form.Control
          {...myRowItem}
        />
        <Form.Control.Feedback type="invalid">{props.message}</Form.Control.Feedback>
      </Form.Group>
    </Form.Row>
  );
};

interface MySelectProps {
  label: any,
  attrName: string,
  required: boolean,
  disabled?: boolean | null,
  value: string,
  listDom: any,
  message: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

export const MySelect: React.FC<MySelectProps> = (props) => {
  let mySelectItem: any = {
    as: "select",
    required: props.required,
    onChange: props.onChange,
    defaultValue: props.value,
  };
  if (props.disabled !== undefined && props.disabled !== null) {
    mySelectItem.disabled = props.disabled;
  }
  return (
    <Form.Row className="mx-0">
      <Form.Group controlId={props.attrName}>
        <Form.Label>{props.label}</Form.Label>
        <Form.Control
          custom
          {...mySelectItem}
        >
          <option value=""></option>
          {props.listDom}
        </Form.Control>
        <Form.Control.Feedback type="invalid">{props.message}</Form.Control.Feedback>
      </Form.Group>
    </Form.Row>
  );
};

interface MyRadioInlineProps {
    label: string,
    className: string,
    itemLabels: string[],
    attrName: string,
    checked?: boolean[] | null,
    values: string[],
    disabled: boolean[],
    messageClassName: string,
    message: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  }

export const MyRadioInline: React.FC<MyRadioInlineProps> = (props) => {
  return (
    <React.Fragment>
      <style>{`input[name=${props.attrName}]+label{color:black !important}`}</style>
      {props.label}
      <Form.Row className={props.className}>
        {props.itemLabels.map((itemLabel, index) =>{
          let myRowInlineItem: any = {
            inline: true,
            label: itemLabel,
            type: "radio",
            id: `${props.attrName}-${index}`,
            name: props.attrName,
            value: props.values[index],
            disabled: props.disabled[index],
            onChange: props.onChange,
          };
          if (props.checked !== undefined && props.checked !== null) {
            myRowInlineItem.checked = props.checked[index];
          }
          return (
            <React.Fragment key={`${props.attrName}-${index}`}>
              <Form.Check
                {...myRowInlineItem}
              ></Form.Check>
            </React.Fragment>
          );
        }
        )
        }
      </Form.Row>
      <Form.Row className={props.messageClassName}>{props.message}</Form.Row>
    </React.Fragment>
  );
};

interface MyCheckboxInlineProps {
    label: string,
    className: string,
    itemLabels: string[],
    attrName: string,
    values: string[],
    inline: boolean,
    disabled: boolean[],
    checked: boolean,
    messageClassName: string,
    message: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  }

export const MyCheckboxInline: React.FC<MyCheckboxInlineProps> = (props) => {
  let attribute: { [key: string]: boolean; } = {};
  if (props.checked) { attribute.checked = true; }
  return (
    <React.Fragment>
      <style>{`input[name=${props.attrName}]+label{color:black !important}`}</style>
      {props.label}
      <Form.Row className={props.className}>
        {props.itemLabels.map((itemLabel, index) => (
          <React.Fragment key={`${props.attrName}-${index}`}>
            <Form.Check
              {...attribute}
              inline={true}
              label={itemLabel}
              type="checkbox"
              id={`${props.attrName}-${index}`}
              name={props.attrName}
              value={props.values[index]}
              disabled={props.disabled[index]}
              onChange={props.onChange}
            />
          </React.Fragment>
        ))}
      </Form.Row>
      <Form.Row className={props.messageClassName}>{props.message}</Form.Row>
    </React.Fragment>
  );
};

/** チェックボックスを単品で使いたい人用 */
export const MySimpleCheckBox: React.FC<FormCheckProps> = (props) => {
  return (
    <>
      <style>{`#${props.id}+label{color:black !important}`}</style>
      <Form.Check {...props} />
    </>
  );
};

interface MyDatePickerProps {
  label: string,
  startDate: Date | null,
  dateFormat: string,
  showTimeInput: boolean,
  timeInputLabel: string,
  maxDate: Date,
  isClearable: boolean,
  disabled: boolean,
  message?: string | null,
  messageClassName?: string | null,
  onChange: (date: any) => void,
}

export const MyDatePicker: React.FC<MyDatePickerProps> = (props) => {
  let attribute: { [key: string]: any; } = {};
  if (props.showTimeInput) { attribute.showTimeInput = true; }
  if (props.timeInputLabel !== "") { attribute.timeInputLabel = props.timeInputLabel; }
  registerLocale("ja", ja);
  return (
    <React.Fragment>
      {props.label}
      <Form.Row className="mx-0 mt-2">
        <Form.Group as={Col} md="4">
          <DatePicker
            {...attribute}
            selected={props.startDate}
            onChange={props.onChange}
            dateFormat={props.dateFormat}
            disabled={props.disabled}
            maxDate={props.maxDate}
            isClearable={props.isClearable}
            locale="ja"
          />
        </Form.Group>
      </Form.Row>
      <Form.Row className={props.messageClassName + ""}>{props.message}</Form.Row>
    </React.Fragment>
  );
};

interface MyRowNumberProps {
  label: any,
  attrName: string,
  required: boolean,
  value: string,
  max: number,
  min: number,
  md: string,
  message: string,
  disabled: boolean,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

export const MyRowNumber: React.FC<MyRowNumberProps> = (props) => {
  return (
    <Form.Row>
      <Form.Group as={Col} md={props.md} controlId={props.attrName}>
        <Form.Label>{props.label}</Form.Label>
        <Form.Control
          type="number"
          required={props.required}
          value={props.value}
          max={props.max}
          min={props.min}
          placeholder={props.message}
          disabled={props.disabled}
          onChange={props.onChange}
        />
        <Form.Control.Feedback type="invalid">{props.message}</Form.Control.Feedback>
      </Form.Group>
    </Form.Row>
  );
};

interface MyTextAreaProps {
  label: any,
  attrName: string,
  required: boolean,
  value: string,
  maxLength: number,
  md: string,
  message: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

export const MyTextArea: React.FC<MyTextAreaProps> = (props) => {
  return (
    <Form.Row>
      <Form.Group as={Col} md={props.md} controlId={props.attrName}>
        <Form.Label>{props.label}</Form.Label>
        <Form.Control
          as="textarea"
          required={props.required}
          value={props.value}
          maxLength={props.maxLength}
          placeholder={props.message}
          onChange={props.onChange}
        />
        <Form.Control.Feedback type="invalid">{props.message}</Form.Control.Feedback>
      </Form.Group>
    </Form.Row>
  );
};

interface MyRowMailProps {
  label: any,
  attrName: string,
  required: boolean,
  value: string,
  maxLength: number,
  md: string,
  message: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

export const MyRowMail: React.FC<MyRowMailProps> = (props) => {
  return (
    <Form.Row>
      <Form.Group as={Col} md={props.md} controlId={props.attrName}>
        <Form.Label>{props.label}</Form.Label>
        <Form.Control
          type="email"
          required={props.required}
          value={props.value}
          maxLength={props.maxLength}
          placeholder={props.message}
          onChange={props.onChange}
        />
        <Form.Control.Feedback type="invalid">{props.message}</Form.Control.Feedback>
      </Form.Group>
    </Form.Row>
  );
};