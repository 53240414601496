import { AxiosRequestConfig } from "axios";
import Pagination from "components/parts/Pagination";
import { ENDPOINT } from "constants/endpoint";
import { useUserInfoContext } from "contexts/userInfoContext";
import jwt from "jsonwebtoken";
import { SESSION_TIMEOUT, USER_EDIT, USER_LIST, USER_REGISTER } from "pages/pageInfo";
import React, { useEffect, useState } from "react";
import { Alert, Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { request } from "util/request";

type COLS = "user_id" | "group_name" | "user_name" | "user_name_kana" | "role_id" | "mail" | "receive_event_mail";

export const UserList = () => {
  const { signOut, } = useUserInfoContext();

  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [data, setData] = useState<any>(null); // 初期状態：null、エラー時：undefined とする
  const [activePage, setActivePage] = useState<number>(1);
  const [sortId, setSort] = useState<COLS>("user_id");
  const [order, setOrder] = useState<string>("asc");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const handlePageChange = (page: number) => {
    setActivePage(page);
    setData(null);
  };

  const handleOnClickCol = (e: React.MouseEvent<HTMLInputElement>, col: COLS) => {

    // ソート順の決定
    const _order = sortId !== col // "現ソートカラム != クリックされたカラム" の場合
      ? "asc" // asc 固定
      : order === "desc" // "現ソートカラム == クリックされたカラム" の場合
        ? "asc" // desc だった場合、asc へ
        : "desc"; // asc だった場合、desc へ

    setOrder(_order);
    setSort(col);
    setData(null);
  };

  useEffect(()=>{
    if(sessionStorage.getItem("successMessage")){
      setSuccessMessage(sessionStorage.getItem("successMessage"));
      sessionStorage.removeItem("successMessage");
    }
  }, [successMessage]);

  useEffect(() => {
    document.title = USER_LIST.title;

    // 表示用データが空で、リクエスト中でもない場合
    // リクエストを投げてデータを取得する
    if(data === null && !isRequesting){
      (async ()=>{
        setIsRequesting(true); // APIの二重コール防止用

        // GETリクエスト
        const config: AxiosRequestConfig = {
          method: "post",
          data: { page: activePage, sort_item: sortId, sort_order: order }, // クエリパラメータ
        };

        await request(ENDPOINT.USER_LIST, config, setErrorMessage)
          .then((res: any) =>{ setData(res.data); setIsRequesting(false); })
          .catch((err: any)=>{
            // トークン不正時、サインアウト処理してreturn ※サインアウト処理の中でタイムアウト画面へ遷移させる
            if(err instanceof jwt.JsonWebTokenError){ signOut(SESSION_TIMEOUT.path); return; }

            setData(undefined); // nullにしたいけど、nullにすると無限にリクエストすることになるのでundefined使う
            setIsRequesting(false); // 必ずsetDataの方が先（dataがnullのまま先にisRequestingをfalseにすると2回目のリクエストを投げるので。。）
            return;
          }
          );
      })();
    }
  });

  // ヘッダ部分の生成
  const cols: {name: string, id: COLS}[] = [
    {"name": "ユーザーID", "id": "user_id"},
    {"name": "組織", "id": "group_name"},
    {"name": "名前", "id": "user_name"},
    {"name": "名前（カナ）", "id": "user_name_kana"},
    {"name": "権限", "id": "role_id"},
    {"name": "メールアドレス", "id": "mail"},
    {"name": "危険挙動メール", "id": "receive_event_mail"},
  ];

  const listHeader = cols.map((col, index) => {
    const _style = sortId === col.id ? "btn-primary list-th" : "btn-light list-th"; // クリックされているカラムの場合、色を付ける
    const _order = sortId === col.id ? (order === "asc" ? "▲" : "▼") : ""; // クリックされているカラムの場合、ソート文字を付ける
    const _onclick = !isRequesting // リクエストを投げている間は反応しないようにする
      ? (e: any)=>{handleOnClickCol(e, col.id);}
      : ()=>{};
    const style_for_id = index === 0 ? {width: "120px"} : {}; // ID列だけサイズ指定する
    return <th key={col.id} className={_style} onClick={_onclick} style={style_for_id}>{col.name} {_order}</th>;
  });

  // リスト部分の生成
  const list = !data
    ? (isRequesting
      // レスポンスデータ無しでリクエスト中の場合、Loading表示
      ? <Spinner animation="border" className="mt-4" style={{width: "5rem", height: "5rem"}}/>

      // レスポンスデータ無しでリクエスト中でもない場合、何も表示しない（エラー時用）
      : null
    )
    : ( // レスポンスデータが有る場合はそれを表示する
      <>
        <div className="table-responsive mt-4" >
          <h5>総件数：{data.total}</h5>
          <div className="scroll">
            <table className="table table-striped" style={{tableLayout: "fixed"}}>
              <thead>
                <tr>
                  {listHeader}
                </tr>
              </thead>
              <tbody>
                {
                  data.values.map((item: any)=>{
                    return (
                      <React.Fragment key={item.user_id}>
                        <tr className="list-page-tr">
                          <td><Link to={`${USER_EDIT.path}?user_id=${item.user_id}`}>{item.user_id}</Link></td>
                          <td>{item.group_name}</td>
                          <td>{item.user_name}</td>
                          <td>{item.user_name_kana}</td>
                          <td>{item.role_id}</td>
                          <td>{item.mail}</td>
                          <td>{item.receive_event_mail}</td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </>
    );

  // ページネーション部分の生成（レスポンスデータが無い間は非表示）
  const pagination = !data
    ? null
    : (
      <Pagination
        activePage={data.page}
        itemsCountPerPage={data.count}
        totalItemsCount={data.total}
        pageRangeDisplayed={5}
        onChange={handlePageChange}
      />
    );

  // 登録完了メッセージの生成
  const registerd = successMessage != null
    ? <Alert variant="success" className="mt-2" dismissible onClose={e => setSuccessMessage(null)}><strong>成功：</strong>{successMessage}</Alert>
    : null;

  // エラーメッセージの生成
  const alert = errorMessage != null
    ? <Alert variant="danger" className="mt-2" dismissible onClose={e => setErrorMessage(null)}><strong>エラー：</strong>{errorMessage}</Alert>
    : null;

  return (
    <div className="mt-5 ml-5 mr-5">
      <div className="d-flex">
        <h2 className="">{USER_LIST.title}</h2>
        <Button size="lg" variant="outline-primary" className="ml-auto" href={USER_REGISTER.path}>新規登録</Button>
      </div>

      {registerd}

      {alert}

      {list}

      {pagination}
    </div>
  );
};