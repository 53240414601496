import { useUserInfoContext } from "contexts/userInfoContext";
import { UserRole } from "interfaces/userInfoInterface";
import * as pg from "pages/pageInfo";
import React, { useEffect, useState } from "react";
import { Button, Navbar } from "react-bootstrap";
import { useLocation } from "react-router-dom";

export const Header = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentRole, setCurrentRole] = useState<UserRole>("unauthorized");
  const location = useLocation();
  const {
    getUserRole,
    hasBeenAuthorized,
    signOut,
  } = useUserInfoContext();

  useEffect(() => {
    if (hasBeenAuthorized()) {
      setIsLoading(false);
      setCurrentRole(getUserRole());
    }
  }, [hasBeenAuthorized, getUserRole]);

  // ヘッダ非表示画面の場合、ヘッダを出さない
  const none = [pg.SESSION_TIMEOUT.path,];
  if(none.includes(location.pathname)){
    return null;
  }

  if(getUserRole() === "unauthorized"){
    return null;
  }

  if (isLoading) {
    return <React.Fragment />;
  } else {
    return currentRole === "unauthorized" ? (
      <React.Fragment />
    ) : (
      <>
        <div className="pl-5 pr-5 bg-light">
          <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
            <Navbar.Brand as="img" src="/img/logo/elematec_logo.png" alt="logo" width="150"></Navbar.Brand>
            {(currentRole === "driver" || currentRole === "manager") && (
              <div>
                <Button className="pl-5 pr-5 ml-1 mr-1" href={pg.TRACKING_MANAGEMENT.path}>動態管理</Button>
                <Button className="pl-5 pr-5 ml-1 mr-1" href={pg.HISTORY_MANAGEMENT.path}>履歴管理</Button>
              </div>
            )}
            {currentRole === "manager" && (
              <div>
                <Button className="pl-5 pr-5 ml-1 mr-1" href={pg.USER_LIST.path}>利用者管理</Button>
                <Button className="pl-5 pr-5 ml-1 mr-1" href={pg.VEHICLE_LIST.path}>車両管理</Button>
              </div>
            )}
            {currentRole === "admin" && (
              <div>
                <Button className="pl-5 pr-5 ml-1 mr-1" href={pg.COMPANY_LIST.path}>顧客管理</Button>
                <Button className="pl-5 pr-5 ml-1 mr-1" href={pg.RECORDER_LIST.path}>ドラレコ管理</Button>
              </div>
            )}
            <Button variant="outline-primary" className="pl-5 pr-5 ml-auto" onClick={e=>signOut()}>ログアウト</Button>
          </Navbar>
        </div>
      </>
    );
  }
};