import { Auth } from "aws-amplify";
import { LOCAL_STORAGE } from "constants/localStorage";
import { Map } from "immutable";
import { SIGN_IN } from "pages/pageInfo";
import React, { useContext, useReducer } from "react";
import { useHistory } from "react-router-dom";
import {
  initialUserInfo,
  UserInfo,
  UserInfoMap
} from "../interfaces/userInfoInterface";

export type UserInfoActionType = "UPDATE_USER_INFO" | "SIGN_OUT";

export interface UserInfoAction {
  type: UserInfoActionType;
  payload?: UserInfo;
}

export const userInfoReducer = (
  userInfo: UserInfo,
  action: UserInfoAction
): UserInfo => {
  switch (action.type) {
    case "UPDATE_USER_INFO":
      if (action.payload) {
        return action.payload;
      } else {
        return userInfo;
      }
    case "SIGN_OUT":
      return initialUserInfo;
    default:
      return userInfo;
  }
};

export interface UserInfoContextProps {
  userInfo: UserInfo;
  dispatch: React.Dispatch<UserInfoAction>;
}

export const UserInfoContext = React.createContext<UserInfoContextProps>({
  userInfo: initialUserInfo,
  dispatch: () => {},
});

export const useUserInfoContext = () => {
  const history = useHistory();
  const { userInfo, dispatch } = useContext(UserInfoContext);
  const userInfoMap: UserInfoMap = Map(userInfo);

  const getUserRole = () => userInfoMap.get("userRole");
  const getAuthStatus = () => userInfoMap.get("authStatus");
  const getCompanyId = () => userInfoMap.get("companyId");
  const getUser = () => userInfoMap.get("user");

  const hasBeenAuthorized = () =>
    userInfoMap.get("authStatus") !== "UNAUTHORIZED";

  const updateUserInfo = (newUserInfo: UserInfo) => {
    dispatch({
      type: "UPDATE_USER_INFO",
      payload: newUserInfo,
    });
  };

  const signOut = (redirectPath?: string): void => {
    dispatch({
      type: "SIGN_OUT",
    });
    Auth.signOut();

    // ログインIDが保持されている場合の一時退避
    const _userId = localStorage.getItem(LOCAL_STORAGE.SIGNIN_USER_ID);

    localStorage.clear();

    // ログインIDが保持されている場合、そこだけ復元する
    if(_userId !== null){
      localStorage.setItem(LOCAL_STORAGE.SIGNIN_USER_ID, _userId);
    }
    if(redirectPath){
      history.push(redirectPath);
    }else{
      history.push(SIGN_IN.path);
    }
  };

  return {
    getUserRole,
    getAuthStatus,
    getCompanyId,
    getUser,
    hasBeenAuthorized,
    updateUserInfo,
    signOut,
  };
};

export const UserInfoContextProvider: React.FC = ({ children }) => {
  const [userInfo, dispatch] = useReducer(userInfoReducer, initialUserInfo);
  return (
    <UserInfoContext.Provider value={{ userInfo, dispatch }}>
      {children}
    </UserInfoContext.Provider>
  );
};
