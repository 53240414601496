import React from "react";
import { Button, Modal } from "react-bootstrap";

interface Props {
    show: boolean,
    isRequesting: boolean,
    handleClose: () => void,
    handleYes: (event: React.MouseEvent<HTMLInputElement>) => void,
    yesButtonDom: JSX.Element | string,
    message: string,
}

export const ConfirmDialog: React.FC<Props> = (props) =>{
  return (
    <Modal show={props.show} onHide={props.handleClose} animation={false} centered>
      <Modal.Header closeButton>
        <Modal.Title>確認</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{fontSize: "1rem"}}>{props.message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" style={{width: "100px"}} onClick={e=>{props.handleClose();}} disabled={props.isRequesting} size="lg">
    いいえ
        </Button>
        <Button variant="primary" style={{width: "100px"}} onClick={props.handleYes} disabled={props.isRequesting} size="lg">
          {props.yesButtonDom}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

