import React from "react";
import Pagination, { ReactJsPaginationProps } from "react-js-pagination";

export const MyPagination: React.FC<ReactJsPaginationProps> = (props) =>{

  return (
    <Pagination {...props}
      innerClass="pagination justify-content-center"
      activeClass="page-item active"
      itemClass="page-item"
      linkClass="page-link"
      prevPageText="前"
      nextPageText="次"
      firstPageText="最初"
      lastPageText="最後"
    />
  );
};

export default MyPagination;