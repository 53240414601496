// APIエラー時のデフォルトメッセージ

export type CODE =
| "V0001" | "V0002" | "V0003" | "V0004" | "V0005" | "V0006" | "V0007" | "V0008" | "V0009" | "V0010" | "V0011" | "V0012" | "V0013" | "V0014" | "V0015" | "V0016" | "V0017"
| "M0001" | "M0002" | "M0003" | "M0004" | "M0005" | "M0006"
| "M0100" | "M0101" | "M0102" | "M0103" | "M0104" | "M0105" | "M0200" | "M9999"
| "AM9999"
| "CONFIRM_DELETE"
;

export const MESSAGES: {[key: string]: string} = {

  V0001: "数値で入力してください。",
  V0002: "半角英数字で入力してください。",
  V0003: "記号（{0}）で入力してください。",
  V0004: "パスワードポリシーを確認してください。",
  V0005: "入力されたパスワードと一致していません。",
  V0006: "メールアドレスが不正です。",
  V0007: "必須入力です。",
  V0008: "任意入力です。",
  V0009: "{0}文字以内で入力してください。",
  V0010: "半角英数字と記号（_-）を使用した{0}文字以内で入力してください。",
  V0011: "{0}文字以内のメールアドレス形式で入力してください。",
  V0012: "{0}～{1}の範囲で入力してください。",
  V0013: "いずれかを選択してください。",
  V0014: "1つ以上選択してください。",
  V0015: "{0}文字以内の半角英数字で入力してください。",
  V0016: "{0}文字以内の半角数字またはハイフンで入力してください。",
  V0017: "{0}は、{1}以降の日付を入力してください。",

  M0001: "ユーザーID、またはパスワードが異なっています。",
  M0002: "有効期限が切れています。",
  M0003: "登録が完了しました。",
  M0004: "保存が完了しました。",
  M0005: "削除が完了しました。",
  M0006: "表示するデータがありません。",

  M0100: "動画像取得リクエストを受け付けました。",
  M0101: "端末起動確認中",
  M0102: "端末接続中",
  M0103: "動画像変換中",
  M0104: "指定時間帯の動画像を取得できませんでした。",
  M0105: "動画像の取得に失敗しました。",

  M0200: "地図情報の取得に失敗しました。",

  M9999: "サーバーでエラーが発生しました。システム管理者にお問い合わせください。",

  AM9999: "サーバーでエラーが発生しました。システム管理者にお問い合わせください。",

  CONFIRM_DELETE: "本当に削除してもよろしいですか",
};

export const m = (code: CODE, ...args: string[]) =>{

  let replaced = MESSAGES[code];
  args.forEach((val, idx) =>{
    replaced = replaced.replace(`{${idx}}`, args[idx]);
  });

  return replaced;
};