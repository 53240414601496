import { AxiosRequestConfig } from "axios";
import "chartjs-plugin-annotation";
import Pagination from "components/parts/Pagination";
import { ENDPOINT } from "constants/endpoint";
import { m } from "constants/message";
import { useUserInfoContext } from "contexts/userInfoContext";
import GoogleMapReact, { MapOptions, Maps } from "google-map-react";
import jwt from "jsonwebtoken";
import { DRIVING_HISTORY_DETAIL, MENU, MOVIE_PLAYBACK, SESSION_TIMEOUT } from "pages/pageInfo";
import queryString from "query-string";
import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import { Link, useHistory } from "react-router-dom";
import { request } from "util/request";

interface QueryParams {
  "source": string | undefined,
  "vehicleId": number,
  "driverId": number,
  "driveDate": string,
  "searchVehicleId": number,
  "searchDriveDate": string,
  "page": number,
  "sortItem": string,
  "sortOrder": string,
}

interface selectedHistory {
  "vehicleId": number,
  "driverId": number,
  "driveDate": string,
}

interface tripBoundaryPoints {
  "arrivalTime": string,
  "departureTime": string,
  "lat": number,
  "lng": number,
}

interface selectedMapIcon {
  "key": string,
  "lat": number,
  "lng": number,
  "iconTexts": string[],
}

export const DrivingHistoryDetail = () => {
  const { signOut, } = useUserInfoContext();
  const [isRequestingDrvHistory, setIsRequestingDrvHistory] = useState<boolean>(false);
  const [data, setData] = useState<any>(null); // 初期状態：null、エラー時：undefined とする
  const [isRequestingHistoryDetail, setIsRequestingHistoryDetail] = useState<boolean>(false);
  const [detailData, setDetailData] = useState<any>(null); // 初期状態：null、エラー時：undefined とする
  const [isRequestingRoad, setIsRequestingRoad] = useState<boolean>(false);
  const [roadData, setRoadData] = useState<any>([]);
  const [roadRequestParams, setRoadRequestParams] = useState<any>([]);
  const [tripBoundaryPoints, setTripBoundaryPoints] = useState<tripBoundaryPoints[]>([]);
  const [activePage, setActivePage] = useState<number>(0);
  const [searchVehicleName, setSearchVehicleName] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const queryParams = useRef<QueryParams>({
    "source": "",
    "vehicleId": 0,
    "driverId": 0,
    "driveDate": "",
    "searchVehicleId": 0,
    "searchDriveDate": "",
    "page": 0,
    "sortItem": "",
    "sortOrder": ""
  });
  const [selectedHistory, setSelectedHistory] = useState<selectedHistory>({ // 一覧で選択中の履歴
    "vehicleId": 0,
    "driverId": 0,
    "driveDate": "",
  });
  const [selectedMapIcon, setSelectedMapIcon] = useState<selectedMapIcon>({ // 地図上で選択したアイコン
    "key": "",
    "lat": 0,
    "lng": 0,
    "iconTexts": [],
  });
  const history = useHistory();
  const googleMapKey = "AIzaSyBA3-5wbmyiAJ21Fu65g6QpX9kR6RfZCrw";

  useEffect(() => {
    document.title = DRIVING_HISTORY_DETAIL.title;

    // クエリパラメータ取得
    const getQuery = (param: string) => queryString.parse(history.location.search)[param] as string;
    if (queryParams.current["source"] === "") {
      let p = queryParams.current;
      // source: 01:履歴管理画面/走行履歴一覧, 02:動態管理画面/走行軌跡表示
      p["source"] = (getQuery("source") === "01" || getQuery("source") === "02") ? getQuery("source") : undefined;
      p["vehicleId"] = parseInt(getQuery("vehicle_id"));
      p["driverId"] = parseInt(getQuery("driver_id"));
      p["driveDate"] = getQuery("drive_date");
      if (p["source"] === "01") {
        // クエリパラメータにsearchVehicleId/serchDriveDateが含まれない場合は、初期状態のままにする
        p["searchVehicleId"] = getQuery("search_vehicle_id") !== "" ? parseInt(getQuery("search_vehicle_id")) : 0;
        p["searchDriveDate"] = getQuery("search_drive_date");
        p["page"] = parseInt(getQuery("page"));
        p["sortItem"] = getQuery("sort_item");
        p["sortOrder"] = getQuery("sort_order");
      }
      // 不正なクエリパラメータの場合、MENUページに遷移させる
      if (Object.values(p).includes(undefined) || Object.values(p).includes(NaN)) {
        history.replace(MENU.path);
      }
      setActivePage(() => p["page"]);
      setSelectedHistory({ ...selectedHistory, "vehicleId": p["vehicleId"], "driverId": p["driverId"], "driveDate": p["driveDate"] });
    }
  }, [history, queryParams, selectedHistory]);

  useEffect(() => {
    // 走行履歴検索APIのレスポンスから、検索条件の車両IDに対応する車両名を取得する
    if (queryParams.current["searchVehicleId"] !== 0 && searchVehicleName === null && data != null) {
      const target = data.values.find((v: any) => v.vehicle_id === queryParams.current["searchVehicleId"]);
      setSearchVehicleName(() => target.vehicle_name);
    }
  }, [searchVehicleName, data, queryParams]);

  useEffect(() => {
    // アクティブページ設定後、走行履歴が空でリクエスト中でもない場合
    // リクエストを投げてデータを取得する
    if (activePage !== 0 && data === null && !isRequestingDrvHistory){
      (async () => {
        setIsRequestingDrvHistory(true); // APIの二重コール防止用

        // POSTリクエスト（走行履歴検索）
        const config: AxiosRequestConfig = {
          method: "post",
          data: {
            vehicle_id: queryParams.current["searchVehicleId"] !== 0 ? queryParams.current["searchVehicleId"] : null,
            drive_date: queryParams.current["searchDriveDate"] ? queryParams.current["searchDriveDate"] : null,
            page: activePage,
            sort_item: queryParams.current["sortItem"],
            sort_order: queryParams.current["sortOrder"]
          },
        };

        await request(ENDPOINT.DRIVING_HISTORY_SEARCH, config, setErrorMessage)
          .then((res: any) => { setData(res.data); setIsRequestingDrvHistory(false); })
          .catch((err: any) => {
            // トークン不正時、サインアウト処理してreturn ※サインアウト処理の中でタイムアウト画面へ遷移させる
            if (err instanceof jwt.JsonWebTokenError){ signOut(SESSION_TIMEOUT.path); return; }

            setData(undefined);
            setIsRequestingDrvHistory(false); // 必ずsetDataの方が先（dataがnullのまま先にisRequestingをfalseにすると2回目のリクエストを投げるので。。）
            return;
          }
          );
      })();
    }
  },[activePage, data, isRequestingDrvHistory, queryParams, signOut]);

  useEffect(() => {
    // 履歴選択後、走行履歴詳細が空でリクエスト中でもない場合
    // リクエストを投げてデータを取得する
    if (selectedHistory["driveDate"] !== "" && detailData === null && !isRequestingHistoryDetail) {
      (async () => {
        setIsRequestingHistoryDetail(true); // APIの二重コール防止用

        // POSTリクエスト（走行履歴詳細情報取得
        const config: AxiosRequestConfig = {
          method: "post",
          data: {
            vehicle_id: selectedHistory["vehicleId"],
            driver_id: selectedHistory["driverId"],
            drive_date: selectedHistory["driveDate"],
          },
        };

        await request(ENDPOINT.DRIVING_HISTORY_DETAIL_INFO_GET, config, setErrorMessage)
          .then((res: any) => {
            setDetailData(res.data);
            setIsRequestingHistoryDetail(false);
            const data = JSON.parse(JSON.stringify(res.data.map_graph_info.data));
            if (data.length) { // 地図情報があればRoads APIをコールする
              setRoadRequestParams(data);
            }
          })
          .catch((err: any) => {
            // トークン不正時、サインアウト処理してreturn ※サインアウト処理の中でタイムアウト画面へ遷移させる
            if (err instanceof jwt.JsonWebTokenError){ signOut(SESSION_TIMEOUT.path); return; }

            setDetailData(undefined);
            setIsRequestingHistoryDetail(false); // 必ずsetDetailDataの方が先（detailDataがnullのまま先にisRequestingをfalseにすると2回目のリクエストを投げるので。。）
            return;
          }
          );
      })();
    }
  }, [detailData, isRequestingHistoryDetail, selectedHistory, signOut]);

  useEffect(() => {
    // リクエストパラメータ用のデータがあり、リクエスト中でもない場合
    // リクエストを投げてデータを取得する
    if (roadRequestParams.length && !isRequestingRoad) {

      // Roads APIをコールし、補間された位置情報を返却する
      const callRoadsApi = async (data: any[], index: number) => {
        // リクエストパラメータを(経度,緯度|経度,緯度|・・・|経度,緯度)の形式に変換する
        let path = data.map((Item: any) => Item.lat + "," + Item.lon).join("|");

        // GETリクエスト（Roads API）
        const config: AxiosRequestConfig = {
          method: "get",
        };

        let points: any;
        await request("https://roads.googleapis.com/v1/snapToRoads?path=" + path + "&interpolate=true&key=" + googleMapKey, config, setErrorMessage, false)
          .then((res: any) => {
            // リクエストパラメータの座標によっては、空のオブジェクトが返却されることがある
            // その場合、補間前のデータを使用する
            if (Object.keys(res.data).length) {
              points = res.data.snappedPoints;
            } else {
              points = data.map((Item: any) => {
                return {"location": {"latitude": Item.lat, "longitude": Item.lon}};
              });
            }
          })
          .catch((err: any) => {
            throw err;
          });
        return points;
      };

      // リクエストを分割してcallRoadsApiを実行し、補間された位置情報(1trip分)を返却する
      const devideRequest = async (data: any[], index: number) => {
        // リクエストパラメータの制限が100地点までなので、100地点ずつに区切る
        let reqList = [];
        while (data.length) {
          reqList.push(data.splice(0, 100));
        }

        // 100地点ごとに区切られた位置情報の配列をもとに、callRoadsApiを並列で実行する
        return (await Promise.all(reqList.map(async (item: any, index: number) => {
          return await callRoadsApi(item, index);
        }))).flat();
      };

      (async () => {
        setIsRequestingRoad(true); // useEffectの二重実行防止用

        // trip境界点における出発日時・到着日時を設定する
        let points: tripBoundaryPoints[] = [{"arrivalTime": roadRequestParams[0].slice(-1)[0].time, "departureTime": "", "lat": 0, "lng": 0}];
        roadRequestParams.forEach((item: any, index: number) => {
          if (index !== 0) {
            points[index - 1].departureTime = item[0].time;
            points.push({"arrivalTime": item.slice(-1)[0].time, "departureTime": "", "lat": 0, "lng": 0});
          }
        });

        // tripごとに区切られた位置情報の配列をもとに、devideRequestを並列で実行する
        await Promise.all(roadRequestParams.map(async (item: any, index: number) => {
          return await devideRequest(item, index);
        }))
          .then((values) => {
            // trip境界点における緯度・経度に補間後の値を設定する
            values.forEach((item: any, index: number) => {
              if (index !== 0) {
                points[index - 1].lat = item[0].location.latitude;
                points[index - 1].lng = item[0].location.longitude;
              }
            });
            setTripBoundaryPoints(() => points);

            setRoadData(() => values.flat());
          })
          .catch((err: any) => {
            setErrorMessage(m("M0200"));
            setRoadData([]);
          }
          );
        setRoadRequestParams([]);
        setIsRequestingRoad(false);
      })();
    }
  }, [isRequestingRoad, roadData, roadRequestParams]);

  // 履歴ボタンのハンドラ
  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    // [車両ID, ドライバーID, 運行日]
    const params = event.currentTarget.value.split(",");
    const [vehicleId, driverId, driveDate] = [parseInt(params[0]), parseInt(params[1]), params[2]];

    // 選択中の履歴ボタンを押した場合はなにもしない
    if (vehicleId === selectedHistory["vehicleId"] && driverId === selectedHistory["driverId"] && driveDate === selectedHistory["driveDate"]) {
      return;
    } else {
      // 走行履歴詳細APIコール準備
      setSelectedHistory({ ...selectedHistory, "vehicleId": vehicleId, "driverId": driverId, "driveDate": driveDate });
      setErrorMessage(null);
      setRoadRequestParams([]);
      setRoadData([]);
      setTripBoundaryPoints([]);
      setSelectedMapIcon({ ...selectedMapIcon, "key": "", "lat": 0, "lng": 0, "iconTexts": []});
      setDetailData(null);
      // 他画面へ遷移した後に戻るとき、選択した履歴をアクティブにする
      history.replace(`${DRIVING_HISTORY_DETAIL.path}?source=${queryParams.current.source}&vehicle_id=${vehicleId}&driver_id=${driverId}&drive_date=${driveDate}&search_vehicle_id=${queryParams.current.searchVehicleId !== 0 ? queryParams.current.searchVehicleId : ""}&search_drive_date=${queryParams.current.searchDriveDate}&page=${data.page}&sort_item=drive_date&sort_order=desc`);
    }
  };

  // 検索条件部分の生成
  const condition = (
    <React.Fragment>
      <div>
        <h5>走行履歴</h5>
      </div>
      <hr />
      <h6>検索条件</h6>
      <table className="table table-borderless table-sm" style={{tableLayout: "fixed"}}>
        <tbody>
          <tr>
            <td>車両名：{searchVehicleName !== null ? searchVehicleName : "―"}</td>
          </tr>
          <tr>
            <td>運行日：{queryParams.current["searchDriveDate"] !== "" ? queryParams.current["searchDriveDate"] : "―"}</td>
          </tr>
        </tbody>
      </table>
      <hr />
    </React.Fragment>
  );

  // ページボタンのハンドラ
  const handlePageChange = (page: number) => {
    setActivePage(page);
    setData(null);
  };

  // ページネーション部分の生成（レスポンスデータが無い間は非表示）
  const pagination = !data
    ? null
    : (
      <Pagination
        activePage={data.page}
        itemsCountPerPage={data.count}
        totalItemsCount={data.total}
        pageRangeDisplayed={5}
        onChange={handlePageChange}
      />
    );

  // 履歴一覧部分の生成
  const historyList = !data
    ? null // レスポンスデータ無しの場合、何も表示しない（動態管理画面からの遷移の場合）
    : (
      <React.Fragment>
        <div className="scroll mb-3" >
          {
            data.values.map((item: any, index: any) => {
              return (
                <React.Fragment key={`${item.drive_date}-${item.vehicle_id}-${item.driver_id}`}>
                  <Button
                    type="submit"
                    variant="light"
                    size="sm"
                    active={item.vehicle_id === selectedHistory.vehicleId && item.driver_id === selectedHistory.driverId && item.drive_date === selectedHistory.driveDate ? true : false}
                    className="text-left"
                    value={`${item.vehicle_id},${item.driver_id},${item.drive_date}`}
                    disabled={isRequestingHistoryDetail}
                    onClick={(e: React.MouseEvent<HTMLInputElement>) => handleClick(e)}>
                    <table className="table table-borderless table-sm" style={{tableLayout: "fixed"}}>
                      <tbody>
                        <tr>
                          <td>運行日：</td>
                          <td>{item.drive_date}</td>
                        </tr>
                        <tr>
                          <td>車両名：</td>
                          <td>{item.vehicle_name}</td>
                        </tr>
                        <tr>
                          <td>ドライバー：</td>
                          <td>{item.driver_name}</td>
                        </tr>
                        <tr>
                          <td>出庫時刻：</td>
                          <td>{item.departure_datetime}</td>
                        </tr>
                        <tr>
                          <td>帰庫時刻：</td>
                          <td>{item.return_datetime}</td>
                        </tr>
                      </tbody>
                    </table>
                  </Button>
                </React.Fragment>
              );
            })
          }
        </div>
        {pagination}
      </React.Fragment>
    );

  // 基本運行情報部分の生成
  const basicInfo = !detailData
    ? (isRequestingHistoryDetail
      // レスポンスデータ無しでリクエスト中の場合、Loading表示
      ? <Spinner animation="border" className="mt-4" style={{width: "5rem", height: "5rem"}}/>
      // レスポンスデータ無しでリクエスト中でもない場合、何も表示しない（エラー時用）
      : null
    )
    : (
      <React.Fragment>
        <div>
          <h5>基本運行情報</h5>
        </div>
        <table className="table table-borderless table-sm center-block" style={{tableLayout: "fixed"}}>
          <tbody>
            <tr>
              <td>運行日：</td>
              <td>{detailData.departure_datetime !== "" ? detailData.departure_datetime.split(" ")[0] : "―"}</td>
              <td>出庫時刻：</td>
              <td>{detailData.departure_datetime !== "" ? detailData.departure_datetime : "―"}</td>
            </tr>
            <tr>
              <td>車両名：</td>
              <td>{detailData.vehicle_name !== "" ? detailData.vehicle_name : "―"}</td>
              <td>帰庫時刻：</td>
              <td>{detailData.return_datetime !== "" ? detailData.return_datetime : "―"}</td>
            </tr>
            <tr>
              <td>ドライバー：</td>
              <td>{detailData.driver_name !== "" ? detailData.driver_name : "―"}</td>
              <td>総走行距離：</td>
              <td>{detailData.total_mileage !== null ? detailData.total_mileage + "km" : "―"}</td>
            </tr>
            <tr>
              <td>組織名：</td>
              <td>{detailData.group_name !== "" ? detailData.group_name : "―"}</td>
              <td>総走行時間：</td>
              <td>{detailData.total_driving_time !== "" ? detailData.total_driving_time : "―"}</td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    );

  // 運転診断データ部分の生成
  const drivingDiagnosis = !detailData
    ? null // レスポンスデータ無しの場合、何も表示しない（エラー時用）
    : (
      <React.Fragment>
        <div className="pt-3">
          <h5>運転診断データ</h5>
        </div>
        <table className="table table-sm table-bordered" style={{tableLayout: "fixed"}}>
          <tbody>
            <tr className="table-primary">
              <td><div className="text-center">運転スコア</div></td>
              <td><div className="text-center">急加速回数</div></td>
              <td><div className="text-center">急減速回数</div></td>
              <td><div className="text-center">急ハンドル回数</div></td>
            </tr>
            <tr>
              <td><div className="text-center">―</div></td>
              <td><div className="text-center">{detailData.suddenly_accel_count !== null ? detailData.suddenly_accel_count + "回" : "―"}</div></td>
              <td><div className="text-center">{detailData.suddenly_brake_count !== null ? detailData.suddenly_brake_count + "回" : "―"}</div></td>
              <td><div className="text-center">{detailData.suddenly_turn_count !== null ? detailData.suddenly_turn_count + "回" : "―"}</div></td>
            </tr>
            <tr className="table-primary">
              <td><div className="text-center">速度超過回数</div></td>
              <td><div className="text-center">強衝撃回数</div></td>
              <td><div className="text-center">緊急通報回数</div></td>
            </tr>
            <tr>
              <td><div className="text-center">{detailData.speed_over_count !== null ? detailData.speed_over_count + "回" : "―"}</div></td>
              <td><div className="text-center">{detailData.shock_count !== null ? detailData.shock_count + "回" : "―"}</div></td>
              <td><div className="text-center">{detailData.panic_count !== null ? detailData.panic_count + "回" : "―"}</div></td>
            </tr>
          </tbody>
        </table>
        { queryParams.current.source === "02" && <div className="text-danger">※基本運行情報、運転診断データはデータ集計後（翌日）に表示されます。</div> }
      </React.Fragment>
    );

  // 動画一覧部分の生成
  const movieList = !detailData
    ? null // レスポンスデータ無しの場合、何も表示しない（エラー時用）
    : (
      <React.Fragment>
        <div className="pt-3">
          <h5>動画一覧</h5>
        </div>
        <table className="table table-sm table-bordered" style={{tableLayout: "fixed"}}>
          <tbody>
            <tr className="table-primary">
              <td><div className="text-center">日時</div></td>
              <td><div className="text-center">種別</div></td>
              <td><div className="text-center">動画</div></td>
            </tr>
            {
              detailData.movie_list.data.map((item: any,index: any)=>{
                return (
                  <React.Fragment key={item.mng_id}>
                    <tr>
                      <td><div className="text-center">{item.start_datetime} ～ {item.end_datetime}</div></td>
                      <td className="align-middle"><div className="text-center">取得</div></td>
                      <td className="align-middle">
                        <div className="text-center">
                          <img style={{cursor: "pointer"}} alt="Link" src="/img/movie.png" width="auto" height="30"
                            onClick={() => window.open(`${MOVIE_PLAYBACK.path}?source=01&type=movie&mng_ids=${String(detailData.movie_list.data.map((item: any)=>item.mng_id))}&main_mng_id_num=${index}`)}/>
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })
            }
          </tbody>
        </table>
      </React.Fragment>
    );

  // 静止画一覧部分の生成
  const imageList = !detailData
    ? null // レスポンスデータ無しの場合、何も表示しない（エラー時用）
    : (
      <React.Fragment>
        <div className="pt-3">
          <h5>静止画一覧</h5>
        </div>
        <table className="table table-sm table-bordered" style={{tableLayout: "fixed"}}>
          <tbody>
            <tr className="table-primary">
              <td><div className="text-center">日時</div></td>
              <td><div className="text-center">種別</div></td>
              <td><div className="text-center">静止画</div></td>
            </tr>
            {
              detailData.snapshot_list.data.map((item: any,index: number)=>{
                return (
                  <React.Fragment key={item.mng_id}>
                    <tr>
                      <td><div className="text-center">{item.start_datetime}{item.end_datetime !== "" ? " ～ " + item.end_datetime : ""}</div></td>
                      <td className="align-middle"><div className="text-center">{item.event_name !== null ? item.event_name : "取得"}</div></td>
                      <td className="align-middle">
                        <div className="text-center">
                          <img style={{cursor: "pointer"}} alt="Link" src="/img/images.png" width="auto" height="25"
                            onClick={() => window.open(`${MOVIE_PLAYBACK.path}?source=05&type=snapshot&mng_ids=${String(detailData.snapshot_list.data.map((item: any)=>item.mng_id))}&main_mng_id_num=${index}${item.event_name !== "" ? "&event_id=" + String(detailData.snapshot_list.data.map((item: any)=>item.event_id)) : ""}`)}/>
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })
            }
          </tbody>
        </table>
      </React.Fragment>
    );

  // 危険挙動一覧部分の生成
  const eventList = !detailData
    ? null // レスポンスデータ無しの場合、何も表示しない（エラー時用）
    : (
      <React.Fragment>
        <div className="pt-3">
          <h5>危険挙動一覧</h5>
        </div>
        <table className="table table-sm table-bordered" style={{tableLayout: "fixed"}}>
          <tbody>
            <tr className="table-primary">
              <td><div className="text-center">日時</div></td>
              <td><div className="text-center">種別</div></td>
            </tr>
            {
              detailData.events.map((item: any)=>{
                return (
                  <React.Fragment key={item.event_id}>
                    <tr>
                      <td><div className="text-center">{item.event_datetime}</div></td>
                      <td className="align-middle"><div className="text-center">{item.event_name}</div></td>
                    </tr>
                  </React.Fragment>
                );
              })
            }
          </tbody>
        </table>
      </React.Fragment>
    );

  // Mapのオプションを設定
  const createMapOptions = (maps: Maps): MapOptions => {
    return {
      mapTypeControlOptions: {
        position: maps.ControlPosition.TOP_RIGHT,
      },
      mapTypeControl: true,
      zoomControl: true,
      scaleControl: true,
      streetViewControl: true,
      fullscreenControl: true,
      clickableIcons: true,
    };
  };

  // 走行軌跡描画のハンドラ
  const handleApiLoaded = (map: any, maps: any) => {
    const path = new maps.Polyline({
      path: roadData.map((item: any) => ({
        lat: item.location.latitude,
        lng: item.location.longitude,
      })),
      geodesic: true,
      strokeColor: "#FF3366",
      strokeOpacity: 1,
      strokeWeight: 3,
      // 動態管理画面からの遷移の場合、終端のアイコンを矢印にする
      icons: queryParams.current["source"] === "02"
        ? [{icon: {
          path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          scale: 3,
          strokeColor: "#FF3366",
          strokeWeight: 5
        }, offset: "100%"}]
        : null
    });
    // 緯度/経度の最小値/最大値を求め、倍率と初期表示位置を調整する
    let [minLat, maxLat, minLng, maxLng] = [90, -90, 180, -180];
    roadData.forEach((item: any) => {
      minLat = item.location.latitude < minLat ? item.location.latitude : minLat;
      maxLat = item.location.latitude > maxLat ? item.location.latitude : maxLat;
      minLng = item.location.longitude < minLng ? item.location.longitude : minLng;
      maxLng = item.location.longitude > maxLng ? item.location.longitude : maxLng;
    });
    const southWest = new google.maps.LatLng(maxLat, minLng);
    const northEast = new google.maps.LatLng(minLat, maxLng);
    const bounds = new google.maps.LatLngBounds(southWest, northEast);
    path.setMap(map);
    map.fitBounds(bounds);
  };

  // 地図上に表示するアイコン(画像)
  const ImageIcon = ({lat, lng, src}: {lat: number, lng: number, src: string, iconText: string}) => (
    <div>
      <img src={src} alt="" style={{
        position: "absolute",
        width: 40,
        height: 40,
        left: -20,
        top: -20,
        textAlign: "center",
        padding: 4,
        cursor: "pointer",
      }}>
      </img>
    </div>
  );

  // 地図上に表示するアイコン(文字)
  const TextIcon = ({lat, lng, text, color}: {lat: number, lng: number, text: string, color: string, iconText: string}) => (
    <div style={{
      position: "absolute",
      width: 30,
      height: 30,
      left: -15,
      top: -15,
      border: `5px solid ${color}`,
      borderRadius: 40,
      backgroundColor: `${color}`,
      textAlign: "center",
      color: "#FFFFFF",
      fontSize: 14,
      fontWeight: "bold",
      padding: 2,
      cursor: "pointer",
    }}>
      {text}
    </div>
  );

  // アイコン押下時の吹き出し
  const IconInfo = ({lat, lng, iconTexts}: {lat: number, lng: number, iconTexts: string[]}) => (
    <div style={{
      position: "absolute",
      width: 260,
      transform: "translate(-50%, -130%)",
    }}>
      <div style={{
        padding: "15px",
        backgroundColor: "#FFFFFF",
        wordBreak: "normal",
        fontSize: 14,
        boxShadow: "0 2px 7px 1px rgba(0,0,0,0.3)",
        borderRadius: 8,
      }}>
        {
          // 一行ずつ表示する
          iconTexts.map((item: string, index) => {
            return (
              <React.Fragment key={`icon-text-line-${index}`}>
                {
                  // 危険挙動アイコン押下時、危険挙動IDに紐づく静止画管理IDがあれば、動画再生画面へのリンクを生成する
                  index === 0 && item.startsWith("snapshot")
                    ? <Link to={`${MOVIE_PLAYBACK.path}?source=04&type=snapshot&mng_ids=${item.split("-")[1]}&main_mng_id_num=0&event_id=${item.split("-")[3]}`} target="_blank" rel="noopener noreferrer">
                      <div>{item.split("-")[4]}</div>
                    </Link>
                    : <div>{item}</div>
                }
              </React.Fragment>
            );
          })
        }
        <div style={{
          position: "absolute",
          width: 0,
          height: 0,
          top: "100%",
          left: "50%",
          transform: "translate(-50%, 0)",
          borderStyle: "solid",
          borderWidth: "10px 10px 0px 10px",
          borderColor: "#FFFFFF transparent transparent transparent",
        }}>
        </div>
      </div>
    </div>
  );

  // アイコン押下時のハンドラ
  const onChildClick = (key: string, childProps: any) => {
    if (key === selectedMapIcon.key) {
      // 同じアイコンを押下したら、吹き出しを非表示にする
      setSelectedMapIcon({ ...selectedMapIcon, "key": "", "lat": 0, "lng": 0, "iconTexts": []});
    } else {
      const texts = childProps.iconText.split(",");
      setSelectedMapIcon({ ...selectedMapIcon, "key": key, "lat": childProps.lat, "lng": childProps.lng, "iconTexts": texts});
    }
  };

  // 走行軌跡部分の生成
  const tracking = !detailData
    ? null
    : (isRequestingRoad)
      // Roads APIコール中の場合、何も表示しない
      ? null
      : (
        <React.Fragment>
          <div>
            <h5>走行軌跡</h5>
          </div>
          <div style={{ height: "50vh", width: "100%" }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: googleMapKey }}
              defaultCenter={{ lat: 35.6802117, lng: 139.7576692 }}
              defaultZoom={5}
              options={createMapOptions}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
              onChildClick={(key: string, childProps: any) => onChildClick(key, childProps)}
            >
              {
                tripBoundaryPoints.map((item: any, index) => {
                  if (index !== (tripBoundaryPoints.length - 1)) {
                    // 終点以外の境界点を数字でアイコン表示する
                    return (
                      <TextIcon
                        key={`boundary-icon-${index + 1}`}
                        lat={item.lat}
                        lng={item.lng}
                        text={`${index + 1}`}
                        color="#33CC99"
                        iconText={`到着日時：${item.arrivalTime},出発日時：${item.departureTime}`}
                      />
                    );
                  } else { return null; }
                })
              }
              {
                JSON.parse(JSON.stringify(detailData.events)).map((item: any, index: number, self: any) => {
                  // 危険挙動をアイコン表示する
                  if (index !== (self.length - 1) && item.event_datetime === self[index + 1].event_datetime) {
                    // 発生日時が同じイベントは、1つのアイコンにまとめて表示する
                    self[index + 1].event_name = item.event_name + "／" + self[index + 1].event_name;
                    return null;
                  } else {
                    // 危険挙動IDに紐づく静止画管理IDがあれば取得し、iconTextにそれぞれのIDを埋め込む
                    let snapshotData: any = detailData.snapshot_list.data.find((target: any) => target.event_id === item.event_id);
                    let iconText = snapshotData ? `snapshot-${snapshotData.mng_id}-event-${item.event_id}-発生日時：${item.event_datetime},危険挙動種別：${item.event_name}`
                      : `発生日時：${item.event_datetime},危険挙動種別：${item.event_name}`;
                    return (
                      <ImageIcon
                        key={`event-icon-${item.event_id}`}
                        lat={item.event_lat}
                        lng={item.event_lon}
                        src="/img/map_icons/danger.png"
                        iconText={iconText}
                      />
                    );
                  }
                })
              }
              {
                roadData.length &&
                  // 始点をアイコン表示する
                  <TextIcon
                    lat={roadData[0].location.latitude}
                    lng={roadData[0].location.longitude}
                    text="S"
                    color="#0099FF"
                    iconText={`出庫時刻：${detailData.departure_datetime}`}
                  />
              }
              {
                queryParams.current.source !== "02" && roadData.length &&
                  // 動態管理画面からの遷移ではない場合、終点をアイコン表示する
                  <TextIcon
                    lat={roadData.slice(-1)[0].location.latitude}
                    lng={roadData.slice(-1)[0].location.longitude}
                    text="G"
                    color="#FF3366"
                    iconText={`帰庫時刻：${detailData.return_datetime}`}
                  />
              }
              <IconInfo
                // 選択したアイコンの吹き出しを表示する
                key={selectedMapIcon.key}
                lat={selectedMapIcon.lat}
                lng={selectedMapIcon.lng}
                iconTexts={selectedMapIcon.iconTexts}
              />
            </GoogleMapReact>
          </div>
        </React.Fragment>
      );

  // 走行データグラフ(速度)のデータ部分生成
  const createSpeedDataset = () => {
    // ラベル
    const labelList = detailData.map_graph_info.data.map((items: any[]) => items.map((item: { time: string; }) => item.time.split(" ")[1])).flat();
    // GPS速度
    const gSpeedList = detailData.map_graph_info.data.map((items: any[]) => items.map((item: { g_speed: number; }) => item.g_speed)).flat();
    // パルス速度
    const pSpeedList = detailData.map_graph_info.data.map((items: any[]) => items.map((item: { p_speed: number; }) => item.p_speed)).flat();
    return {
      labels: labelList,
      datasets: [
        {
          label: "GPS速度",
          fill: true,
          lineTension: 0.1,
          backgroundColor: "rgba(0,0,0,0)",
          borderColor: "rgba(50,205,50,1)",
          borderCapStyle: "round",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "square",
          pointRadius: 0,
          pointHitRadius: 5,
          borderWidth: 1.5,
          data: gSpeedList
        },
        {
          label: "パルス速度",
          fill: true,
          lineTension: 0.1,
          backgroundColor: "rgba(0,0,0,0)",
          borderColor: "rgba(220,20,60,1)",
          borderCapStyle: "round",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "square",
          pointRadius: 0,
          pointHitRadius: 5,
          borderWidth: 1.5,
          data: pSpeedList
        },
      ]
    };
  };

  // 走行データグラフ(速度)のオプション部分生成
  const createSpeedOption = () => {
    return {
      scales: {
        xAxes: [
          {
            ticks: {
              autoSkip: true, // 横幅が狭くなったときに表示を間引くか否か
              maxRotation: 45, // 下のと合わせて表示される角度を決める
              minRotation: 45, // 横幅を最小にしたときに縦に表示される
              maxTicksLimit: 20, // メモリの最大表示数
            },
          },
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: "速度",
            },
            ticks: {
              callback: (value: any) => `${value} km/h`,
              autoSkip: true, // 縦幅が狭くなったときに表示を間引くか否か
            },
          },
        ],
      },
      legend: {
        position: "bottom", // ラベルの表示位置
      },
      maintainAspectRatio: false,
    };
  };

  // 走行データグラフ(Gセンサー値)のデータ部分生成
  const createGSensorDataset = () => {
    // ラベル
    const labelList = detailData.map_graph_info.data.map((items: any[]) => items.map((item: { time: string; }) => item.time.split(" ")[1])).flat();
    // Xのデータリスト
    const xList = detailData.map_graph_info.data.map((items: any[]) => items.map((item: { gsensor: { x: number; }}) => item.gsensor.x)).flat();
    // Yのデータリスト
    const yList = detailData.map_graph_info.data.map((items: any[]) => items.map((item: { gsensor: { y: number; }}) => item.gsensor.y)).flat();
    // Zのデータリスト
    const zList = detailData.map_graph_info.data.map((items: any[]) => items.map((item: { gsensor: { z: number; }}) => item.gsensor.z)).flat();
    return {
      labels: labelList,
      datasets: [
        {
          label: "X",
          fill: true,
          lineTension: 0.1,
          backgroundColor: "rgba(0,0,0,0)",
          borderColor: "rgba(220,20,60,1)",
          borderCapStyle: "round",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "square",
          pointRadius: 0,
          pointHitRadius: 5,
          borderWidth: 1.5,
          data: xList
        },
        {
          label: "Y",
          fill: true,
          lineTension: 0.1,
          backgroundColor: "rgba(0,0,0,0)",
          borderColor: "rgba(50,205,50,1)",
          borderCapStyle: "round",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "square",
          pointRadius: 0,
          pointHitRadius: 5,
          borderWidth: 1.5,
          data: yList
        },
        {
          label: "Z",
          fill: true,
          lineTension: 0.1,
          backgroundColor: "rgba(0,0,0,0)",
          borderColor: "rgba(30,144,255,1)",
          borderCapStyle: "round",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "square",
          pointRadius: 0,
          pointHitRadius: 5,
          borderWidth: 1.5,
          data: zList
        },
      ]
    };
  };

  // 走行データグラフ(Gセンサー値)のオプション部分生成
  const createGSensorOption = () => {
    return {
      scales: {
        xAxes: [
          {
            ticks: {
              autoSkip: true, // 横幅が狭くなったときに表示を間引くか否か
              maxRotation: 45, // 下のと合わせて表示される角度を決める
              minRotation: 45, // 横幅を最小にしたときに縦に表示される
              maxTicksLimit: 20, // メモリの最大表示数
            },
          },
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: "mG",
            },
            ticks: {
              callback: (value: any) => `${value} mG`,
              autoSkip: true, // 縦幅が狭くなったときに表示を間引くか否か
            },
          },
        ],
      },
      legend: {
        position: "bottom", // ラベルの表示位置
      },
      maintainAspectRatio: false,
    };
  };

  // 走行データグラフ部分の生成
  const graph = !detailData
    ? null // レスポンスデータ無しの場合、何も表示しない（エラー時用）
    : (
      <React.Fragment>
        <div className="pt-4 pb-1">
          <h5>走行データグラフ</h5>
        </div>
        <div id="graph-1" style={{float: "inline-end"}}>
          <Line data={createSpeedDataset()} height={300} options={createSpeedOption()} />
        </div>
        <div id="graph-2" style={{float: "inline-end"}}>
          <Line data={createGSensorDataset()} height={300} options={createGSensorOption()} />
        </div>
      </React.Fragment>
    );

  // エラーメッセージの生成
  const alert = errorMessage != null
    ? <Alert variant="danger" className="mt-2" dismissible onClose={e => setErrorMessage(null)}><strong>エラー：</strong>{errorMessage}</Alert>
    : null;

  return (
    <div className="mt-3 ml-2 mr-2">

      {alert}

      <Container fluid>
        <Row>
          <Col xs={3} className="border border-top-0 border-left-0 border-bottom-0">
            <div className="text-right">
              <Button variant="primary" className="pl-4 pr-4" size="sm" onClick={() => history.goBack()}>戻る</Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={3} className="border border-top-0 border-left-0 border-bottom-0">
            {condition}
            {historyList}
          </Col>
          <Col xs={3} className="border border-top-0 border-left-0 border-bottom-0">
            {basicInfo}
            {drivingDiagnosis}
            {movieList}
            {imageList}
            {eventList}
          </Col>
          <Col xs={6}>
            {tracking}
            {graph}
          </Col>
        </Row>
      </Container>

    </div>
  );
};